import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, InputGroup, InputGroupAddon, InputGroupText, Input, Row } from 'reactstrap';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { RemoveRedEyeOutlined, RemoveRedEye } from '@material-ui/icons';

import AppConfig from 'Constants/AppConfig';
import FormErrors from 'Constants/FormErrors';

import PositionedFormFeedback from 'Components/PositionedFormFeedback';

import { isInvalidField } from 'Utils/FormUtils';

const SignInForm = ({
    email,
    onChangeFormField,
    onTogglePasswordVisibility,
    onSubmitLoginForm,
    password,
    showPassword,
    submitted
}) => (
    <Container className="page-container-center-wrapper flex-column">
        <Row>
            <Col md={12} className="d-flex justify-content-center">
                <img
                    className="img-fluid"
                    src={AppConfig.appLogo}
                    alt=""
                />
            </Col>
        </Row>

        <Row>
            <Col md={12} className="d-flex justify-content-center pt-40">
                <h1>Sign in</h1>
            </Col>
        </Row>

        <Row className="py-3 w-100">
            <Col md={12} className="d-flex justify-content-center">
                <Input
                    invalid={isInvalidField(submitted, email)}
                    type="text"
                    name="email"
                    className="form-control input-lg"
                    placeholder="Email"
                    onChange={onChangeFormField}
                    value={email}
                />

                {isInvalidField(submitted, email) && (
                    <PositionedFormFeedback message={FormErrors.email.blank} leftOffset/>
                )}
            </Col>
        </Row>

        <Row className="py-3 w-100">
            <Col md={12} className="d-flex justify-content-center">
                <InputGroup>
                    <Input
                        invalid={isInvalidField(submitted, password)}
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        className="form-control input-lg"
                        placeholder="Password"
                        onChange={onChangeFormField}
                        value={password}
                    />

                    <InputGroupAddon addonType="append">
                        <InputGroupText>
                            <IconButton
                                disabled={password.length === 0}
                                aria-label="eye"
                                tabIndex="-1"
                                onClick={onTogglePasswordVisibility}
                            >
                                {showPassword ? <RemoveRedEye/> : <RemoveRedEyeOutlined/>}
                            </IconButton>
                        </InputGroupText>
                    </InputGroupAddon>
                </InputGroup>

                {isInvalidField(submitted, password) && (
                    <PositionedFormFeedback message={FormErrors.password.blank} leftOffset />
                )}
            </Col>
        </Row>

        <Row className="justify-content-between w-100 my-3 px-2">
            <Button
                color="primary"
                className="py-2 mx-1 font-weight-bold"
                fullWidth
                onClick={onSubmitLoginForm}
                variant="text"
            >
                Log in
            </Button>
        </Row>

        <Row className="justify-content-around pb-3 w-100">
            <Link to="/admin/forgot" className="mt-3 mx-3 text-muted font-weight-bold">
                Forgot Password?
            </Link>

            <Link to="/admin/terms-of-use" className="mt-3 mx-3 text-muted font-weight-bold">
                Terms of Use & Privacy Policy
            </Link>
        </Row>

        <Row className="justify-content-center w-100">
            <Link to="/">
                <p>Go to Landing Page</p>
            </Link>
        </Row>
    </Container>
);

export default SignInForm;
