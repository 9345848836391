import IconButton from '@material-ui/core/IconButton';

import { getTermsForLanding } from 'Actions';
import ContactInfo from 'Components/LandingPage/components/ContactInfo';
import GetApp from 'Components/LandingPage/components/GetApp';
import Header from 'Components/LandingPage/components/Header';
import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet';
import ReactQuill from 'react-quill';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import { Link } from 'react-router-dom';
import {
  Col,
  Row,
  Container,
} from 'reactstrap';

import { getLandingTerms } from 'Selectors';

import { getQuillFormats } from 'Utils/FormUtils';
import Typography from "@material-ui/core/Typography";
import {TermsAndConditionsJsx} from "Constants/termsAndConditions";

const TermsOfUse = () => {
  const dispatch = useDispatch();

  const terms = useSelector(getLandingTerms);

  useEffect(() => {
    dispatch(getTermsForLanding());
  }, []);

  const termsTitle = 'Terms & Conditions';

  return (
    <>
      <Helmet>
        <title>PAYMEROBOT {termsTitle}</title>
        <meta name="terms" content={termsTitle} />
      </Helmet>

      <Header />

      <Container className="main-container pt-4">
        <div className="page-title d-flex align-items-center justify-content-between mt-4">
          <Row className="align-items-center">
            <IconButton
              to="/"
              className="mr-15"
              aria-label="zmdi-arrow-left"
              component={Link}
            >
              <i className="zmdi zmdi-arrow-left" />
            </IconButton>

            <h1 className="section-title">{termsTitle}</h1>

              <div className={'pl-4 pr-4'} style={{color: '#000000'}}>
                  <TermsAndConditionsJsx />
              </div>
          </Row>
        </div>

        <Row className="py-3">
          <Col md={12} className="h-100">
            <ReactQuill
              readOnly
              className="quill-custom h-100"
              formats={getQuillFormats()}
              modules={(
                { toolbar: false }
              )}
              name="terms"
              value={terms}
            />
          </Col>
        </Row>
      </Container>
      <GetApp />
      <ContactInfo />
    </>
  );
};

export default TermsOfUse;
