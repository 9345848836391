import React from 'react';
import { NotificationManager } from 'react-notifications';

import {
    GET_SETTINGS,
    GET_SETTINGS_SUCCESS,
    GET_SETTINGS_FAILURE,
    UPDATE_SETTINGS,
    UPDATE_SETTINGS_SUCCESS,
    UPDATE_SETTINGS_FAILURE,
    GET_PUSH_NOTIFICATIONS,
    GET_PUSH_NOTIFICATIONS_SUCCESS,
    GET_PUSH_NOTIFICATIONS_FAILURE,
    UPDATE_PUSH_NOTIFICATIONS, UPDATE_PUSH_NOTIFICATIONS_SUCCESS, UPDATE_PUSH_NOTIFICATIONS_FAILURE
} from 'Actions/types';

import { api, errorHandler, successMessageHandler } from 'Api';

export const getSettings = () => dispatch => {
    dispatch({ type: GET_SETTINGS });

    api.get('admin/system/settings')
        .then(response => {
            dispatch({
                type: GET_SETTINGS_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => dispatch(errorHandler(error, GET_SETTINGS_FAILURE)))
};

export const updateSettings = (data, onSuccess) => dispatch => {
    dispatch({ type: UPDATE_SETTINGS });

    api.put('admin/system/settings', data)
        .then(response => {
            dispatch({ type: UPDATE_SETTINGS_SUCCESS });

            if (!successMessageHandler(response)) {
                NotificationManager.success('Success!');
            } else {
                successMessageHandler(response);
            }

            onSuccess();
        })
        .catch(error => dispatch(errorHandler(error, UPDATE_SETTINGS_FAILURE)))
};

export const getPushNotifications = (onSuccess) => dispatch => {
    dispatch({ type: GET_PUSH_NOTIFICATIONS });

    api.get('admin/system/settings/push-notifications')
        .then(response => {
            dispatch({
                type: GET_PUSH_NOTIFICATIONS_SUCCESS,
                payload: response.data
            });

            if (!successMessageHandler(response)) {
                NotificationManager.success('Success!');
            } else {
                successMessageHandler(response);
            }

            onSuccess?.();
        })
        .catch(error => dispatch(errorHandler(error, GET_PUSH_NOTIFICATIONS_FAILURE)))
};

export const updatePushNotifications = ({notificationsData, onSuccess}) => dispatch => {
    dispatch({ type: UPDATE_PUSH_NOTIFICATIONS });

    api.put('admin/system/settings', notificationsData)
        .then(response => {
            dispatch({
                type: UPDATE_PUSH_NOTIFICATIONS_SUCCESS,
            });

            if (!successMessageHandler(response)) {
                NotificationManager.success('Success!');
            } else {
                successMessageHandler(response);
            }

            onSuccess?.();
        })
        .catch(error => dispatch(errorHandler(error, UPDATE_PUSH_NOTIFICATIONS_FAILURE)))
};
