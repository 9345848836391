import React from 'react';

import { useContestEndTimer } from 'Hooks';

import { getContestTimeLeft } from 'Utils/DateModifiers';

const ContestEnd = ({ time }) => {
    const contestEndTime = useContestEndTimer(time);

    return (
        <div className="header-contest-end">
            <h2 className="m-0">To the end of contest</h2>

            <h3 className="m-0">
                {getContestTimeLeft(contestEndTime)}
            </h3>
        </div>
    );
};

export default ContestEnd;
