import React from 'react';
import { Col, FormGroup, Input, Row } from 'reactstrap';

import FormErrors from 'Constants/FormErrors';

import PositionedFormFeedback from 'Components/PositionedFormFeedback';

import { isInvalidField } from 'Utils/FormUtils';

const NewWatcherForm = ({
    email,
    name,
    onChangeFormField,
    submitted
}) => (
    <FormGroup row className="has-wrapper text-left pl-3">
        <Row className="py-3 w-100">
            <Col md={12} className="d-flex justify-content-center">
                <Input
                    invalid={isInvalidField(submitted, email)}
                    type="text"
                    name="email"
                    className="form-control input-lg"
                    placeholder="Email"
                    onChange={onChangeFormField}
                    value={email}
                />

                {isInvalidField(submitted, email) && (
                    <PositionedFormFeedback message={FormErrors.email.blank} leftOffset />
                )}
            </Col>
        </Row>

        <Row className="py-3 w-100">
            <Col md={12} className="d-flex justify-content-center">
                <Input
                    invalid={isInvalidField(submitted, name)}
                    type="text"
                    name="name"
                    className="form-control input-lg"
                    placeholder="Name"
                    onChange={onChangeFormField}
                    value={name}
                />

                {isInvalidField(submitted, name) && (
                    <PositionedFormFeedback message={FormErrors.name.blank} leftOffset />
                )}
            </Col>
        </Row>
    </FormGroup>
);

export default NewWatcherForm;
