import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { storeResetPasswordToken, storeUserEmail } from 'Utils/LocalStorageUtils';
import { getDeepLink, getOS } from 'Utils/MobileUtils';

const ResetPassword = () => {
    const history = useHistory();

    useEffect(() => {
        // store token
        const resetToken = location.pathname.split('/').pop();
        storeResetPasswordToken(resetToken);

        // store email
        const rawEmail = location.search.split('=').pop();
        const decodedEmail = decodeURIComponent(rawEmail.replace(/\+/g,  ' '));

        switch (true) {
            case getOS() === 'android':
                const androidLink = getDeepLink(`reset-password?token=${resetToken}&email=${rawEmail}`);
                window.location.href = androidLink;
                break;

            case getOS() === 'ios':
                const iosLink = getDeepLink(`reset-password?token=${resetToken}&email=${rawEmail}`);
                window.location.href = iosLink;
                break;

            default:
                storeUserEmail(decodedEmail);
                history.push('/admin/recovery');
                break;
        }
    }, []);

    return null;
}

export default ResetPassword;
