import { getCurrentUserInfo } from 'Actions';

import AuthRoute from 'Components/AuthRoute';

import ChangePassword from 'Components/ChangePasswordDialog';
import ForgotPassword from 'Components/ForgotPassword';
import ResetPassword from 'Components/ForgotPassword/reset';
import LandingPage from 'Components/LandingPage';
import PageNotFound from 'Components/PageNotFound';
import PasswordRecovery from 'Components/PasswordRecovery';
import ProtectedRoute from 'Components/ProtectedRoute';
import SignIn from 'Components/SignIn';
import TermsOfUse from 'Components/TermsOfUse';
import Verify from 'Components/Verify';

import DefaultLayout from 'Container/DefaultLayout';
import RctThemeProvider from 'Container/RctThemeProvider';
import React, {
  useEffect,
  useMemo,
} from 'react';
import { NotificationContainer } from 'react-notifications';
import {
  useDispatch,
  useSelector,
} from 'react-redux';
import {
  Redirect,
  Route,
  Switch,
} from 'react-router-dom';

import {
  getUserInfo,
  getUserToken,
} from 'Selectors';

import { isUserVerified } from 'Utils/LocalStorageUtils';
import {
  checkIsContestExternalLink,
  getDeepLink,
  getOS,
  prepareLinkForMobile,
} from 'Utils/MobileUtils';

const App = ({ match }) => {
  useMemo(() => { // Run it before first render
    if (checkIsContestExternalLink()) {
      switch (true) {
        case getOS() === 'android':
          const androidLink = prepareLinkForMobile();

          if (androidLink) {
            window.location.href = getDeepLink(androidLink);
          }

          break;

        case getOS() === 'ios':
          const iosLink = prepareLinkForMobile();

          if (iosLink) {
            window.location.href = getDeepLink(iosLink);
          }

          break;

        default:
          window.location.href = '/';
      }
    }
  }, []);

  const verified = isUserVerified();

  const dispatch = useDispatch();

  const userToken = useSelector(getUserToken);
  const userInfo = useSelector(getUserInfo);

  useEffect(() => {
    if (userToken && !userInfo) {
      dispatch(getCurrentUserInfo());
    }
  });

  return (
    <RctThemeProvider>
      <div id="page-top" />
      <NotificationContainer />

      <ChangePassword />

      <Switch>
        <Route exact path="/" component={LandingPage} />
        <Route exact path="/terms" component={TermsOfUse} />

        <Route
          exact
          path="/admin"
          render={
            props => userToken
              ? verified && props.history.location.pathname !== '/admin/verify'
                ? <Redirect to="/admin/watchers" />
                : <Redirect to="/admin/verify" />
              : <Redirect to="/admin/signin" />
          }
        />

        <Route path="/email/verify" component={Verify} token={userToken} />
        <AuthRoute
          path="/reset-password"
          component={ResetPassword}
          token={userToken}
        />
        <AuthRoute path="/admin/signin" component={SignIn} token={userToken} />
        <AuthRoute
          path="/admin/forgot"
          component={ForgotPassword}
          token={userToken}
        />
        <AuthRoute
          path="/admin/recovery"
          component={PasswordRecovery}
          token={userToken}
        />
        <AuthRoute
          path="/admin/terms-of-use"
          component={TermsOfUse}
          token={userToken}
        />

        <ProtectedRoute
          path={`${match.url}admin`}
          component={DefaultLayout}
          token={userToken}
          verified={verified}
        />

        <Route exact path="/contest" component={LandingPage} />
        <Route path="/:id" component={LandingPage} />
        <Route path="/404" component={PageNotFound} />

        <Redirect to="/404" />
      </Switch>
    </RctThemeProvider>
  );
};

export default App;
