import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Form, FormGroup, Input, InputGroup, InputGroupAddon, InputGroupText, Label, Row } from 'reactstrap';
import { RemoveRedEye, RemoveRedEyeOutlined } from '@material-ui/icons';
import IconButton from '@material-ui/core/IconButton';

import FormErrors from 'Constants/FormErrors';
import PositionedFormFeedback from 'Components/PositionedFormFeedback';


import { isInvalidField } from 'Utils/FormUtils';

const SignInToAdminPanelForm = ({
    email,
    onChangeFormField,
    onTogglePasswordVisibility,
    password,
    showPassword,
    submitted
}) => (
    <Form className="landing-admin-panel-dialog">
        <FormGroup row className="align-items-center">
            <Col sm={3}>
                <Label>Email</Label>
            </Col>

            <Col sm={7} className="d-flex justify-content-between">
                <Input
                    invalid={isInvalidField(submitted, email)}
                    type="text"
                    name="email"
                    className="form-control input-lg"
                    placeholder="Email"
                    onChange={onChangeFormField}
                    value={email}
                />

                {isInvalidField(submitted, email) && (
                    <PositionedFormFeedback message={FormErrors.email.blank} leftOffset/>
                )}
            </Col>
        </FormGroup>

        <FormGroup row className="align-items-center">
            <Col sm={3}>
                <Label>Password</Label>
            </Col>

            <Col sm={7} className="d-flex justify-content-center">
                <InputGroup>
                    <Input
                        invalid={isInvalidField(submitted, password)}
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        className="form-control input-lg"
                        placeholder="Password"
                        onChange={onChangeFormField}
                        value={password}
                    />

                    <InputGroupAddon addonType="append">
                        <InputGroupText>
                            <IconButton
                                disabled={password.length === 0}
                                aria-label="eye"
                                tabIndex="-1"
                                onClick={onTogglePasswordVisibility}
                            >
                                {showPassword ? <RemoveRedEye/> : <RemoveRedEyeOutlined/>}
                            </IconButton>
                        </InputGroupText>
                    </InputGroupAddon>
                </InputGroup>

                {isInvalidField(submitted, password) && (
                    <PositionedFormFeedback message={FormErrors.password.blank} leftOffset />
                )}
            </Col>
        </FormGroup>

        <FormGroup>
            <Row className="w-100 m-0">
                <Col sm={10} className="d-flex justify-content-end px-0">
                    <Link to="/admin/forgot" className="mt-3 text-primary">
                        <p className="pr-1">Forgot Password?</p>
                    </Link>
                </Col>
            </Row>
        </FormGroup>
    </Form>
);

export default SignInToAdminPanelForm;
