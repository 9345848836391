import AppConfig from 'Constants/AppConfig';
import React from 'react';
import { HashLink } from 'react-router-hash-link';

const HeaderLogo = () => (
  <div className="header-logo">
    <HashLink to="/#page-top">
      <img
        src={AppConfig.appLogo}
        alt="Logo"
        height={100}
        width={100}
      />
    </HashLink>
  </div>
);

export default HeaderLogo;
