import { createMuiTheme } from '@material-ui/core/styles';

import AppConfig from 'Constants/AppConfig';

const theme = createMuiTheme({
   palette: {
      primary: {
         main: AppConfig.themeColors.white
      },
      secondary: {
         main: AppConfig.themeColors.blue
      }
   },
    overrides: {
        MuiTab: {
            root: {
                fontSize: 20,
                fontWeight: 600,
                textTransform: 'none',
                '&:focus': {
                    outline: 'none'
                },
                '&$selected': {
                    backgroundColor: AppConfig.bgColors.success,
                    color: AppConfig.themeColors.white,
                    '&:hover': {
                        backgroundColor: AppConfig.bgColors.success,
                        color: AppConfig.themeColors.white
                    }
                }
            }
        },
        MUIDataTableHeadCell: {
            root: {
                color: AppConfig.themeColors.black,
                fontSize: '1rem'
            },
            toolButton: {
                backgroundColor: AppConfig.bgColors.white,
                color: AppConfig.themeColors.black,
                padding: '0 7px'
            }
        },
        MuiIconButton: {
            root: {
                borderRadius: 12,
                opacity: .7,
                padding: 0,
                '&:hover': {
                    backgroundColor: 'transparent'
                }
            }
        },
        MuiSelect: {
            icon: {
                right: -3,
                top :'calc(50% - 16px)'
            }
        },
        MuiSvgIcon: {
            root: {
                fontSize: '2rem'
            }
        },
        MuiButton: {
            root: {
                backgroundColor: AppConfig.bgColors.greyLighten,
                borderRadius: 7,
                color: AppConfig.themeColors.muted,
                fontSize: '1rem',
                fontWeight: '500',
                textTransform: 'none',
                '&:focus': {
                    outline: 'none !important',
                },
                '&:disabled': {
                    backgroundColor: AppConfig.bgColors.greyLighten,
                    color: AppConfig.themeColors.black
                }
            },
            text: {
                padding: '5px 20px'
            },
            textPrimary: {
                backgroundColor: AppConfig.bgColors.primary,
                color: AppConfig.themeColors.white,
                '&:hover': {
                    backgroundColor: AppConfig.bgColors.primary
                },
                '&:disabled': {
                    backgroundColor: AppConfig.bgColors.greyLighten,
                    color: AppConfig.themeColors.black
                },
            },
            textSecondary: {
                backgroundColor: AppConfig.bgColors.blue,
                color: AppConfig.themeColors.white,
                '&:hover': {
                    backgroundColor: AppConfig.bgColors.blue
                },
                '&:disabled': {
                    backgroundColor: AppConfig.bgColors.greyLighten,
                    color: AppConfig.themeColors.black
                },
            },
            contained: {
                backgroundColor: AppConfig.themeColors.greyLighten,
            },
            outlined: {
                border: 'none'
            },
        },
    },
   typography: {
      useNextVariants: true,
      // Use the system font instead of the default Roboto font.
      fontFamily: [
         'Rubik',
         'Helvetica',
         'Arial',
         'sans-serif',
      ].join(','),
      htmlFontSize: 16,
      h2: {
         fontSize: 21,
         fontWeight: 400,
      },
      body1: {
         fontWeight: 400,
      },
   },
});

export default theme;
