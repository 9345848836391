import React from 'react';
import _ from 'lodash';

import {
    GET_CONTESTS,
    GET_CONTESTS_SUCCESS,
    GET_CONTESTS_FAILURE,
    GET_CURRENT_CONTEST,
    GET_CURRENT_CONTEST_SUCCESS,
    GET_CURRENT_CONTEST_FAILURE
} from 'Actions/types';

import { api, errorHandler } from 'Api';

import { formatDate } from 'Utils/DateModifiers';

export const getContestList = ({
    date = '',
    page = null,
    perPage = null,
    statuses = []
}) => dispatch => {
    dispatch({ type: GET_CONTESTS });

    const paginate = !_.isNil(page) && !_.isNil(perPage);

    const pagePath = paginate ? `?page=${page + 1}` : '';
    const perPagePath = paginate ? `&per_page=${perPage}`: '';

    const datePath = date instanceof Date ? `${paginate ? '&' : '?'}date=${formatDate(date)}` : '';

    const statusPath = statuses.length === 0
        ? ''
        : `${datePath === '' && !paginate ? '?' : '&'}${statuses.map((status, idx) => `statuses[${idx}]=${status}`).join('&')}`;

    api.get(`admin/contests${pagePath}${perPagePath}${datePath}${statusPath}`)
        .then(response => {
            dispatch({
                type: GET_CONTESTS_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => dispatch(errorHandler(error, GET_CONTESTS_FAILURE)))
};

export const getCurrentContest = () => dispatch => {
    dispatch({ type: GET_CURRENT_CONTEST });

    const statusPath = '?statuses[0]=in_voting';

    api.get(`admin/contests${statusPath}`)
        .then(response => {
            dispatch({
                type: GET_CURRENT_CONTEST_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => dispatch(errorHandler(error, GET_CURRENT_CONTEST_FAILURE)))
};
