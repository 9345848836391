import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import {Row} from "reactstrap";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import React from "react";

export const RewardDialog = (props) => {
    const {isEditMode, isVisible, setIsVisible, children, onSaveAction, disableSaveButton = false, onCloseDialogAction} = props;

    const onCloseDialog = () => {
        setIsVisible(false);

        onCloseDialogAction?.();
    }

    const dialogTitle = isEditMode ? 'Change Reward' : 'Create Reward'

    return (
        <Dialog
            fullWidth
            open={isVisible}
            onClose={onCloseDialog}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                {dialogTitle}
            </DialogTitle>

            <DialogContent>
                {children}
            </DialogContent>

            <DialogActions>
                <Row className="m-3">
                    <Button
                        color="primary"
                        variant="text"
                        className="mr-3 dialog-button-custom"
                        onClick={onSaveAction}
                        disabled={disableSaveButton}
                    >
                        {'Save'}
                    </Button>

                    <Button
                        color="default"
                        variant="text"
                        onClick={onCloseDialog}
                    >
                        {'Cancel'}
                    </Button>
                </Row>
            </DialogActions>
        </Dialog>
    )
}
