import React from 'react';
import { useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';

const SidebarItem = ({ name, onClick, route }) => {
    const history = useHistory();

    const { pathname } = history.location;

    const active = pathname === `/admin/${route}`;

    const color = active ? 'primary' : 'default';

    return (
        <Button
            color={color}
            className="sidebar-button font-weight-bold"
            variant="text"
            onClick={() => onClick(route)}
        >
            {name}
        </Button>
    )
};

export default SidebarItem;
