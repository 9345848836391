import {
    ADD_PHOTO_TO_REWARD,
    ADD_PHOTO_TO_REWARD_FAILURE,
    ADD_PHOTO_TO_REWARD_SUCCESS,
    ADD_REWARD,
    ADD_REWARD_FAILURE,
    ADD_REWARD_SUCCESS,
    DELETE_PHOTO_OF_REWARD,
    DELETE_PHOTO_OF_REWARD_FAILURE,
    DELETE_PHOTO_OF_REWARD_SUCCESS, DELETE_REWARD, DELETE_REWARD_FAILURE, DELETE_REWARD_SUCCESS,
    EDIT_REWARD, EDIT_REWARD_FAILURE,
    EDIT_REWARD_SUCCESS,
    GET_REWARD_BY_ID,
    GET_REWARD_BY_ID_FAILURE,
    GET_REWARD_BY_ID_SUCCESS,
    GET_REWARDS,
    GET_REWARDS_FAILURE,
    GET_REWARDS_SUCCESS, SET_IS_INVALID__REWARD_FORM,
} from "Actions/types";
import {api, errorHandler, successMessageHandler} from "Api";
import {NotificationManager} from "react-notifications";
import {Base64ToBlob} from "Utils/FileUtils";

export const getRewards = () => dispatch => {
    dispatch({ type: GET_REWARDS });

    api.get('admin/rewards')
        .then(response => {
            dispatch({
                type: GET_REWARDS_SUCCESS,
                payload: response.data.data
            });
        })
        .catch(error => dispatch(errorHandler(error, GET_REWARDS_FAILURE)))
};

export const addReward = (params, onSuccess) => dispatch => {
    const { name, amount, description, image } = params

    const formData = new FormData();

    formData.append('photo', Base64ToBlob(image))
    formData.append('amount_of_users', amount)
    formData.append('description', description)
    formData.append('name', name)

    dispatch({ type: ADD_REWARD });

    api.post('admin/rewards', formData)
        .then(response => {
            dispatch({
                type: ADD_REWARD_SUCCESS,
                payload: response.data
            });

            onSuccess?.();

            if (!successMessageHandler(response)) {
                NotificationManager.success('Success!');
            } else {
                successMessageHandler(response)
            }
        })
        .catch(error => dispatch(errorHandler(error, ADD_REWARD_FAILURE)))
};

export const getRewardById = (params) => dispatch => {
    const { rewardId } = params

    dispatch({ type: GET_REWARD_BY_ID });

    api.get(`admin/rewards/${rewardId}`)
        .then(response => {
            dispatch({
                type: GET_REWARD_BY_ID_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => dispatch(errorHandler(error, GET_REWARD_BY_ID_FAILURE)))
};

export const editReward = (params, onSuccess) => (dispatch, getState) => {
    const { rewardId, name, amount, description, image } = params

    const currentReward = getState().rewards.entities[rewardId];

    const formData = new FormData();

    if(currentReward.photo !== image){
        formData.append('photo', Base64ToBlob(image))
    }

    formData.append('amount_of_users', amount)
    formData.append('description', description)
    formData.append('name', name)

    dispatch({ type: EDIT_REWARD });

    api.post(`admin/rewards/${rewardId}`, formData)
        .then(response => {
            dispatch({
                type: EDIT_REWARD_SUCCESS,
                payload: response.data
            });

            onSuccess?.();

            if (!successMessageHandler(response)) {
                NotificationManager.success('Success!');
            } else {
                successMessageHandler(response)
            }
        })
        .catch(error => dispatch(errorHandler(error, EDIT_REWARD_FAILURE)))
};

export const deleteReward = (params, onSuccess) => dispatch => {
    const {rewardId} = params

    dispatch({ type: DELETE_REWARD });

    api.delete(`admin/rewards/${rewardId}`)
        .then(response => {
            dispatch({
                type: DELETE_REWARD_SUCCESS,
            });

            onSuccess?.();

            if (!successMessageHandler(response)) {
                NotificationManager.success('Success!');
            } else {
                successMessageHandler(response)
            }
        })
        .catch(error => dispatch(errorHandler(error, DELETE_REWARD_FAILURE)))
}

export const setIsInvalidRewardsForm = (isInvalid) => dispatch => {
    dispatch({type: SET_IS_INVALID__REWARD_FORM, payload: isInvalid})
}
