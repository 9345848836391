// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/cssWithMappingToString.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "./Rubik-Italic-VariableFont_wght.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "./Rubik-VariableFont_wght.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "/* rubik-regular - latin */\n@font-face {\n    font-family: 'Rubik';\n    font-style: normal;\n    font-weight: 400;\n    src: url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n    src: local(''),\n    url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ") format('ttf')\n}\n", "",{"version":3,"sources":["webpack://./resources/js/assets/fonts/rubik/rubik.css"],"names":[],"mappings":"AAAA,0BAA0B;AAC1B;IACI,oBAAoB;IACpB,kBAAkB;IAClB,gBAAgB;IAChB,4CAA8C;IAC9C;;AAEJ","sourcesContent":["/* rubik-regular - latin */\n@font-face {\n    font-family: 'Rubik';\n    font-style: normal;\n    font-weight: 400;\n    src: url('Rubik-Italic-VariableFont_wght.ttf');\n    src: local(''),\n    url('Rubik-VariableFont_wght.ttf') format('ttf')\n}\n"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
