import React from 'react';
import { NotificationManager } from 'react-notifications';

import {
    CHANGE_WATCHERS_LIST_PAGE,
    CHANGE_WATCHERS_LIST_SIZE_PER_PAGE,
    CREATE_WATCHER,
    CREATE_WATCHER_SUCCESS,
    CREATE_WATCHER_FAILURE,
    GET_WATCHERS,
    GET_WATCHERS_SUCCESS,
    GET_WATCHERS_FAILURE,
    REMOVE_WATCHER,
    REMOVE_WATCHER_SUCCESS,
    REMOVE_WATCHER_FAILURE
} from 'Actions/types';

import { api, errorHandler, successMessageHandler } from 'Api';

export const createNewWatcher = (watcher, onSuccess) => dispatch => {
    dispatch({ type: CREATE_WATCHER });

    api.post('admin/users/create', watcher)
        .then(response => {
            dispatch({
                type: CREATE_WATCHER_SUCCESS,
                payload: response.data
            });

            onSuccess();

            if (!successMessageHandler(response)) {
                NotificationManager.success('Success!');
            } else {
                successMessageHandler(response)
            }
        })
        .catch(error => dispatch(errorHandler(error, CREATE_WATCHER_FAILURE)))
};

export const getWatchersList = ({
    roles,
    page = 0,
    search = '',
    sizePerPage = 10
}) => dispatch => {
    dispatch({ type: GET_WATCHERS });

    const searchPath = search.length === 0 ? '' : `&search=${search}`;

    api.get(`admin/users?roles[]=${roles}&page=${page + 1}&per_page=${sizePerPage}${searchPath}`)
        .then(response => {
            dispatch({
                type: GET_WATCHERS_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => dispatch(errorHandler(error, GET_WATCHERS_FAILURE)))
};

export const deleteWatcher = (id, onSuccess) => dispatch => {
    dispatch({ type: REMOVE_WATCHER });

    api.delete(`admin/users/${id}/delete`)
        .then(response => {
            dispatch({
                type: REMOVE_WATCHER_SUCCESS,
                payload: response.data
            });

            if (!successMessageHandler(response)) {
                NotificationManager.success('Success!');
            } else {
                successMessageHandler(response);
            }

            onSuccess();
        })
        .catch(error => dispatch(errorHandler(error, REMOVE_WATCHER_FAILURE)))
};

export const changeWatchersListPage = page => dispatch => {
    dispatch({
        type: CHANGE_WATCHERS_LIST_PAGE,
        payload: page
    });
};

export const changeWatchersListSizePerPage = sizePerPage => dispatch => {
    dispatch({
        type: CHANGE_WATCHERS_LIST_SIZE_PER_PAGE,
        payload: sizePerPage
    });
};
