import React, {useRef} from "react";
import {Input} from "reactstrap";
import uploadImageSvg from "../../assets/img/upload-solid.svg";
import closeSvg from "../../assets/img/circle-xmark-solid.svg";
import IconButton from "@material-ui/core/IconButton";
import {isInvalidFormatOrSizeOfRewardPhoto} from "Utils/FormUtils";

export const ImageInputWithPreview = (props) => {
    const {photo, setPhoto, isInvalid = false, onInputAction} = props;

    const hiddenFileInput = useRef(null);

    const onInputClick = (e) => {
        e.preventDefault();

        const file = e.target.files[0];

        onInputAction?.(file)

        if(isInvalidFormatOrSizeOfRewardPhoto(photo)){
            return;
        }

        const reader = new FileReader();

        reader.onloadend = () => {
            setPhoto(reader.result);
        };

        if (file) {
            reader.readAsDataURL(file);
        }
    };

    const onUpload = () => {
        hiddenFileInput.current.click();
    };

    const onClose = (event) => {
        event.stopPropagation();

        setPhoto('');
    }

    const photoUploaded = photo !== '';

    return (
        <div>
            <Input
                invalid={false}
                type={"file"}
                name={'photo'}
                className="d-none"
                onChange={onInputClick}
                innerRef={hiddenFileInput}
            />
            <div
                className={`upload-touchable-area ${isInvalid && 'border border-danger'}`}
                onClick={onUpload}
            >
                {
                    photoUploaded && (
                        <IconButton
                            className={'close-preview-button'}
                            onClick={onClose}
                        >
                            <img src={closeSvg} alt={'close'} className={'close-icon'} />
                        </IconButton>
                    )
                }
                {
                    photoUploaded ? (
                            <img src={photo} alt={'reward photo'} className={'preview-image'} />
                        )
                        : (
                            <>
                                <img src={uploadImageSvg} alt={'upload'} className={'is-m text-gray'} />
                                <span className={'mt-3 font-xs'}>{'maximal size is 10 MB png, jpeg'}</span>
                            </>
                        )
                }
            </div>
        </div>
    )
}
