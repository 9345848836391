import React, { useState } from 'react';
import { Row } from 'reactstrap';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import DisapproveForm from './DisapproveForm';

const DisapproveDialog = ({ onDisapprove, show, toggle }) =>  {
    const [reason, setReason] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const onClearForm = () => {
        setReason('');
    };

    const onCloseDialog = () => {
        toggle();

        onClearForm();
    }

    const onDisapproveSubmit = () => {
        if (reason !== '' ) {
            onDisapprove(reason);

            onCloseDialog();

            setSubmitted(() => false);
        } else {
            setSubmitted(() => true);
        }
    };

    const onChangeFormField = e => {
        const { value } = e.target;

        setReason(() => value);
        setSubmitted(() => false)
    };

    return (
        <Dialog
            fullWidth
            open={show}
            onClose={toggle}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                Disapprove Video
            </DialogTitle>

            <DialogContent>
                <DisapproveForm
                    reason={reason}
                    submitted={submitted}
                    onChangeFormField={onChangeFormField}
                />
            </DialogContent>

            <DialogActions>
                <Row className="m-3">
                    <Button
                        color="primary"
                        variant="text"
                        className="mr-3"
                        onClick={onDisapproveSubmit}
                    >
                        Disapprove
                    </Button>

                    <Button
                        color="default"
                        variant="text"
                        onClick={onCloseDialog}
                    >
                        Cancel
                    </Button>
                </Row>
            </DialogActions>
        </Dialog>
    );
}

export default DisapproveDialog;
