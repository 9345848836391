// Clear storage

export const clearLocalStorage = () => {
    localStorage.clear();
}

// Reset password token

export const getResetPasswordTokenFromStorage = () => localStorage.getItem('reset_token');

export const removeResetPasswordTokenFromStorage = () => {
    localStorage.removeItem('reset_token');
};

export const storeResetPasswordToken = token => {
    localStorage.setItem('reset_token', token);
}

// User email

export const getUserEmailFromStorage = () => localStorage.getItem('email');

export const storeUserEmail = email => {
    localStorage.setItem('email', email);
}

export const removeUserEmailFromStorage = () => {
    localStorage.removeItem('email');
};

// User role

export const getUserRoleFromStorage = () => localStorage.getItem('role');

export const storeUserRole = role => {
    localStorage.setItem('role', role);
}

export const isPortalAdmin = () => getUserRoleFromStorage() === 'portal-admin';

export const isSuperAdmin = () => getUserRoleFromStorage() === 'super-admin';

export const isAdmin = () => isPortalAdmin() || isSuperAdmin();

// User token

export const getUserTokenFromStorage = () => localStorage.getItem('token');

export const storeUserToken = token => {
    localStorage.setItem('token', token);
}

// Verify

export const isVerifiedFieldExist = () => localStorage.getItem('verified');

export const isUserVerified = () => localStorage.getItem('verified') === 'true';

export const isUserNotVerified = () => localStorage.getItem('verified') === 'false';

export const setVerified = () => {
    localStorage.setItem('verified', 'true');
};

export const setNotVerified = () => {
    localStorage.setItem('verified', 'false');
}
