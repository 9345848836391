import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'reactstrap';
import moment from 'moment';

import {getContestList, deleteVideoFromPoolOrContest} from 'Actions';

import { VideosCalendarInTheContest } from 'Components/Calendar';
import { VideoItem, VideoGroup } from 'Components/Video';

import { getContests } from 'Selectors';
import {TwoActionsDialog} from "Components/TwoActionsDialog";

const ContestList = () => {
    const dispatch = useDispatch();

    const contests = useSelector(getContests);

    const [date, setDate] = useState(new Date());
    const [videoIdToDelete, setVideoIdToDelete] = useState(false)
    const [showDialog, setShowDialog] = useState(false)

    const videoListTopRef = useRef(null);

    const getContestListAction = () => {
        dispatch(getContestList({ date, statuses: [] }));
    };

    const onChangeDate = date => {
        setDate(prevDate => moment(date).isSame(moment(prevDate)) ? new Date() : date);
    };

    const onNegativeDialogAction = () => {
        setShowDialog(false)
    }

    const onPositiveDialogAction = () => {
        dispatch(deleteVideoFromPoolOrContest({ videoId:videoIdToDelete, onSuccess: () => {
                getContestListAction();
            }}))

        setShowDialog(false)
    }

    useEffect(() => {
        getContestListAction();
    }, [date]);

    return (
        <div className="pt-10 px-40 pb-30">
            <div ref={videoListTopRef} />

            <VideosCalendarInTheContest date={date} onChangeDate={onChangeDate} />

            <Row className="flex-wrap px-4 justify-content-center">
                {contests.map(contest => (
                    <VideoGroup
                        key={contest.id}
                        border
                        date={contest.date}
                    >
                        {
                            contest.video_1.status !== 'deleted' && <VideoItem hasActions={false} video={contest.video_1} onDelete={() => {
                                setVideoIdToDelete(contest.video_1.id)

                                setShowDialog(true)
                            }} hasDeleteButton={true} />
                        }
                        {
                            contest.video_2.status !== 'deleted' && <VideoItem hasActions={false} video={contest.video_2} onDelete={() => {
                                setVideoIdToDelete(contest.video_2.id)

                                setShowDialog(true)
                            }} hasDeleteButton={true} />
                        }
                    </VideoGroup>
                ))}
            </Row>
            <TwoActionsDialog
                isOpen={showDialog}
                onClose={onNegativeDialogAction}
                onNegativeAction={onNegativeDialogAction}
                onPositiveAction={onPositiveDialogAction}
                dialogTitle={"Are you sure you want to delete the video from the Contest?"}
                positiveText={"Delete"}
                negativeText={"Cancel"}
            />
        </div>
    );
}

export default ContestList;
