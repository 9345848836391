import { useEffect, useState } from 'react';

const useContestEndTimer = initialTime => {
    const [contestEndTime, setContestEndTime] = useState(initialTime);

    useEffect(() => {
        const timer1 = setInterval(() => {
            setContestEndTime(prevTime => prevTime - 1) // subtract 1 second every second
        }, 1000);

        return () => {
            clearTimeout(timer1);
        };
    }, []);

    return contestEndTime;
};

export default useContestEndTimer;
