import React from 'react';
import { Input } from 'reactstrap';

const Vote = ({ video }) => (
    <div className="d-flex flex-column align-items-center">
        <h3>{video.title}</h3>
        <Input className="text-center" disabled value={video.votes || 0} />
    </div>
);

export default Vote;
