import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { sendResetPasswordLink } from 'Actions';

import ForgotPasswordForm from './form';

const ForgotPassword = ({ loading }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const onResetPasswordFormSubmit = () => {
        if (email !== '') {
            dispatch(sendResetPasswordLink({ email }, history));

            setSubmitted(() => false);
        } else {
            setSubmitted(() => true);
        }
    };

    const onChangeFormField = e => {
        const { value } = e.target;

        setEmail(() => value);
        setSubmitted(() => false)
    };

    return (
        <ForgotPasswordForm
            email={email}
            loading={loading}
            submitted={submitted}
            onChangeFormField={onChangeFormField}
            onResetPasswordFormSubmit={onResetPasswordFormSubmit}
        />
    );
}

export default ForgotPassword;
