import React from 'react';
import { useSelector } from 'react-redux';
import { Col, Row } from 'reactstrap';

import { Push } from 'Components/Votes';
import { VideoGroup, VideoItem } from 'Components/Video';

import { getCurrentContestData } from 'Selectors';

const CurrentContest = () => {
    const currentContest = useSelector(getCurrentContestData);

    return currentContest && (
        <>
            <Row className="p-3">
                <Col md={12}>
                    <h1>Current contest</h1>
                </Col>
            </Row>

            <Row className="w-100">
                <VideoGroup
                    classNames="w-100 justify-content-around"
                    key={currentContest.id}
                    border={false}
                >
                    <VideoItem votes hasActions={false} video={currentContest.video_1} />

                    <Push push={currentContest.push} />

                    <VideoItem votes hasActions={false} video={currentContest.video_2} />
                </VideoGroup>
            </Row>
        </>

    );
};


export default CurrentContest;
