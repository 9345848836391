import React, {useEffect, useState} from "react";
import {PageLayout} from "Components/PageLayout";
import {Row} from "reactstrap";
import {ComplaintsVideoItem} from "Components/ComplaintsVideoItem";
import {ManageComplaintDialog} from "Components/ManageComplaintDialog";
import {useDispatch, useSelector} from "react-redux";
import {deleteVideoWithComplaints, getVideosWithComplaintsList, removeComplaintsOnVideo} from "Actions";
import {getVideosWithComplaintsSelector} from "Selectors";
import {TwoActionsDialog} from "Components/TwoActionsDialog";

export const Complaints = ({ match, role }) => {
    const [activeManagingVideo, setActiveManagingVideo] = useState({})
    const [isActiveManageDialog, setIsActiveManageDialog] = useState(false)
    const [isActiveDeleteDialog, setIsActiveDeleteDialog] = useState(false)
    const data = useSelector(getVideosWithComplaintsSelector)
    const dispatch = useDispatch()

    const onRemoveOrDeleteComplaints = (type) => () => {
        const action = type === 'delete' ? deleteVideoWithComplaints : removeComplaintsOnVideo

        dispatch(action({videoId: activeManagingVideo.id, onSuccess: () => {
                dispatch(getVideosWithComplaintsList())

                setIsActiveManageDialog(false)

                setActiveManagingVideo({})
            }}))

        setIsActiveManageDialog(false)
        setIsActiveDeleteDialog(false)
    }

    useEffect(() => {
        dispatch(getVideosWithComplaintsList())
    }, []);

    const onNegativeDeleteDialogAction = () => {
        setIsActiveDeleteDialog(false)
    }

    const onPositiveDeleteDialogAction = () => {
        onRemoveOrDeleteComplaints('delete')()
    }

    const openDeleteDialogAction = () => {
        setIsActiveDeleteDialog(true)
    }

    return (
        <PageLayout
            match={match}
            role={role}
            pageTitle={'Complaints'}
            metaDataName={'complaints'}
            metaDataContent={'Complaints'}
        >
            <div className="pt-10 pb-30">
                <Row>
                    {data.map(video => (
                        <div key={video.id}>
                            <ComplaintsVideoItem
                                video={video}
                                onManageComplaint={() => {
                                    setActiveManagingVideo(video)

                                    setIsActiveManageDialog(true)
                                }}
                            />
                        </div>
                    ))}
                </Row>
            </div>
            <ManageComplaintDialog
                videoTitle={activeManagingVideo.title}
                videoComplaints={activeManagingVideo.complaints}
                isActive={isActiveManageDialog}
                onDeleteVideo={openDeleteDialogAction}
                onRemoveComplaints={onRemoveOrDeleteComplaints('remove')}
                setIsActive={setIsActiveManageDialog}
            />
            <TwoActionsDialog
                isOpen={isActiveDeleteDialog}
                onClose={onNegativeDeleteDialogAction}
                onNegativeAction={onNegativeDeleteDialogAction}
                onPositiveAction={onPositiveDeleteDialogAction}
                dialogTitle={"Are you sure you want to delete the video?"}
                positiveText={"Delete"}
                negativeText={"Cancel"}
            />
        </PageLayout>
        )

}
