import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import React from "react";
import {Button, Row} from "reactstrap";
import DialogActions from "@material-ui/core/DialogActions";

export const TwoActionsDialog = (props) => {
    const { onPositiveAction, isOpen, onNegativeAction, onClose, dialogTitle, positiveText, negativeText } = props;

    return (
        <Dialog
            open={isOpen}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                {dialogTitle}
            </DialogTitle>
            <DialogActions>
                <Row className="mb-3 mr-3 ml-3">
                    <Button
                        color="danger"
                        variant="text"
                        className="mr-3 min-width-100"
                        onClick={onPositiveAction}
                    >
                        <span className="font-weight-light">
                            {positiveText}
                        </span>
                    </Button>
                    <Button
                        color="default"
                        variant="text"
                        onClick={onNegativeAction}
                        className={'min-width-100'}
                    >
                        <span className="font-weight-light">
                            {negativeText}
                        </span>
                    </Button>
                </Row>
            </DialogActions>
        </Dialog>
    )
}
