import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row } from 'reactstrap';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { logoutAdmin, passwordChangeNecessitySet, updateUserProfile } from 'Actions';

import ChangePasswordForm from './form';

import { isUserShouldChangePassword } from 'Selectors';

const ChangePassword = () => {
    const dispatch = useDispatch();

    const shouldUserHaveChangePassword = useSelector(isUserShouldChangePassword);

    const [modalShow, setModalShow] = useState(false);

    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [passwordConfirm, setConfirmPassword] = useState('');

    const [showPassword, setPasswordVisibility] = useState(false);
    const [showNewPassword, setNewPasswordVisibility] = useState(false);
    const [showPasswordConfirm, setPasswordConfirmVisibility] = useState(false);

    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        onToggleModalShow(shouldUserHaveChangePassword);
    }, [shouldUserHaveChangePassword]);

    const onToggleModalShow = show => {
        setModalShow(() => show);
    };

    const onTogglePasswordVisibility = useCallback(() => {
        setPasswordVisibility(state => !state)
    }, [showPassword]);

    const onToggleNewPasswordVisibility = useCallback(() => {
        setNewPasswordVisibility(state => !state)
    }, [showNewPassword]);

    const onTogglePasswordConfirmVisibility = useCallback(() => {
        setPasswordConfirmVisibility(state => !state)
    }, [showPasswordConfirm]);

    const onLogoutHandler = () => {
        dispatch(logoutAdmin());

        onChangePasswordNecessityHandler(false);
    };

    const onChangePasswordNecessityHandler = flag => {
        dispatch(passwordChangeNecessitySet(flag));
    };

    const onChangePasswordSubmit = () => {
        if (
            password !== '' &&
            newPassword !== '' &&
            newPassword === passwordConfirm
        ) {
            const dataToUpdate = {
                current_password: password,
                password: newPassword,
                password_confirmation: passwordConfirm
            };

            const onSuccess = () => {
                onChangePasswordNecessityHandler(false);

                window.location.reload();
            }

            dispatch(updateUserProfile(dataToUpdate, onSuccess));

            setSubmitted(() => false);
        } else {
            setSubmitted(() => true);
        }
    };

    const onChangeFormField = useCallback(e => {
        const { name, value } = e.target;

        switch (name) {
            case 'password': setPassword(() => value); break;
            case 'newPassword': setNewPassword(() => value); break;
            case 'passwordConfirm': setConfirmPassword(() => value); break;
            default: break;
        }

        setSubmitted(() => false)
    }, [password, newPassword, passwordConfirm]);

    return (
        <Dialog open={modalShow} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">
                <h1>Change password</h1>
            </DialogTitle>

            <DialogContent>
                <ChangePasswordForm
                    password={password}
                    newPassword={newPassword}
                    passwordConfirm={passwordConfirm}
                    onChangeFormField={onChangeFormField}
                    onChangePasswordSubmit={onChangePasswordSubmit}
                    onTogglePasswordVisibility={onTogglePasswordVisibility}
                    onToggleNewPasswordVisibility={onToggleNewPasswordVisibility}
                    onTogglePasswordConfirmVisibility={onTogglePasswordConfirmVisibility}
                    showPassword={showPassword}
                    showNewPassword={showNewPassword}
                    showPasswordConfirm={showPasswordConfirm}
                    submitted={submitted}
                />
            </DialogContent>

            <DialogActions>
                <Row className="change-password-form-actions">
                    <Button
                        variant="contained"
                        color="secondary"
                        className="text-white font-weight-bold text-transform-none mr-3"
                        onClick={onLogoutHandler}
                    >
                        Log out
                    </Button>

                    <Button
                        color="primary"
                        className="py-2 mr-1 font-weight-bold"
                        onClick={onChangePasswordSubmit}
                        variant="text"
                    >
                        Change
                    </Button>
                </Row>
            </DialogActions>
        </Dialog>
    );
};

export default ChangePassword;
