import React, {useEffect, useRef, useState} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col,  Row } from 'reactstrap';

import {getVideoList, deleteVideoFromPoolOrContest} from 'Actions';

import CustomPagination from 'Components/Pagination';
import { VideoItem } from 'Components/Video';

import { getVideoCurrentPage, getVideoLastPage, getVideos, getVideoPerPage } from 'Selectors';

import { filterVideosByDate, formatDate, getUniqVideoDates } from 'Utils/DateModifiers'
import {TwoActionsDialog} from "Components/TwoActionsDialog";

const PoolList = () => {
    const dispatch = useDispatch();

    const videos = useSelector(getVideos);

    const currentPage = useSelector(getVideoCurrentPage);
    const lastPage = useSelector(getVideoLastPage);
    const perPage = useSelector(getVideoPerPage);

    const videoListTopRef = useRef(null);

    const [showDialog, setShowDialog] = useState(false)
    const [videoIdToReject, setVideoIdToReject] = useState(false)

    const getVideoListAction = ({ page = 0, perPage = 10 }) => {
        dispatch(getVideoList({
            page,
            perPage,
            status: 'in_the_pool'
        }));
    };

    useEffect(() => {
        getVideoListAction({ page: 0, perPage: 10 });
    }, []);

    const onNegativeDialogAction = () => {
        setShowDialog(false)
    }

    const onPositiveDialogAction = () => {
        dispatch(deleteVideoFromPoolOrContest({videoId:videoIdToReject, onSuccess: () => {
                getVideoListAction({page: 0, perPage})
            }}))

        setShowDialog(false)
    }

    const uniqDates = getUniqVideoDates(videos);

    return (
        <div className="pt-10 pb-30">
            <div ref={videoListTopRef} />

            <Row className="flex-wrap px-4">
                {uniqDates.map(date => (
                    <Col md={12} key={date}>
                        <Row className="px-3 pt-3">
                            <Col md={12}>
                                <h2 className="mb-0">
                                    {formatDate(new Date(date))}
                                </h2>
                            </Col>
                        </Row>

                        <Row>
                            {filterVideosByDate(date, videos).map(video => (
                                <div key={video.id}>
                                    <VideoItem hasActions={false} video={video} onReject={() => {
                                        setVideoIdToReject(video.id)

                                        setShowDialog(true)
                                    }} hasRejectButton={true} />
                                </div>
                            ))}
                        </Row>
                    </Col>
                ))}
            </Row>

            <TwoActionsDialog
                isOpen={showDialog}
                onClose={onNegativeDialogAction}
                onNegativeAction={onNegativeDialogAction}
                onPositiveAction={onPositiveDialogAction}
                dialogTitle={"Are you sure you want to reject the video from the Pool?"}
                positiveText={"Reject"}
                negativeText={"Cancel"}
            />

            <Row className="py-3 px-40">
                <CustomPagination
                    page={currentPage}
                    lastPage={lastPage}
                    perPage={perPage}
                    onChangeHandler={getVideoListAction}
                    data={videos}
                    topRef={videoListTopRef}
                />
            </Row>
        </div>
    )
}

export default PoolList;
