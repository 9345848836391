import React from 'react';
import { Helmet } from 'react-helmet';

import { RctCard } from 'Components/RctCard';
import PageTitleBar from 'Components/PageTitleBar/PageTitleBar';

export const PageLayout = (props) => {

    const { match, role, pageTitle, metaDataName, metaDataContent, children} = props

    const showPageTitleBar = match && role;

    return (
        <div className="h-100">
            <Helmet>
                <title>{pageTitle}</title>
                <meta name={metaDataName} content={metaDataContent} />
            </Helmet>

            {showPageTitleBar && (
                <PageTitleBar
                    title={pageTitle}
                    match={match}
                />
            )}

            <RctCard colClasses="h-100" customClasses="h-100">
                <div className="rct-tabs h-100">{children}</div>
            </RctCard>
        </div>
    )
};
