import React from 'react';

import AppConfig from 'Constants/AppConfig';

const HeaderLogo = () => (
    <div className="header-logo">
        <img
            src={AppConfig.appLogo}
            alt="Logo"
            height={100}
            width={100}
        />
    </div>
);

export default HeaderLogo;
