import {
    GET_LANDING_CONTENT_VOTING,
    GET_LANDING_CONTENT_VOTING_SUCCESS,
    GET_LANDING_CONTENT_VOTING_FAILURE,
    GET_LANDING_TERMS,
    GET_LANDING_TERMS_SUCCESS,
    GET_LANDING_TERMS_FAILURE
} from 'Actions/types';

const INIT_STATE = {
    contest: null,
    terms: null,
    loading: false,
    error: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_LANDING_CONTENT_VOTING:
            return {
                ...state,
                loading: true
            };

        case GET_LANDING_CONTENT_VOTING_SUCCESS:
            return {
                ...state,
                loading: false,
                contest: action.payload
            };

        case GET_LANDING_CONTENT_VOTING_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case GET_LANDING_TERMS:
            return {
                ...state,
                loading: true
            };

        case GET_LANDING_TERMS_SUCCESS:
            return {
                ...state,
                loading: false,
                terms: action.payload
            };

        case GET_LANDING_TERMS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        default: return state;
    }
}
