import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { verifyEmail } from 'Actions';

import { getDeepLink, getOS } from 'Utils/MobileUtils';

const Verify = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    useEffect(() => {
        const parsedUrl = window.location.href.split('/');
        const userId = parsedUrl[5];
        const token = parsedUrl[6].split('?')[0];
        const params = history.location.search;

        switch (true) {
            case getOS() === 'android':
                const androidLink = getDeepLink(`confirm-email${params}&token=${token}&id=${userId}`);
                window.location.href = androidLink;
                break;

            case getOS() === 'ios':
                const iosLink = getDeepLink(`confirm-email${params}&token=${token}&id=${userId}`);
                window.location.href = iosLink;
                break;

            default:
                dispatch(verifyEmail({ history }));
                break;
        }
    }, []);

    return null;
}

export default Verify;
