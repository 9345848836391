import React from 'react';

const LandingPage = () => {

  return (
      <iframe src="/landingPage/index.html" title="Landing Page" sandbox="allow-popups allow-same-origin allow-scripts" style={{ width: '100%', height: '100vh', border: 'none' }}
      />
  );
};

export default LandingPage;
