import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import SidebarItem from './SidebarItem';

import { getUserInfo, isSuperAdminCheck } from 'Selectors';

const Sidebar = () => {
    const history = useHistory();

    const isSuperAdmin = useSelector(isSuperAdminCheck);
    const userInfo = useSelector(getUserInfo);

    const showToggle = window.screen.width < 768;

    const [showSidebar, setShowSidebar] = useState(!showToggle);

    const onToggleSidebar = () => {
        setShowSidebar(prev => !prev);
    };

    const onSwitchSidebarMenu = path => {
        if (showToggle) {
            onToggleSidebar();
        }

        history.push(`/admin/${path}`);
    };

    const commonSidebarItemProps = {
        onClick: onSwitchSidebarMenu
    };

    return (
        <div className="rct-sidebar">
            <IconButton
                disableRipple
                className="hamburger"
                mini="true"
                aria-label="Menu"
                onClick={onToggleSidebar}
            >
                <MenuIcon color="inherit" />

                {showSidebar
                    ? <KeyboardArrowUpIcon fontSize="small" color="inherit" />
                    : <KeyboardArrowDownIcon fontSize="small" color="inherit" />
                }
            </IconButton>

            {showSidebar && (
                <>
                    <SidebarItem route="video" name="Video managing" {...commonSidebarItemProps} />
                    <SidebarItem route="contest" name="Contests results" {...commonSidebarItemProps} />

                    {userInfo && isSuperAdmin && (
                        <SidebarItem route="watchers" name="Watcher's list" {...commonSidebarItemProps} />
                    )}

                    <SidebarItem route="users" name="User's list" {...commonSidebarItemProps} />
                    <SidebarItem route="terms" name="Rules & Regulations" {...commonSidebarItemProps} />
                    <SidebarItem route="rewards" name="Reward policy" {...commonSidebarItemProps} />
                    <SidebarItem route="pushNotifications" name="Push notifications" {...commonSidebarItemProps} />
                    <SidebarItem route="complaints" name="Complaints" {...commonSidebarItemProps} />
                </>
            )}
        </div>
    );
}

export default Sidebar;
