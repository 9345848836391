import React from 'react';
import { Col, FormGroup, Input, Row } from 'reactstrap';

import FormErrors from 'Constants/FormErrors';

import PositionedFormFeedback from 'Components/PositionedFormFeedback';

import { isInvalidField } from 'Utils/FormUtils';

const DisapproveForm = ({ onChangeFormField, reason, submitted }) => (
    <FormGroup row className="has-wrapper text-left pl-3">
        <Row className="py-3 w-100">
            <Col md={12} className="d-flex justify-content-center">
                <Input
                    invalid={isInvalidField(submitted, reason)}
                    type="textarea"
                    name="reason"
                    className="form-control input-lg disapprove-reason-field"
                    placeholder="Please type a reason to disapprove video"
                    onChange={onChangeFormField}
                    value={reason}
                />

                {isInvalidField(submitted, reason) && (
                    <PositionedFormFeedback
                        message={FormErrors.reasonToDisapprove.blank}
                        leftOffset
                    />
                )}
            </Col>
        </Row>
    </FormGroup>
);

export default DisapproveForm;
