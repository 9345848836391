import React from 'react';
import { Input } from 'reactstrap';

const Push = ({ push }) => (
    <div className="current-contest-push-container">
        <h3>Push</h3>
        <Input className="text-center" disabled value={push} />
    </div>
);

export default Push;
