import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import ContestList from './component/ContestList';
import PoolList from './component/PoolList';
import UploadedList from './component/UploadedList';
import {PageLayout} from "Components/PageLayout";
import {WaitingForRoomInPoolList} from "Routes/videoManaging/component/WaitingForRoomInPoolList";

const VideoManaging = ({ match, role }) => {
    const [activeTab, setActiveTab] = useState(0);

    const onToggleActiveTab = (e, value) => {
        setActiveTab(() => value);
    }

    const renderTabContent = () => {
        switch (activeTab) {
            case 0: return <UploadedList />;
            case 1: return <WaitingForRoomInPoolList />;
            case 2: return <PoolList />;
            case 3: return <ContestList />;
            default: return <UploadedList />;
        }
    };

    return (
        <PageLayout
            match={match}
            role={role}
            pageTitle={'Video managing'}
            metaDataName={'video'}
            metaDataContent={'Video managing'}
        >
            <AppBar position="static" color="default">
                <Tabs
                    value={activeTab}
                    onChange={onToggleActiveTab}
                    indicatorColor="primary"
                    textColor="primary"
                    variant="fullWidth"
                >
                    <Tab label="Submitted" />
                    <Tab label="Waiting for room in Pool" />
                    <Tab label="In the Pool" />
                    <Tab label="In a contest" />
                </Tabs>
            </AppBar>

            {renderTabContent()}
        </PageLayout>
    );
}

export default VideoManaging;
