import FormErrors from "Constants/FormErrors";

export const isInvalidField = (submitted, field) => submitted &&
    (field.length === 0 || field === 0 || JSON.stringify(field) === '{}');

export const isOutOfRange = (submitted, field, min, max) => {
    return submitted && !((field.length <= max) && (field.length >= min))
}

export const isInvalidRewardField = (submitted, field, min, max) => {
    const stringifyNumberField = typeof field === 'number' ? field.toString() : field;

    return isInvalidField(submitted, stringifyNumberField) || isOutOfRange(submitted, stringifyNumberField, min, max)
}

export const isInvalidRewardDescriptionField = (submitted, field, min, max) => {
    return isOutOfRange(submitted, field, min, max) && field.length !== 0
}

export const isInvalidFormatOrSizeOfRewardPhoto = (file) => {
    if(file){
        const fileSize = file.size / 1024 / 1024;
        const fileFormat = file.type;
        const allowedFormats = ['image/jpeg', 'image/png'];

        return !allowedFormats.includes(fileFormat) || fileSize > 10
    }
    else {
        return undefined
    }
}

export const isInvalidRewardPhotoField = (submitted, value, file) => {
    return isInvalidField(submitted, value) || isInvalidFormatOrSizeOfRewardPhoto(file)
}

export const getErrorMessageForRewardField = (value, name, submitted) => {
    const errors = FormErrors.rewards;

    return isInvalidField(submitted, value) ? errors.requiredFields[name] : errors.outOfRangeFields[name]
}

export const getErrorMessageForRewardDescriptionField = () => {
    const errors = FormErrors.rewards;

    return errors.outOfRangeFields.description
}

export const isInvalidUniqField = (submitted, field, values) =>
    values && Array.isArray(values) && submitted &&
    values.includes(field);

export const arePasswordsMatching = (submitted, password, confirmation) => {
    if (submitted) {
        if (password.length !== 0) {
            return password === confirmation;
        }

        return true;
    }

    return true;
};

export const isNewPasswordEqualsOld = (submitted, oldPassword, newPassword) => {
    if (submitted) {
        return newPassword.length !== 0 && oldPassword === newPassword;
    }

    return false;
};

export const addFieldToObjectOnlyIfItSatisfiesTheCondition = (condition, name, value) =>
    ({ ...((condition) && { [name]: value }) });

export const getQuillFormats = () => ([
    'header',
    'font',
    'bold', 'italic', 'underline', 'strike', 'blockquote',
    'list', 'bullet', 'indent',
    /*'link', 'image', 'video', 'audio', */ 'align',
    'code-block'
]);

export const getQuillModules = () => ({
    toolbar: [
        [{
            header: [1, 2, 3, 4, 5, 6, false]
        }],
        [{
            font: []
        }],
        ['bold', 'italic', 'underline', 'strike', 'blockquote'],
        [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' }
        ],
        ['clean'],
        [{
            align: []
        }],
        ['code-block']
    ]
});
