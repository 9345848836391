import Contest from 'Routes/contest';
import Profile from 'Routes/profile';
import Terms from 'Routes/terms';
import Users from 'Routes/users';
import Verify from 'Routes/verify';
import VideoManaging from 'Routes/videoManaging';
import Watchers from 'Routes/watchers';
import RewardPolicy from "Routes/rewardPolicy";
import {PushNotifications} from "Routes/pushNotifications";
import {Complaints} from "Routes/complaints";

export default [
    {
        path: 'contest',
        component: Contest
    },
    {
        path: 'profile',
        component: Profile
    },
    {
        path: 'terms',
        component: Terms
    },
    {
        path: 'users',
        component: Users
    },
    {
        path: 'verify',
        component: Verify
    },
    {
        path: 'video',
        component: VideoManaging
    },
    {
        path: 'watchers',
        component: Watchers
    },
    {
        path: 'rewards',
        component: RewardPolicy
    },
    {
        path: 'pushNotifications',
        component: PushNotifications
    },
    {
        path: 'complaints',
        component: Complaints
    }
]
