import React from 'react';
import { Col, Input, Row } from 'reactstrap';
import moment from 'moment';

const PastContent = ({ contest }) => (
    <div className="past-content-info">
        <Row className="h-100 w-100 py-3 align-items-center">
            <Col lg={2} className="d-flex flex-column align-items-center justify-content-center">
                <h3 className="m-0 text-center">
                    {contest.date.length > 10
                        ? moment(contest.date, 'MM/DD/YYYY HH:mm:ss').format('YYYY-MM-DD')
                        : contest.date
                    }
                </h3>
            </Col>

            <Col lg={10} className="past-content-info-main">
                <Row className="past-content-info-row border-bottom">
                    <Col
                        className="d-flex align-items-center justify-content-center justify-content-lg-start mb-2 mb-lg-0"
                        lg={2}
                    >
                        <h4 className="m-0">{contest.video_1.player.name}</h4>
                    </Col>

                    <Col
                        className="d-flex align-items-center justify-content-center justify-content-lg-start mb-2 mb-lg-0"
                        lg={1}
                    >
                        <h4 className="m-0">ID: {contest.video_1.id}</h4>
                    </Col>

                    <Col
                        className="d-flex align-items-center justify-content-center justify-content-lg-start mb-2 mb-lg-0"
                        lg={3}
                    >
                        <h4 className="m-0">{contest.video_1.title}</h4>
                    </Col>

                    <Col
                        className="d-flex align-items-center justify-content-center justify-content-lg-start mb-2 mb-lg-0"
                        lg={3}
                    >
                        <h4 className="m-0">{contest.video_1.description}</h4>
                    </Col>

                    <Col
                        className="d-flex align-items-center justify-content-center justify-content-lg-start"
                        lg={3}
                    >
                        <Input className="text-center" disabled value={contest.video_1.votes || 0} />
                    </Col>
                </Row>

                <Row className="past-content-info-row border-bottom">
                    <Col
                        className="d-flex align-items-center justify-content-center justify-content-lg-start mb-2 mb-lg-0"
                        lg={2}
                    >
                        <h4 className="m-0">{contest.video_2.player.name}</h4>
                    </Col>

                    <Col
                        className="d-flex align-items-center justify-content-center justify-content-lg-start mb-2 mb-lg-0"
                        lg={1}
                    >
                        <h4 className="m-0">ID: {contest.video_2.id}</h4>
                    </Col>

                    <Col
                        className="d-flex align-items-center justify-content-center justify-content-lg-start mb-2 mb-lg-0"
                        lg={3}
                    >
                        <h4 className="m-0">{contest.video_2.title}</h4>
                    </Col>

                    <Col
                        className="d-flex align-items-center justify-content-center justify-content-lg-start mb-2 mb-lg-0"
                        lg={3}
                    >
                        <h4 className="m-0">{contest.video_2.description}</h4>
                    </Col>

                    <Col
                        className="d-flex align-items-center justify-content-center justify-content-lg-start"
                        lg={3}
                    >
                        <Input className="text-center" disabled value={contest.video_2.votes || 0} />
                    </Col>
                </Row>

                <Row className="past-content-info-row">
                    <Col
                        className="d-flex align-items-center justify-content-center justify-content-lg-start mb-2 mb-lg-0"
                        lg={9}
                    >
                        <h4 className="m-0">PUSH</h4>
                    </Col>

                    <Col
                        className="d-flex align-items-center justify-content-center justify-content-lg-start"
                        lg={3}
                    >
                        <Input className="text-center" disabled value={contest.push || 0} />
                    </Col>
                </Row>
            </Col>
        </Row>
    </div>
);

export default PastContent;
