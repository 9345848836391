import { combineReducers } from 'redux';

import contestsReducer from './ContestsReducer';
import landingReducer from './LandingReducer';
import settingsReducer from './SettingsReducer';
import userReducer from './UserReducer';
import usersReducer from './UsersReducer';
import videoReducer from './VideoReducer';
import watchersReducer from './WatchersReducer';
import rewardsReducer from './RewardsReducer';

const reducers = combineReducers({
    rewards: rewardsReducer,
   authUser: userReducer,
   contests: contestsReducer,
   landing: landingReducer,
   settings: settingsReducer,
   users: usersReducer,
   video: videoReducer,
   watchers: watchersReducer,
});

export default reducers;
