import React from 'react';
import { Row } from 'reactstrap';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import {Close} from "@material-ui/icons";
import IconButton from "@material-ui/core/IconButton";
import MUIDataTable from "mui-datatables";
import moment from "moment";
import {getTableDataFromComplaints} from "Components/ManageComplaintDialog/helpers";
import {ReportReasons} from "Constants/Complaints";

export const ManageComplaintDialog = ({ onDeleteVideo, onRemoveComplaints, isActive, setIsActive, videoTitle, videoComplaints }) =>  {
    const onCloseDialog = () => {
        setIsActive(false);
    }

    const onRenderTableTextColumns = (text) => {
        return (
            <span className={'rewards-cell-text'}>
                    {text}
            </span>
        );
    }

    const onRenderTableReasonColumns = (text) => {
        return (
            <span className={'rewards-cell-text'}>
                {ReportReasons[text]}
            </span>
        );
    }

    const columnsOptions = {
        filter: false,
        sort: false,
    }

    const options = {
        download: false,
        filter: false,
        print: false,
        search: false,
        selectableRowsHideCheckboxes: true,
        serverSide: true,
        viewColumns: false,
        pagination: false,
    }

    const columns = [
        {
            name: "who_complained",
            label: "Who complained",
            options: {...columnsOptions, customBodyRender: onRenderTableTextColumns}
        },
        {
            name: "user_email",
            label: "User email",
            options: {...columnsOptions, customBodyRender: onRenderTableTextColumns}
        },
        {
            name: "date",
            label: "Date",
            options: {...columnsOptions, customBodyRender: onRenderTableTextColumns}
        },
        {
            name: "complaint",
            label: "Complaint",
            options: {...columnsOptions, customBodyRender: onRenderTableReasonColumns}
        },
    ];

    const tableData = getTableDataFromComplaints(videoComplaints)

    return (
        <Dialog
            fullWidth
            open={isActive}
            onClose={onCloseDialog}
            aria-labelledby="form-dialog-title"
            maxWidth={'md'}
            scroll={'body'}
        >
            <DialogTitle id="form-dialog-title">
                <div className={'justify-content-between d-flex'}>
                    {`Complaints to the "${videoTitle}" video`}
                    <IconButton
                        className="ml-2"
                        onClick={onCloseDialog}
                        disableRipple
                    >
                        <Close />
                    </IconButton>
                </div>
            </DialogTitle>

            <DialogContent>
                <MUIDataTable data={tableData} columns={columns} options={options} />
            </DialogContent>

            <DialogActions>
                <Row className="m-3">
                    <Button
                        color="primary"
                        variant="text"
                        className="mr-3"
                        onClick={onDeleteVideo}
                    >
                        Delete video
                    </Button>

                    <Button
                        color="default"
                        variant="text"
                        onClick={onRemoveComplaints}
                    >
                        Remove complaints
                    </Button>
                </Row>
            </DialogActions>
        </Dialog>
    );
}
