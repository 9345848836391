import {Accordion, AccordionActions, AccordionDetails, AccordionSummary} from "@material-ui/core";
import React, {useState} from "react";
import ExpandMoreRounded from '@material-ui/icons/ExpandMoreRounded';
import {FormInputWithLabel} from "Components/FormInputWithLabel";
import Button from "@material-ui/core/Button";

export const PushItem = (props) => {
    const {title, setTitle, description, setDescription, onSubmit } = props

    const [showAccordion, setShowAccordion] = useState(false)

    return   <Accordion
        onChange={() => {
            setShowAccordion(!showAccordion)
        }}
        expanded={showAccordion}
        className="accordion-wrapper"
    >
        <AccordionSummary
            aria-controls="panel1-content"
            id="panel1-header"
            expandIcon={<ExpandMoreRounded
                color="inherit"
                className="icon-expand"
            />}
        >
            {
                title
            }
        </AccordionSummary>
        <AccordionDetails className={'d-flex flex-column'}>
            <FormInputWithLabel
                label={'Title'}
                name={"pushName"}
                onChange={(e) => {
                    setTitle(e.target.value)
                }}
                placeholder={'Title'}
                showCounter={false}
                type={'text'}
                value={title}
            />

            <FormInputWithLabel
                label={'Description'}
                name={"pushText"}
                onChange={(e) => {
                    setDescription(e.target.value)
                }}
                placeholder={'Description'}
                showCounter={false}
                type={'textarea'}
                className={"min-h-100"}
                value={description}
            />
        </AccordionDetails>
        <AccordionActions className={'mh-2 mb-2'}>
            <Button onClick={() => {
                setShowAccordion(false)
            }}>Cancel</Button>
            <Button color={'primary'} onClick={() => {
                onSubmit();

                setShowAccordion(false);
            }}>Save</Button>
        </AccordionActions>
    </Accordion>
}
