export default {
    confirmation: {
        newPassword: {
            noMatch: "New password and confirmation don't match"
        },
        password: {
            noMatch: "The password and confirmation don't match"
        }
    },
    email: {
        blank: 'Please enter your email in the email field'
    },
    jackpot_step: {
        blank: 'Please enter jackpot step',
        notANumber: 'Should be a number'
    },
    name: {
        blank: 'Please enter your name in the name field'
    },
    newPassword: {
        blank: 'Please enter new password in the password field'
    },
    password: {
        blank: 'Please enter your password in the password field'
    },
    reasonToDisapprove: {
        blank: "Please enter reason to disapprove"
    },
    rewards: {
        requiredFields: {
            name: "Please enter name of reward",
            amount_of_users: "Please enter amount of users",
            description: "Please enter description of reward",
            photo: "Please add photo",
        },
        outOfRangeFields: {
            name: "Invalid number of signs (min: 1, max: 50)",
            amount_of_users: "Invalid number of digits (min: 1, max: 4)",
            description: "Invalid number of signs (min: 1, max: 300)",
            photo: "Invalid format or size of photo",
        }
    }
};

