export const Base64ToBlob = (base64File) => {
    if(base64File !== ''){
        const mimeType = base64File.match(/^data:(.*);base64,/)[1];

        const base64Data = base64File.split(',')[1];

        const binaryString = atob(base64Data);
        const length = binaryString.length;
        const binaryData = new Uint8Array(length);

        for (let i = 0; i < length; i++) {
            binaryData[i] = binaryString.charCodeAt(i);
        }

        return new Blob([binaryData], {type: mimeType});
    } else {
        return '';

    }
}
