import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT,
    PASSWORD_CHANGE_NECESSITY,
    USER_INFO,
    USER_INFO_SUCCESS,
    USER_INFO_FAILURE
} from 'Actions/types';

import { getUserTokenFromStorage } from 'Utils/LocalStorageUtils';

const INIT_STATE = {
    change_password: false,
    data: null,
    loading: false,
    error: null,
    user: getUserTokenFromStorage(),
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case LOGIN:
            return {
                ...state,
                loading: true
            };

        case LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                user: action.payload.token
            };

        case LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case LOGOUT:
            return {
                ...state,
                data: null,
                user: null
            };

        case PASSWORD_CHANGE_NECESSITY:
            return {
                ...state,
                change_password: action.payload
            };

        case USER_INFO:
            return { ...state, loading: true };

        case USER_INFO_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data
            };

        case USER_INFO_FAILURE:
            return {
                ...state,
                loading: false
            };

        default: return state;
    }
}
