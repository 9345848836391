import { NotificationManager } from 'react-notifications';

export const clearAllMessages = (type = 'all') => {
    const notifications = NotificationManager.listNotify;

    if (type === 'all') {
        notifications.forEach(n => NotificationManager.remove({ id: n.id }));
    } else {
        notifications
            .filter(n => n.type === type)
            .forEach(n => NotificationManager.remove({ id: n.id }));
    }
};
