import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Input, Row } from 'reactstrap';
import MUIDataTable from 'mui-datatables';

import {
    changeWatchersListPage,
    changeWatchersListSizePerPage,
    deleteWatcher,
    getWatchersList
} from 'Actions';

import { TableLoader } from 'Components/Loader';
import { TransparentTable } from 'Components/Table';

import { getWatchers, getWatchersDataLoading, getWatcherListPagination } from 'Selectors';

const WatchersList = ({ createWatcherButton }) => {
    const dispatch = useDispatch();

    const loading = useSelector(getWatchersDataLoading);
    const watchers = useSelector(getWatchers);

    const [search, setSearch] = useState('');

    useEffect(() => {
        dispatch(getWatchersList({
            roles,
            page: 0,
            sizePerPage: 10,
            search
        }));
    }, [search]);

    const pagination = useSelector(getWatcherListPagination);

    const [page, setPage] = useState(pagination.page);
    const [sizePerPage, setSizePerPage] = useState(pagination.sizePerPage);

    const roles = ['admin'];

    const onDeleteWatcher = selectedWatcherId => {
        dispatch(
            deleteWatcher(
                selectedWatcherId,
                () => dispatch(getWatchersList({ roles, page, sizePerPage, search }))
            )
        );
    };

    const onTableChange = (action, tableState) => {
        if (action === 'changePage') {
            const { page, rowsPerPage } = tableState;

            setPage(page);

            dispatch(changeWatchersListPage(page));

            dispatch(getWatchersList({
                roles,
                page,
                sizePerPage: rowsPerPage,
                search
            }));
        }

        if (action === 'changeRowsPerPage') {
            const { rowsPerPage } = tableState;

            setSizePerPage(rowsPerPage);

            dispatch(changeWatchersListPage(0));
            dispatch(changeWatchersListSizePerPage(rowsPerPage));

            dispatch(getWatchersList({
                roles,
                page: 0,
                sizePerPage: rowsPerPage,
                search
            }));
        }
    };

    const onRenderTableActions = selectedWatcherId => (
        <Button
            color="danger"
            onClick={() => onDeleteWatcher(selectedWatcherId)}
        >
            <span className="font-weight-light">
                Delete
            </span>
        </Button>
    )

    const onSearchUsers = e => {
        setSearch(() => e.target.value);
    }

    const columns = [
        {
            name: 'id',
            label: 'ID',
            options: {
                sort: false
            }
        },
        {
            name: 'email',
            label: 'Email',
            options: {
                sort: false
            }
        },
        {
            name: 'name',
            label: 'Watcher Name',
            options: {
                sort: false
            }
        },
        {
            name: 'id',
            label: 'Managing',
            options: {
                customBodyRender: onRenderTableActions,
                sort: false
            }
        }
    ];

    const options = {
        download: false,
        count: pagination.total,
        filter: false,
        onTableChange: onTableChange,
        page: pagination.page,
        print: false,
        rowsPerPage: pagination.sizePerPage,
        search: false,
        selectableRowsHideCheckboxes: true,
        serverSide: true,
        viewColumns: false
    }

    return (
        <div className="pt-10 px-30 pb-30">
            <Row className="py-3">
                <Col md={4}>
                    <Input
                        className="mb-3"
                        placeholder="Filter by email"
                        type="search"
                        onChange={onSearchUsers}
                        value={search}
                    />
                </Col>

                <Col>
                    {createWatcherButton}
                </Col>
            </Row>

            {loading && <TableLoader />}

            <TransparentTable transparent={loading}>
                <MUIDataTable
                    data={watchers}
                    columns={columns}
                    options={options}
                />
            </TransparentTable>
        </div>
    )
}

export default WatchersList;
