import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, Row } from 'reactstrap';
import ReactQuill from 'react-quill';

import { getSettings, updateSettings } from 'Actions';

import { getRulesAndRegulations, getUserRole } from 'Selectors';

import { getQuillFormats, getQuillModules } from 'Utils/FormUtils';

const TermsPage = () => {
    const dispatch = useDispatch();

    const defaultRules = useSelector(getRulesAndRegulations);
    const userRole = useSelector(getUserRole);

    const disableTermsUpdate = userRole !== 'super-admin';

    const [termsTextArea, setTermsTextArea] = useState('');

    useEffect(() => {
        dispatch(getSettings());
    }, []);

    useEffect(() => {
        if (defaultRules && defaultRules.value) {
            setTermsTextArea(() => defaultRules.value);
        }
    }, [defaultRules]);

    const onTermsChange = value => {
        setTermsTextArea(() => value);
    };

    const onUpdateTermsSuccessCallback = () => {
        dispatch(getSettings());
    };

    const onUpdateTerms = () => {
        dispatch(
            updateSettings(
                { rules: termsTextArea },
                () => onUpdateTermsSuccessCallback()
            )
        );
    };

    const modules = disableTermsUpdate ? ({ toolbar: false }) : getQuillModules();

    const placeholder = disableTermsUpdate ? '' : 'Type text...';

    return (
        <div className="pt-10 px-30 pb-30">
            <Row className={disableTermsUpdate ? '' : 'py-3'}>
                <Col md={12}>
                    <ReactQuill
                        className="quill-custom"
                        readOnly={disableTermsUpdate}
                        formats={getQuillFormats()}
                        modules={modules}
                        placeholder={placeholder}
                        name="terms"
                        onChange={onTermsChange}
                        value={termsTextArea}
                    />
                </Col>
            </Row>

            {!disableTermsUpdate && (
                <Row className="py-3 justify-content-end px-3">
                    <Button color="success" onClick={onUpdateTerms}>

                        Save Changes

                    </Button>
                </Row>
            )}
        </div>
    )
}

export default TermsPage;
