import React from 'react';
import { Redirect, Route } from 'react-router-dom';

const ProtectedRoute = ({ component: Component, token, verified, ...rest }) => (
    <Route
        {...rest}
        render={props => token ? <Component {...props} /> : <Redirect to="/admin/signin" />}
    />
);

export default ProtectedRoute;
