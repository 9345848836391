import React from 'react';
import { Redirect } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Typography from '@material-ui/core/Typography';

import ConfirmUserEmailForm from './component/ConfirmUserEmail';

import { RctCard } from 'Components/RctCard';

import { isVerifiedFieldExist, isUserVerified } from 'Utils/LocalStorageUtils';

const VerifyPage = () => {
    if (!isVerifiedFieldExist()) {
        return null;
    }

    if (isUserVerified()) {
        return <Redirect to='/admin/video' />;
    }

    return (
        <div>
            <Helmet>
                <title>Confirm your email</title>
                <meta name="confirm_email" content="Confirm your email" />
            </Helmet>

            <RctCard>
                <div>
                    <Typography component="div" style={{ padding: 8 * 3 }}>
                        <ConfirmUserEmailForm />
                    </Typography>
                </div>
            </RctCard>
        </div>
    );
}

export default VerifyPage;
