import React from 'react';
import { Row } from 'reactstrap';
import CircularProgress from '@material-ui/core/CircularProgress';

const TableLoader = () => (
    <Row className="table-loader-row">
        <CircularProgress color="secondary" />
    </Row>
);

export default TableLoader;
