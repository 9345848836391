import appStoreBadgeSVG from 'Assets/img/app-store-badge.svg';
import googlePlayBadgeSVG from 'Assets/img/google-play-badge.svg';
import React from 'react';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';

const GetApp = () => {
  return (
    <div className="get-app-bg w-100" id="download">
      <Container className="main-container">
        <Row>
          <Col className="d-flex flex-column align-items-center">
            <p className="text-center get-app-title mb-4">
              Get the app now!
            </p>
            <Row>
              <Col>
                <a href="/#" className="app-badge">
                  <img
                    src={googlePlayBadgeSVG}
                    alt="Google Play App"
                  />
                </a>
              </Col>
              <Col>
                <a href="/#" className="app-badge">
                  <img
                    src={appStoreBadgeSVG}
                    alt="App Store App"
                  />
                </a>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default GetApp;
