import React from 'react';
import axios from 'axios';
import { NotificationManager } from 'react-notifications';

import { logoutAdmin, passwordChangeNecessitySet } from 'Actions';

import { Statuses } from 'Constants/Statuses.js';

import { isUserShouldChangePassword } from 'Selectors';

import errorMessageHandler from 'Utils/ErrorMessageHandler';
import { getUserTokenFromStorage, setNotVerified } from 'Utils/LocalStorageUtils';

const port = process.env.MIX_APP_PORT ? `:${process.env.MIX_APP_PORT}` : '';
const url = `${process.env.MIX_APP_URL}${port}/`;

axios.defaults.baseURL = url;

export const api = axios.create({
    baseURL: url + 'api/',
    timeout: 20000,
    withCredentials: true,
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
    }
});

api.interceptors.request.use(
    config => {
        const token = getUserTokenFromStorage();

        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }

        return config;
    },
    error => Promise.reject(error)
);

export const successMessageHandler = response => {
    let successResult = false;

    if (response.data) {
        if (response.data.message) {
            NotificationManager.success(response.data.message);

            successResult = true;
        }
    }
    return successResult;
};

export const errorHandler = (error, event, showMessage = true) => (dispatch, getState) => {
    const shouldUserHaveChangePassword = isUserShouldChangePassword(getState());

    const changePasswordRequest = {
        method: 'post',
        url: 'admin/users'
    };

    if (error.response) {
        dispatch({ type: event, payload: Statuses[error.response.status] });

        if (
            error.response.data &&
            error.response.data.message &&
            showMessage &&
            (!shouldUserHaveChangePassword || (
                shouldUserHaveChangePassword &&
                    error.response.config.url === changePasswordRequest.url &&
                    error.response.config.method === changePasswordRequest.method
            ))
        ) {
            const newErrorMessage = errorMessageHandler(error.response.data);

            const displayedNotifications = NotificationManager.listNotify.length === 0
                ? []
                : NotificationManager.listNotify.map(notify => notify && notify.message);

            if (!displayedNotifications.includes(newErrorMessage)) {
                NotificationManager.error(newErrorMessage);
            }
        }

        if (error.response.status === 401) {
            dispatch(logoutAdmin(false));
        }

        if (error.response.status === 412) {
            if (!shouldUserHaveChangePassword) {
                dispatch(passwordChangeNecessitySet(true));
            }
        }

        if (error.response.status === 420) {
            setNotVerified();

            const { location } = window;

            if (location.pathname !== '/admin/verify') {
                location.href = '/admin/verify';
            }
        }
    } else if (error.request) {
        console.log(error.request);
    } else {
        console.log('Error ', error.message);
        dispatch({ type: event, payload: error.message });
    }
};
