import _ from 'lodash';

import noImageIcon from 'Assets/img/logo.svg';

// Auth user

export const getAuthUser = state => _.get(state, 'authUser', {});

export const getUserToken = state => _.get(getAuthUser(state), 'user', null);

export const getUserInfo = state => _.get(getAuthUser(state), 'data', null);

export const getUserDataLoading = state => _.get(getAuthUser(state), 'loading', []);

export const getUserRole = state => _.get(getUserInfo(state), 'role', 'user');

export const getUserId = state => _.get(getUserInfo(state), 'id', null);

export const isSuperAdminCheck = state => getUserRole(state) === 'super-admin';

export const isUserShouldChangePassword = state => _.get(getAuthUser(state), 'change_password', false);

// Contests

export const getContestsData = state => _.get(state, 'contests', {});

export const getContests = state => _.get(getContestsData(state), 'data', []);

export const getContestsDataLoading = state => _.get(getContestsData(state), 'loading', []);

export const getContestsMeta = state => _.get(getContestsData(state), 'meta', null);

export const getContestsCurrentPage = state => _.get(getContestsMeta(state), 'current_page', 0);

export const getContestsPerPage = state => _.get(getContestsMeta(state), 'per_page', 10);

export const getContestsLastPage = state => _.get(getContestsMeta(state), 'last_page', 0);

export const getCurrentContestData = state => {
    const currentContestData = _.get(getContestsData(state), 'current', null);

    return _.get(currentContestData, 'data', null);
};

export const getCurrentContestLoading = state => _.get(getCurrentContestData(state), 'loading', false);

// Files

export const getImageFromStorage = url => {
    if (window.location.host === 'localhost') {
        const domain = process.env.MIX_APP_URL === 'http://localhost'
            ? process.env.MIX_DEV_SERVER
            : process.env.MIX_APP_URL;

        return url ? `${domain}/${url.split(`${process.env.MIX_APP_URL}/`)[1]}` : noImageIcon;
    } else {
        return url ? url : noImageIcon;
    }
};

export const getFileFromStorage = url => {
    if (window.location.host === 'localhost') {
        const domain = process.env.MIX_APP_URL === 'http://localhost'
            ? process.env.MIX_DEV_SERVER
            : process.env.MIX_APP_URL;

        return url ? `${domain}/${url.split(`${process.env.MIX_APP_URL}/`)[1]}` : null;
    } else {
        return url ? url : null;
    }
};

// Landing

export const getLandingData = state => _.get(state, 'landing', {});

export const getLandingContest = state => {
    const contest = _.get(getLandingData(state), 'contest', null);

    if (contest) {
       return _.first(contest);
    }

    return contest;
};

export const getLandingTerms = state => _.get(getLandingData(state), 'terms', '');

export const getLandingDataLoading = state => _.get(getLandingData(state), 'loading', false);

// Loading

export const getLoading = state => getUserDataLoading(state) || getWatchersDataLoading(state) ||
    getUsersDataLoading(state) || getVideoDataLoading(state) || getContestsDataLoading(state) ||
    getSettingsLoading(state) || getLandingDataLoading(state) || getCurrentContestLoading(state);

// Settings

export const getSettingsData = state => _.get(state, 'settings', {});

export const getSettings = state => _.get(getSettingsData(state), 'data', []);

export const getSettingsLoading = state => _.get(getSettingsData(state), 'loading', []);

export const getRulesAndRegulations = state => {
    const settings = getSettings(state);

    if (settings.length === 0) {
        return null;
    }

    return settings.find(({ key }) => key === 'rules');
}

export const getJackpotStep = state => {
    const settings = getSettings(state);

    if (settings.length === 0) {
        return [];
    }

    return settings.find(({ key }) => key === 'jackpot_step');
}

// Users

export const getUsersData = state => _.get(state, 'users', {});

export const getUsers = state => _.get(getUsersData(state), 'data', []);

export const getUsersDataLoading = state => _.get(getUsersData(state), 'loading', []);

export const getUserListPagination = state => _.get(
    getUsersData(state),
    'pagination',
    { page: 0, sizePerPage: 10, total: 0 }
);

// Video

export const getVideoData = state => _.get(state, 'video', {});

export const getVideos = state => _.get(getVideoData(state), 'data', []);

export const getVideoDataLoading = state => _.get(getVideoData(state), 'loading', false);

export const getVideoMeta = state => _.get(getVideoData(state), 'meta', null);

export const getVideoCurrentPage = state => _.get(getVideoMeta(state), 'current_page', 0);

export const getVideoPerPage = state => _.get(getVideoMeta(state), 'per_page', 10);

export const getVideoLastPage = state => _.get(getVideoMeta(state), 'last_page', 0);

export const getVideosWithComplaintsSelector = state => _.get(getVideoData(state), 'videosWithComplaintsData', []);
export const getVideosWithComplaintsLoadingSelector = state => _.get(getVideoData(state), 'videosWithComplaintsLoading', []);

// Watchers

export const getWatchersData = state => _.get(state, 'watchers', {});

export const getWatchers = state => _.get(getWatchersData(state), 'data', []);

export const getWatchersDataLoading = state => _.get(getWatchersData(state), 'loading', []);

export const getWatcherListPagination = state => _.get(
    getWatchersData(state),
    'pagination',
    { page: 0, sizePerPage: 10, total: 0 }
);

export const getRewards = state => _.get(state, 'rewards', {});

export const getRewardsEntities = state => _.get(getRewards(state), 'entities', state.entities);

export const getRewardsTableData = state => _.get(getRewards(state), 'tableData', state.tableData);

export const getRewardsIds = state => _.get(getRewards(state), 'ids', state.ids);

export const getIsInvalidForm = state => _.get(getRewards(state), 'isInvalidForm', state.isInvalidForm);

export const getIsRewardsLoading = state => _.get(getRewards(state), 'isLoading', state.isLoading);

export const getPushNotificationsSelector = state => _.get(getSettingsData(state), 'pushNotifications', state.pushNotifications);
