import siteLogo from 'Assets/img/logo.svg';

const AppConfig = {
   appLogo: siteLogo,                                       // App Logo
   brandName: 'Paymerobot',                                 // Brand Name
   welcomeText: 'Welcome to Paymerobot!',                   // Welcome Text
   getStarted: "Here's what you need to get started",       // Get Started
   boxLayout: false,                                        // Box Layout
   rtlLayout: false,                                        // RTL Layout
   copyRightText: `Paymerobot © ${new Date().getFullYear()} All Rights Reserved.`, // Copy Right Text
   // light theme colors
   themeColors: {
      'primary': '#6d81c0',
      'secondary': '#677080',
      'success': '#E3562A',
      'danger': '#FF3739',
      'warning': '#FFB70F',
      'info': '#00D0BD',
      'dark': '#464D69',
      'default': 'transparent',
      'greyLighten': '#EFEFEF',
      'grey': '#677080',
      'muted': '#6c757d',
      'white': '#FFFFFF',
      'purple': '#896BD6',
      'transparent': 'transparent',
      'yellow': '#D46B08',
      'blue': '#169BD5',
      'black': '#000'
   },
   bgColors: {
      'primary': '#E3562A',
      'secondary': '#677080',
      'success': '#E3562A',
      'danger': '#FF3739',
      'warning': '#FFB70F',
      'info': '#00D0BD',
      'dark': '#464D69',
      'default': 'transparent',
      'greyLighten': '#EFEFEF',
      'grey': '#677080',
      'white': '#FFFFFF',
      'purple': '#896BD6',
      'transparent': 'transparent',
      'yellow': '#D46B08',
      'blue': '#169BD5'
   }
}

export default AppConfig;
