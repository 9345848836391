import React from 'react';
import { FormFeedback } from 'reactstrap';

const PositionedFormFeedback = ({
    leftOffset = false,
    message,
    rightOffset
}) => (
    <div
        className={`form-feedback-container${leftOffset ? ' left-offset' : ''}${rightOffset ? ' right-offset' : ''}`}
    >
        <FormFeedback className="form-feedback">
            {message}
        </FormFeedback>
    </div>
)

export default PositionedFormFeedback;
