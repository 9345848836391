import { IconButton } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar';
import MenuIcon from '@material-ui/icons/Menu';

import { getVotingContestForLanding } from 'Actions';
import React, {
  useEffect,
  useState,
} from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import {
  Col,
  Row,
  Container,
  Collapse,
} from 'reactstrap';
import SignInToAdminPanelDialog from '../SignInToAdminPanelDialog';
import HeaderLogo from './HeaderLogo';

const Header = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [signInToAdminPanelDialogVisibility, setSignInToAdminPanelDialogVisibility] = useState(
    false);

  const [navBarIsOpen, setNavBarIsOpen] = useState(false);

  const onToggleSignInToAdminPanelDialog = () => {
    setSignInToAdminPanelDialogVisibility(state => !state);
  };

  const loginButton = (
    <Button
      className="button-secondary"
      variant="outlined"
      color="secondary"
      size="small"
      onClick={onToggleSignInToAdminPanelDialog}
      disableRipple
    >
      Login Admin/Watcher
    </Button>
  );

  useEffect(() => {
    dispatch(getVotingContestForLanding());
  }, []);

  return (
    <AppBar
      position="sticky"
      className="rct-header landing-header"
    >
      <Toolbar className="d-flex align-items-center justify-content-between w-100">
        <Container className="main-container">
          <Row className="py-4">
            <Col>
              <Row>
                <HeaderLogo />
              </Row>
            </Col>
            <Col
              className="d-flex align-items-center justify-content-end"
            >
              {history.location.pathname === '/terms' && (
                <div className="header-home-link mr-3">
                  <HashLink to="/#page-top">
                    Home
                  </HashLink>
                </div>
              )}
              <SignInToAdminPanelDialog
                show={signInToAdminPanelDialogVisibility}
                toggle={onToggleSignInToAdminPanelDialog}
              />
              <div className="d-none d-lg-block">
                {loginButton}
              </div>
              <IconButton
                className="d-flex d-lg-none"
                edge="end"
                color="inherit"
                aria-label="menu"
                onClick={() => setNavBarIsOpen((prev) => !prev)}
              >
                Menu
                <MenuIcon className="ml-2" />
              </IconButton>
            </Col>
          </Row>
          <Row>
            <Collapse
              isOpen={navBarIsOpen}
            >
              <div className="pb-4 pt-2">
                {loginButton}
              </div>
            </Collapse>
          </Row>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
