import React from 'react';
import { Card, CardBody} from 'reactstrap';
import Typography from '@material-ui/core/Typography';

import UsersList from './component/UsersList';
import {PageLayout} from "Components/PageLayout";

const Users = ({ match, role }) => (
    <PageLayout
        match={match}
        role={role}
        pageTitle={'Users list'}
        metaDataName={'users'}
        metaDataContent={'Users list'}
    >
        <Typography component="div" style={{ padding: 8 * 3 }}>
            <h1 className="text-center font-2x">{'Users list'}</h1>
        </Typography>

        <Card className="border-0">
            <CardBody>
                <UsersList />
            </CardBody>
        </Card>
    </PageLayout>
);

export default Users;
