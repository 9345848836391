import React from 'react';

import AppConfig from 'Constants/AppConfig';

const Footer = () => (
	<div className="rct-footer d-flex justify-content-between align-items-center">
		<h5 className="mb-0 w-100">{AppConfig.copyRightText}</h5>
	</div>
);

export default Footer;
