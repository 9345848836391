import {
    ADD_PHOTO_TO_REWARD,
    ADD_PHOTO_TO_REWARD_FAILURE,
    ADD_PHOTO_TO_REWARD_SUCCESS,
    ADD_REWARD,
    ADD_REWARD_FAILURE,
    ADD_REWARD_SUCCESS, ADD_TO_NEW_REWARDS,
    DELETE_PHOTO_OF_REWARD,
    DELETE_PHOTO_OF_REWARD_FAILURE,
    DELETE_PHOTO_OF_REWARD_SUCCESS, DELETE_REWARD, DELETE_REWARD_FAILURE, DELETE_REWARD_SUCCESS,
    EDIT_REWARD, EDIT_REWARD_FAILURE,
    EDIT_REWARD_SUCCESS,
    GET_REWARD_BY_ID,
    GET_REWARD_BY_ID_FAILURE,
    GET_REWARD_BY_ID_SUCCESS,
    GET_REWARDS,
    GET_REWARDS_FAILURE,
    GET_REWARDS_SUCCESS, REMOVE_FROM_NEW_REWARDS, SET_IS_INVALID__REWARD_FORM,
} from "Actions/types";
import {normalizeData} from "Utils/NormalizationData";

const INITIAL_STATE = {
    isLoading: false,
    entities: {},
    ids: [],
    tableData: [],
    isInvalidForm: false,
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case GET_REWARDS:
            return {
                ...state,
                isLoading: true,
            }
        case GET_REWARDS_SUCCESS:
            return {
                ...state,
                isLoading: false,
                tableData: action.payload,
                ...normalizeData(action.payload)
            }
        case GET_REWARDS_FAILURE:
            return {
                ...state,
                isLoading: false,
            }

        case ADD_REWARD:
            return {
                ...state,
                isLoading: true,
            }
        case ADD_REWARD_SUCCESS:
            return {
                ...state,
                isLoading: false,
            }
        case ADD_REWARD_FAILURE:
            return {
                ...state,
                isLoading: false,
            }

        case EDIT_REWARD:
            return {
                ...state,
                isLoading: true,
            }
        case EDIT_REWARD_SUCCESS:
            return {
                ...state,
                isLoading: false,
            }
        case EDIT_REWARD_FAILURE:
            return {
                ...state,
                isLoading: false,
            }

        case DELETE_REWARD:
            return {
                ...state,
                isLoading: true,
            }
        case DELETE_REWARD_SUCCESS:
            return {
                ...state,
                isLoading: false,
            }
        case DELETE_REWARD_FAILURE:
            return {
                ...state,
                isLoading: false,
            }

        case SET_IS_INVALID__REWARD_FORM:{
            return {
                ...state,
                isInvalidForm: action.payload,
            }
        }

        default:
            return state
    }
}
