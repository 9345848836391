import {
    GET_VIDEOS,
    GET_VIDEOS_SUCCESS,
    GET_VIDEOS_FAILURE,
    MODERATE_VIDEO,
    MODERATE_VIDEO_SUCCESS,
    MODERATE_VIDEO_FAILURE,
    GET_VIDEOS_WITH_COMPLAINTS,
    GET_VIDEOS_WITH_COMPLAINTS_SUCCESS,
    GET_VIDEOS_WITH_COMPLAINTS_FAILURE,
    REMOVE_COMPLAINTS_ON_VIDEO,
    REMOVE_COMPLAINTS_ON_VIDEO_SUCCESS,
    REMOVE_COMPLAINTS_ON_VIDEO_FAILURE,
    DELETE_VIDEO_WITH_COMPLAINTS,
    DELETE_VIDEO_WITH_COMPLAINTS_SUCCESS,
    DELETE_VIDEO_WITH_COMPLAINTS_FAILURE
} from 'Actions/types';

const INIT_STATE = {
    data: [],
    videosWithComplaintsData: [],
    videosWithComplaintsLoading: false,
    removingComplaintsOnVideo: false,
    deletingVideoWithComplaints: false,
    loading: false,
    error: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_VIDEOS:
            return {
                ...state,
                loading: true
            };

        case GET_VIDEOS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data,
                meta: action.payload.meta
            };

        case GET_VIDEOS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case GET_VIDEOS_WITH_COMPLAINTS:
        return {
            ...state,
            videosWithComplaintsLoading: true
        };

        case GET_VIDEOS_WITH_COMPLAINTS_SUCCESS:
            return {
                ...state,
                videosWithComplaintsLoading: false,
                videosWithComplaintsData: action.payload.data,
                meta: action.payload.meta
            };

        case GET_VIDEOS_WITH_COMPLAINTS_FAILURE:
            return {
                ...state,
                videosWithComplaintsLoading: false,
            };

        case REMOVE_COMPLAINTS_ON_VIDEO:
            return {
                ...state,
                removingComplaintsOnVideo: true
            };

        case REMOVE_COMPLAINTS_ON_VIDEO_SUCCESS:
            return {
                ...state,
                removingComplaintsOnVideo: false
            };

        case REMOVE_COMPLAINTS_ON_VIDEO_FAILURE:
            return {
                ...state,
                removingComplaintsOnVideo: false
            };

        case DELETE_VIDEO_WITH_COMPLAINTS:
            return {
                ...state,
                deletingVideoWithComplaints: true
            };

        case DELETE_VIDEO_WITH_COMPLAINTS_SUCCESS:
            return {
                ...state,
                deletingVideoWithComplaints: false
            };

        case DELETE_VIDEO_WITH_COMPLAINTS_FAILURE:
            return {
                ...state,
                deletingVideoWithComplaints: false
            };

        case MODERATE_VIDEO:
            return {
                ...state,
                loading: true
            };

        case MODERATE_VIDEO_SUCCESS:
            return {
                ...state,
                loading: false
            };

        case MODERATE_VIDEO_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        default: return state;
    }
}
