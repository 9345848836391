export const Statuses = {
    '204': 'User not found',
    '302': 'The record with the same data is already exist',
    '400': 'Request contains invalid data',
    '401': 'Unauthorized',
    '403': 'Forbidden, Unauthorized',
    '404': 'Not Found',
    '450': 'Bad request',
    '452': 'Your login or password is incorrect',
    '453': 'Number of attempts has expired',
    '454': 'Username not found',
    '455': 'Your profile is not active',
    '456': 'You try to login without Email verification. You need to go to the email and click confirmation link',
    '457': 'Google verification exception',
    '458': 'Can\'t send email',
    '459': 'Not all required fields are specified',
    '461': 'Password expired',
    '500': 'Server: internal error',
    '0': 'Request timeout'
};

