import {
    CHANGE_USERS_LIST_PAGE,
    CHANGE_USERS_LIST_SIZE_PER_PAGE,
    CHANGE_USER_ACCESS,
    CHANGE_USER_ACCESS_FAILURE,
    CHANGE_USER_ACCESS_SUCCESS,
    DELETE_USER,
    DELETE_USER_FAILURE,
    DELETE_USER_SUCCESS,
    GET_USERS,
    GET_USERS_FAILURE,
    GET_USERS_SUCCESS,
    UPDATE_USER_PROFILE,
    UPDATE_USER_PROFILE_FAILURE,
    UPDATE_USER_PROFILE_SUCCESS,
    SEND_REWARD,
    SEND_REWARD_SUCCESS,
    SEND_REWARD_FAILURE,
} from 'Actions/types';

const INIT_STATE = {
    data: [],
    loading: false,
    error: null,
    pagination: {
        page: 0,
        sizePerPage: 10,
        total: 0
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHANGE_USER_ACCESS:
            return {
                ...state,
                loading: true
            };

        case CHANGE_USER_ACCESS_SUCCESS:
            return {
                ...state,
                loading: false
            };

        case CHANGE_USER_ACCESS_FAILURE:
            return {
                ...state,
                loading: false
            };

        case CHANGE_USERS_LIST_PAGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    page: action.payload
                }
            };

        case CHANGE_USERS_LIST_SIZE_PER_PAGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    sizePerPage: action.payload
                }
            };

        case GET_USERS:
            return {
                ...state,
                loading: true
            };

        case GET_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data,
                pagination: {
                    ...state.pagination,
                    total: action.payload.meta.total
                }
            };

        case GET_USERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case UPDATE_USER_PROFILE:
            return {
                ...state,
                loading: true
            };

        case UPDATE_USER_PROFILE_SUCCESS:
            return {
                ...state,
                loading: false
            };

        case UPDATE_USER_PROFILE_FAILURE:
            return {
                ...state,
                loading: false
            };

        case DELETE_USER:
            return {
                ...state,
                loading: true
            }

        case DELETE_USER_SUCCESS:
        case DELETE_USER_FAILURE:
            return {
                ...state,
                loading: false
            }

        case SEND_REWARD:
            return {
                ...state,
                loading: true
            }
        case SEND_REWARD_SUCCESS:
        case SEND_REWARD_FAILURE:
            return {
                ...state,
                loading: false
            }

        default: return state;
    }
}
