import React from 'react';
import { HashLink } from 'react-router-hash-link';
import {
  Container,
  Row,
  Col,
} from 'reactstrap';

const ContactInfo = () => (
  <div className="contact-bg w-100" id="support">
    <Container className="main-container">
      <Row>
        <Col className="d-flex flex-column align-items-center">
          <p className="contact-text mb-2">© Paymerobot 2021. All Rights Reserved.</p>
          <div className="d-flex contact-links mb-2">
            <HashLink to="/terms#page-top">Terms & Conditions</HashLink>
            <p className="contact-text mx-2 mb-0">·</p>
            <HashLink to="/#faq">FAQ</HashLink>
          </div>
          <div className="d-flex contact-links">
            <a href="mailto:support@paymerobot.com">support@paymerobot.com</a>
          </div>
        </Col>
      </Row>
    </Container>
  </div>
);

export default ContactInfo;
