export default error => {
    const message = error.message || '';
    const errors = error.errors || null;

    let detailedErrors = '';

    if (errors) {
        detailedErrors = `${Object.values(error.errors)
            .map((err, idx) => typeof err === 'string'
                ? err
                : err
                    .map(errItem => typeof errItem === 'string'
                        ? errItem
                        : Object.values(errItem).join(' ')
                    ).join(' ')
            )
            .join('\n')}`;
    }

    return `${detailedErrors.length === 0 ? `${message}\n` : ''}${detailedErrors}`;
};
