import React from 'react';
import { useSelector } from 'react-redux';
import { Button, Row } from 'reactstrap';
import FormControl from '@material-ui/core/FormControl';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { Vote } from 'Components/Votes';

import { getFileFromStorage, getImageFromStorage, getUserId } from 'Selectors';

const VideoItem = ({
    onApprove,
    onToggleDisapproveDialog,
    onModerate,
    hasActions = true,
    hasRejectButton = false,
    hasDeleteButton = false,
    onReject,
    onDelete,
    video,
    votes = false
}) => {
    const userId = useSelector(getUserId);

    const moderating = video.status === 'moderating';
    const disableModerationForCurrentUser = moderating && userId !== video?.moderated_by;

    return (
        <div
            className={`video-item-container${hasActions ? ' actions' : ''}`}
        >
            {votes && <Vote video={video} />}

            <div className="video-block">
                {video.url && video.thumbnail && (
                    <video
                        controls
                        controlsList="nodownload noplaybackrate"
                        disablePictureInPicture
                        height="100%"
                        poster={getImageFromStorage(video.thumbnail)}
                    >
                        <source src={getFileFromStorage(video.url)} />
                    </video>
                )}
            </div>

            <div className="video-info">
                <div className="video-information-data">
                    {video.player && video.player.name && <h2>{video.player.name}</h2>}
                    {video.title && <h2>{video.title}</h2>}
                    {video.id && <h2>ID: {video.id}</h2>}
                    {video.description && <p>{video.description}</p>}
                    { (
                        <div className="video-actions">
                            {hasRejectButton && (<Button
                                size="sm"
                                color="danger"
                                onClick={() => onReject(video.id)}
                            >
                                Reject
                            </Button>)
                            }

                            {hasDeleteButton && (
                                <Button
                                    size="sm"
                                    color="danger"
                                    onClick={() => onDelete(video.id)}
                                >
                                    Delete
                                </Button>
                            )}
                        </div>
                    )}
                </div>

                {hasActions && (
                    <div className="video-actions-block">
                        <Row className="video-actions">
                            <Button
                                disabled={!moderating}
                                size="sm"
                                color="success"
                                onClick={() => onApprove(video.id)}
                            >
                                Approve
                            </Button>

                            <Button
                                disabled={!moderating}
                                size="sm"
                                color="danger"
                                onClick={() => onToggleDisapproveDialog(video.id)}
                            >
                                Disapprove
                            </Button>
                        </Row>

                        <Row
                            className={
                                `video-moderation-checkbox${disableModerationForCurrentUser ? ' blur' : ''}`
                            }
                        >
                            <FormControl>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            className="text-muted"
                                            color="primary"
                                            checked={moderating}
                                            onChange={() => onModerate(video.id, video.status)}
                                            value={moderating}
                                        />
                                    }
                                    label={<p className="w-100 mb-0">In moderation</p>}
                                />
                            </FormControl>
                        </Row>
                    </div>
                )}
            </div>
        </div>
    );
}

export default VideoItem;
