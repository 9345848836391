import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Row } from 'reactstrap';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { createNewWatcher, getWatchersList } from 'Actions';

import NewWatcherForm from './NewWatcherForm';

const NewWatcherDialog = ({ show, toggle }) =>  {
    const dispatch = useDispatch();

    const [email, setEmail] = useState('');
    const [name, setName] = useState('');

    const [submitted, setSubmitted] = useState(false);

    const onClearForm = () => {
        setEmail('');
        setName('');
    };

    const onCloseDialog = () => {
        toggle();

        onClearForm();
    }

    const onSuccessWatcherCreate = () => {
        onCloseDialog();

        const roles = ['admin'];
        const page = 0;
        const sizePerPage = 10;

        dispatch(getWatchersList({
            roles,
            page,
            sizePerPage
        }));
    };

    const onCreateWatcherSubmit = () => {
        if (email !== '' && name !== '') {
            const newWatcher = { email, name };

            dispatch(createNewWatcher(newWatcher, onSuccessWatcherCreate));

            setSubmitted(() => false);
        } else {
            setSubmitted(() => true);
        }
    };

    const onChangeFormField = e => {
        const { name, value } = e.target;

        switch (name) {
            case 'email':
                setEmail(() => value);
                setSubmitted(() => false)
                break;

            case 'name':
                setName(() => value);
                setSubmitted(() => false)
                break;

            default:
                break;
        }
    };

    return (
        <Dialog
            fullWidth
            open={show}
            onClose={toggle}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                Create Watcher
            </DialogTitle>

            <DialogContent>
                <NewWatcherForm
                    email={email}
                    name={name}
                    submitted={submitted}
                    onChangeFormField={onChangeFormField}
                />
            </DialogContent>

            <DialogActions>
                <Row className="m-3">
                    <Button
                        color="primary"
                        variant="text"
                        className="mr-3"
                        onClick={onCreateWatcherSubmit}
                    >
                        Create
                    </Button>

                    <Button
                        color="default"
                        variant="text"
                        onClick={onCloseDialog}
                    >
                        Cancel
                    </Button>
                </Row>
            </DialogActions>
        </Dialog>
    );
}

export default NewWatcherDialog;
