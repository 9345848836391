import React from 'react';
import { NotificationManager } from 'react-notifications';

import {
    GET_VIDEOS,
    GET_VIDEOS_SUCCESS,
    GET_VIDEOS_FAILURE,
    MODERATE_VIDEO,
    MODERATE_VIDEO_SUCCESS,
    MODERATE_VIDEO_FAILURE,
    DELETE_FROM_THE_POOL_OR_CONTEST,
    DELETE_FROM_THE_POOL_OR_CONTEST_SUCCESS,
    DELETE_FROM_THE_POOL_OR_CONTEST_FAILURE,
    GET_VIDEOS_WITH_COMPLAINTS,
    GET_VIDEOS_WITH_COMPLAINTS_SUCCESS,
    GET_VIDEOS_WITH_COMPLAINTS_FAILURE,
    REMOVE_COMPLAINTS_ON_VIDEO,
    REMOVE_COMPLAINTS_ON_VIDEO_SUCCESS,
    REMOVE_COMPLAINTS_ON_VIDEO_FAILURE,
    DELETE_VIDEO_WITH_COMPLAINTS_SUCCESS, DELETE_VIDEO_WITH_COMPLAINTS, DELETE_VIDEO_WITH_COMPLAINTS_FAILURE
} from 'Actions/types';

import { api, errorHandler, successMessageHandler } from 'Api';

export const getVideoList = ({
    page = 0,
    perPage = 10,
    status = 'uploaded'
}) => dispatch => {
    dispatch({ type: GET_VIDEOS });

    const statusPath = status ? `&status=${status}` : '';

    api.get(`admin/videos?page=${page + 1}&per_page=${perPage}${statusPath}`)
        .then(response => {
            dispatch({
                type: GET_VIDEOS_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => dispatch(errorHandler(error, GET_VIDEOS_FAILURE)))
};

export const moderateVideo = ({ id, status, reason = '', onSuccess }) => dispatch => {
    dispatch({ type: MODERATE_VIDEO });

    const data = { status };

    if (reason && reason.length !== 0) {
        data.disapproved_reason = reason;
    }

    api.post(`admin/videos/${id}`, data)
        .then(response => {
            dispatch({
                type: MODERATE_VIDEO_SUCCESS,
                payload: response.data
            });

            if (!successMessageHandler(response)) {
                NotificationManager.success('Success!');
            } else {
                successMessageHandler(response);
            }

            if (onSuccess) {
                onSuccess();
            }
        })
        .catch(error => dispatch(errorHandler(error, MODERATE_VIDEO_FAILURE)))
};

export const deleteVideoFromPoolOrContest = ({videoId, onSuccess}) => dispatch => {
    dispatch({ type: DELETE_FROM_THE_POOL_OR_CONTEST });

    api.get(`admin/videos/${videoId}/reject`).then(response => {
        dispatch({
            type: DELETE_FROM_THE_POOL_OR_CONTEST_SUCCESS,
        });

        if (!successMessageHandler(response)) {
            NotificationManager.success('Success!');
        } else {
            successMessageHandler(response);
        }

        onSuccess?.();
    })
        .catch(error => dispatch(errorHandler(error, DELETE_FROM_THE_POOL_OR_CONTEST_FAILURE)))
}

export const getVideosWithComplaintsList = () => dispatch => {
    dispatch({ type: GET_VIDEOS_WITH_COMPLAINTS });

    api.get(`admin/complaints`)
        .then(response => {
            dispatch({
                type: GET_VIDEOS_WITH_COMPLAINTS_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => dispatch(errorHandler(error, GET_VIDEOS_WITH_COMPLAINTS_FAILURE)))
};

export const removeComplaintsOnVideo = ({videoId, onSuccess}) => dispatch => {
    dispatch({ type: REMOVE_COMPLAINTS_ON_VIDEO });

    api.delete(`admin/videos/${videoId}/complaints`)
        .then(response => {
            dispatch({
                type: REMOVE_COMPLAINTS_ON_VIDEO_SUCCESS,
                payload: response.data
            });

            if (!successMessageHandler(response)) {
                NotificationManager.success('Success!');
            } else {
                successMessageHandler(response);
            }

            onSuccess?.();
        })
        .catch(error => dispatch(errorHandler(error, REMOVE_COMPLAINTS_ON_VIDEO_FAILURE)))
};

export const deleteVideoWithComplaints = ({videoId, onSuccess}) => (dispatch) => {
    dispatch({ type: DELETE_VIDEO_WITH_COMPLAINTS });

    api.delete(`admin/feeds/${videoId}`)
        .then(response => {
            dispatch({
                type: DELETE_VIDEO_WITH_COMPLAINTS_SUCCESS,
                payload: response.data
            });

            if (!successMessageHandler(response)) {
                NotificationManager.success('Success!');
            } else {
                successMessageHandler(response);
            }

            onSuccess?.();
        })
        .catch(error => dispatch(errorHandler(error, DELETE_VIDEO_WITH_COMPLAINTS_FAILURE)))
}
