import React from 'react'

export const TermsAndConditionsJsx = () => {
    return <>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "20pt" }}>
            <strong>PayMeRobot Terms &amp; Conditions</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            <strong>&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            Thank you for using the PayMeRobot/PMR platform, products, service and
            features we make available to you (collectivity, the “Service”). This is the
            Terms of Service and Conditions of Lucrativity, LLC (the “Agreement” or
            “Terms of Service” or “Terms and Conditions”) doing business as
            PayMeRobot/PMR. By using PayMeRobot/PMR, you accept this Agreement and
            consent to contract with us electronically.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            We may update this Agreement by posting a revised version on our website. By
            continuing to use our Services, you accept any revised Agreement.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>Our Services</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            The Service allows you to upload your videos of original content for the
            opportunity to be in PayMeRobot’s daily Contest or upload videos to your
            profile which will or could be viewed, shared, commented upon and liked by
            any PMR account holders.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>The Service Provider</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            <u>
                The company providing the service is Lucrativity LLC, a company operating
                under the laws of Oregon and located at 422 NW 13th Ave, Portland, OR
                97209 (referred to as “PayMeRobot”, “PMR”, “we”, “us”, or “our”).
            </u>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>Accounts</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            Users of the Service shall provide their real names and information. The
            below is not a comprehensive list of obligations you agree to abide by. PMR
            has the right to add obligations as it deems necessary. By registering for
            and maintaining an account with PMR, you agree and adhere to the following:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p
            style={{
                marginTop: "0pt",
                marginLeft: "36pt",
                marginBottom: "0pt",
                textIndent: "-18pt",
                textAlign: "justify",
                fontSize: "13pt"
            }}
        >
            •
            <span
                style={{
                    width: "13.45pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block"
                }}
            >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
            You will not provide any false personal information on the Service or create
            an account for anyone other than yourself;
        </p>
        <p
            style={{
                marginTop: "0pt",
                marginLeft: "36pt",
                marginBottom: "0pt",
                textIndent: "-18pt",
                textAlign: "justify",
                fontSize: "13pt"
            }}
        >
            •
            <span
                style={{
                    width: "13.45pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block"
                }}
            >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
            You will not create more than one personal account/profile;
        </p>
        <p
            style={{
                marginTop: "0pt",
                marginLeft: "36pt",
                marginBottom: "0pt",
                textIndent: "-18pt",
                textAlign: "justify",
                fontSize: "13pt"
            }}
        >
            •
            <span
                style={{
                    width: "13.45pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block"
                }}
            >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
            If we disable your account, you will not create another account without our
            written or electronic permission;
        </p>
        <p
            style={{
                marginTop: "0pt",
                marginLeft: "36pt",
                marginBottom: "0pt",
                textIndent: "-18pt",
                textAlign: "justify",
                fontSize: "13pt"
            }}
        >
            •
            <span
                style={{
                    width: "13.45pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block"
                }}
            >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
            We reserve the right to remove or reclaim your username;
        </p>
        <p
            style={{
                marginTop: "0pt",
                marginLeft: "36pt",
                marginBottom: "0pt",
                textIndent: "-18pt",
                textAlign: "justify",
                fontSize: "13pt"
            }}
        >
            •
            <span
                style={{
                    width: "13.45pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block"
                }}
            >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
            By creating an account, you agree to receive notices from PayMeRobot from
            these email addresses: info@paymerobot.com, no-reply@paymerobot.com or any
            email account PMR creates in the future for such purposes;
        </p>
        <p
            style={{
                marginTop: "0pt",
                marginLeft: "36pt",
                marginBottom: "0pt",
                textIndent: "-18pt",
                textAlign: "justify",
                fontSize: "13pt"
            }}
        >
            •
            <span
                style={{
                    width: "13.45pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block"
                }}
            >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
            You are responsible for all activity that occurs under your account,
            including unauthorized activity;
        </p>
        <p
            style={{
                marginTop: "0pt",
                marginLeft: "36pt",
                marginBottom: "0pt",
                textIndent: "-18pt",
                textAlign: "justify",
                fontSize: "13pt"
            }}
        >
            •
            <span
                style={{
                    width: "13.45pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block"
                }}
            >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
            You must be at least 13 years old to use the Service;
        </p>
        <p
            style={{
                marginTop: "0pt",
                marginLeft: "36pt",
                marginBottom: "0pt",
                textIndent: "-18pt",
                textAlign: "justify",
                fontSize: "13pt"
            }}
        >
            •
            <span
                style={{
                    width: "13.45pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block"
                }}
            >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
            If you are using the Service on behalf of a company or organization, you
            represent that you have authority to act on behalf of that entity, and that
            such entity accepts this Agreement.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            If you are under 18, you represent that you have your parent or guardian’s
            permission to use the Service. Please have them read this Agreement with
            you. If you are a parent or legal guardian of a user under the age of 18, by
            allowing your child to use the Service, you are subject to the terms of this
            Agreement and responsible for your child’s activity on the Service. You are
            responsible for all activity that occurs under your account, including
            unauthorized activity. You must safeguard the confidentiality of your
            account credentials. If you are using a computer that others have access to,
            you must log out of your account after each session. If you become aware of
            unauthorized access to your account, you must change your password and
            notify us immediately.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>Code of Conduct</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p
            style={{
                marginTop: "0pt",
                marginLeft: "36pt",
                marginBottom: "0pt",
                textIndent: "-18pt",
                fontSize: "13pt"
            }}
        >
            •
            <span
                style={{
                    width: "13.45pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block"
                }}
            >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
            In using our Services, you may not:
        </p>
        <p
            style={{
                marginTop: "0pt",
                marginLeft: "36pt",
                marginBottom: "0pt",
                textIndent: "-18pt",
                fontSize: "13pt"
            }}
        >
            •
            <span
                style={{
                    width: "13.45pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block"
                }}
            >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
            Use an offensive screen name or avatar;
        </p>
        <p
            style={{
                marginTop: "0pt",
                marginLeft: "36pt",
                marginBottom: "0pt",
                textIndent: "-18pt",
                fontSize: "13pt"
            }}
        >
            •
            <span
                style={{
                    width: "13.45pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block"
                }}
            >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
            Act in a deceptive manner or impersonate any person or organization;
        </p>
        <p
            style={{
                marginTop: "0pt",
                marginLeft: "36pt",
                marginBottom: "0pt",
                textIndent: "-18pt",
                fontSize: "13pt"
            }}
        >
            •
            <span
                style={{
                    width: "13.45pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block"
                }}
            >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
            Harass or stalk any person;
        </p>
        <p
            style={{
                marginTop: "0pt",
                marginLeft: "36pt",
                marginBottom: "0pt",
                textIndent: "-18pt",
                fontSize: "13pt"
            }}
        >
            •
            <span
                style={{
                    width: "13.45pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block"
                }}
            >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
            Harm or exploit minors or anyone;
        </p>
        <p
            style={{
                marginTop: "0pt",
                marginLeft: "36pt",
                marginBottom: "0pt",
                textIndent: "-18pt",
                fontSize: "13pt"
            }}
        >
            •
            <span
                style={{
                    width: "13.45pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block"
                }}
            >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
            Distribute “spam” in any form or use misleading metadata;
        </p>
        <p
            style={{
                marginTop: "0pt",
                marginLeft: "36pt",
                marginBottom: "0pt",
                textIndent: "-18pt",
                fontSize: "13pt"
            }}
        >
            •
            <span
                style={{
                    width: "13.45pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block"
                }}
            >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
            Infringe on any third party’s copyrights or other rights (e.g., trademark,
            privacy rights, etc.);
        </p>
        <p
            style={{
                marginTop: "0pt",
                marginLeft: "36pt",
                marginBottom: "0pt",
                textIndent: "-18pt",
                fontSize: "13pt"
            }}
        >
            •
            <span
                style={{
                    width: "13.45pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block"
                }}
            >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
            Collect personal information about others;
        </p>
        <p
            style={{
                marginTop: "0pt",
                marginLeft: "36pt",
                marginBottom: "0pt",
                textIndent: "-18pt",
                fontSize: "13pt"
            }}
        >
            •
            <span
                style={{
                    width: "13.45pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block"
                }}
            >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
            Access another’s account without permission;
        </p>
        <p
            style={{
                marginTop: "0pt",
                marginLeft: "36pt",
                marginBottom: "0pt",
                textIndent: "-18pt",
                fontSize: "13pt"
            }}
        >
            •
            <span
                style={{
                    width: "13.45pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block"
                }}
            >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
            Use or export any of our services in violation of any U.S. export control
            laws;
        </p>
        <p
            style={{
                marginTop: "0pt",
                marginLeft: "36pt",
                marginBottom: "0pt",
                textIndent: "-18pt",
                fontSize: "13pt"
            }}
        >
            •
            <span
                style={{
                    width: "13.45pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block"
                }}
            >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
            Engage in any unlawful activity;
        </p>
        <p
            style={{
                marginTop: "0pt",
                marginLeft: "36pt",
                marginBottom: "0pt",
                textIndent: "-18pt",
                fontSize: "13pt"
            }}
        >
            •
            <span
                style={{
                    width: "13.45pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block"
                }}
            >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
            Cause or encourage others to do any of the above.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>Inclusivity</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            PayMeRobot is an inclusive place. We welcome all people regardless of
            identity, orientation or otherwise to be a part of our unique entertainment
            space and Service.
            <span style={{ fontFamily: '"MS Gothic"' }} />
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            <strong>&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>Copyright Policy</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            You may only upload content that you have the right to upload and share.
            Copyright owners may send PayMeRobot a takedown notice as stated in our
            copyright policy if they believe PayMeRobot is hosting copyrighted material.
            PayMeRobot will take action on those who are proven to have violated our
            copyright policy up to and including terminating their accounts.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            <strong>&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>Rights You Grant Us</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            When you share, post, or upload content on or in connection with our
            Service, you hereby grant to us a non-exclusive, royalty-free, transferable,
            sub-licensable, worldwide license to host, use, distribute, modify, run,
            copy, publicly perform or display, translate, and create derivative works of
            your content.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            <strong>&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>Term and Termination</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            We may terminate this Agreement at any time by written notice and users may
            terminate at any time by deleting their accounts.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            If you breach this Agreement, we may, at our option: terminate this
            Agreement immediately, with or without advance written notice; suspend,
            delete, or limit access to your account or any content within it. If we
            delete your account for breach, you may not re-register.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>Indemnification</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            You agree to indemnify, defend and hold us and our partners, employees, and
            affiliates, harmless from any liability, loss, claim and expense, including
            reasonable attorney's fees, arising from: (i) your use of and access to the
            Service; (ii) your violation of any term of these Terms of Service; (iii)
            your violation of any third party right, including without limitation any
            copyright, property, or privacy right; or (iv) any claim that your Content
            caused damage to a third party. This defense and indemnification obligation
            will survive these Terms of Service and your use of the Service.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>Limitation of Liability</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            EXCEPT AS REQUIRED BY APPLICABLE LAW, PAYMEROBOT, ITS AFFILIATES, OFFICERS,
            DIRECTORS, EMPLOYEES AND AGENTS WILL NOT BE RESPONSIBLE FOR ANY LOSS OF
            PROFITS, REVENUES, BUSINESS OPPORTUNITIES, GOODWILL, OR ANTICIPATED SAVINGS;
            LOSS OR CORRUPTION OF DATA; INDIRECT OR CONSEQUENTIAL LOSS; PUNITIVE DAMAGES
            CAUSED BY US OF OUR SERVICE.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>Assignment</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "13pt" }}>
            We may assign the Agreements, and any of its rights under the Agreements, in
            whole or in part, and we may delegate any of our obligations under the
            Agreements. You may not assign the Agreements, in whole or in part, nor
            transfer or sub-license your rights under the Agreements, to any third party
            without permission in writing by us.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>Severability</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            If any provision of this Agreement is held by a court of competent
            jurisdiction to be illegal, invalid or unenforceable in any jurisdiction,
            the remainder of this Agreement shall remain in full force and effect, and
            such holding shall not affect this Agreement or any provision hereof in any
            other jurisdiction. If any provision of this Agreement is so held to be
            illegal, invalid or unenforceable only in part or degree, that provision
            shall remain in full force and effect to the extent not held illegal,
            invalid or unenforceable.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>Jurisdiction, Venue and Law</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            Interpretation and construction of this Agreement is governed by the Laws of
            the State of Oregon and its appellate decisions. Venue for any cause of
            action arising under this Agreement shall be in the General Court of Justice
            of Multnomah County, Oregon; if arbitration or mediation is used by mutual
            agreement, it shall take place in Portland, Oregon.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>Entire Agreement</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            This Agreement constitutes the entire agreement, and supersedes all prior
            agreements and understandings, among the parties with respect to the subject
            matter hereof. Any changes to this agreement must be in writing and signed
            by both parties.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>Contact Us</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            If you have any questions concerning our Terms of Service, please contact
            PayMeRobot Customer Support by email at{" "}
            <a href="mailto:support@compengine.com" style={{ textDecoration: "none" }}>
                <span style={{ color: "#dca10d" }}>info@paymerobot.com.</span>
            </a>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "20pt" }}>
            <strong>&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "20pt" }}>
            <strong>Privacy Policy</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            Last updated on: November 16, 2023
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>
                PayMeRobot, LLC ("PayMeRobot", “PMR”, "we", "our", or "us") respects your
                privacy and strives to earn and keep your trust.
            </strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            <strong>&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>Acceptance and Changes</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            By registering for, downloading, or using our services, you accept this
            Privacy Policy, which is part of our Terms of Service. We may modify this
            Privacy Policy from time to time. If we change the Privacy Policy in a way
            that materially lessens our commitments to you, we will provide notice to
            registered users by email or other methods.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>Data We Collect About You</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            We collect information about you when you use our services. In addition,
            third parties may collect information about you when you use our services.
            Collected information may include or reflect personal information that could
            identify you, as well as non-personal information. We collect information
            from you, and about you from third parties, in the following ways:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <ol type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                Your submission of information in connection with your creation of an
                Account, enrollment in the Services, creation of a profile on our website,
                or entering into a contest sponsored by us, whether through an
                application, or otherwise;
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                Information received from you, from various scripts, analytics tools, and
                other software on our website or in the Services, or which is generated as
                a result of your interaction and use of our website;
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                Through cookies, web beacons, tracking technologies, and similar
                technologies, whether implemented by us or any third party. “Cookies” are
                small files that your Web browser places in your device’s memory;
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                Information submitted by or obtainable from your internet service
                provider, software, computer, mobile device, or other method of accessing
                the Services (which you may be able to control through the settings of
                your software and devices);
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                Information received from third parties, including without limitation
                information submitted by or available from any marketplace, store, social
                media network, or other service, website, or application that you use,
                which refers you to PayMeRobot, or which contains or embeds any of the
                Services, Content, or User Content.
            </li>
        </ol>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>HOW WE USE YOUR PERSONAL DATA</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            Except as set forth herein, your personal data will not be knowingly
            provided by us to third parties without your permission.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            Among other uses set forth in this policy, we may process your personal data
            we receive because it is necessary for the performance of a contract with
            you, including, once you accept our Terms of Service, create an Account and
            authorize us to use your personal data, to:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <ol type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                We use your data to verify you when you access your account;
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                Provide the Services to you or send any information to you, including
                alerts, newsletters, or other materials you have requested;
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                Enable you to access or use the Services, functionality, or other portions
                thereof which you requested;
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                Enable our interactive tools to function properly for you to be able to
                use the Services;
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                Respond to your requests and send messages to you, notice to you, or
                otherwise communicate with you regarding your Account, the Services, and
                any customer service or technical support issue you raise;
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                Disclose, use, and reproduce the comments and feedback you reveal in a
                public forum ion the Services in accordance with our Terms;
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                Facilitate your experience through cookies which allow the Services to
                recognize you if you visit multiple pages during the same session, so that
                you don’t need to re-enter your information multiple times. Once you close
                your browser, session cookies expire and no longer have any effect;
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                We use your data where we are legally required to do so. For example, we
                may need to gather your data to respond to a subpoena or court order.
            </li>
        </ol>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>4. WITH WHOM YOUR PERSONAL DATA MAY BE SHARED</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            We share your personal data with:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <ol type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                You or any person with your Credentials;
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                PayMeRobot employees, in order to operate, optimize, improve, and/or
                further develop the Services;
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                Our third-party processors for the purposes of improving the Services or
                our business;
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                Governmental authorities, regulatory bodies, law enforcement, or other
                authorized persons to the extent required by law or deemed by us to be
                necessary, appropriate, or in the best interests of PayMeRobot or the
                public in connection with a request from the foregoing;
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                To the extent you expressly authorize to do so, affiliated or unaffiliated
                third parties for marketing purposes;
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                A third party, subject to a reasonable obligation of confidentiality, in
                connection with any merger, acquisition, asset sale, financing, or other
                capital transaction involving the sale of all, or substantially all, of
                our assets.
            </li>
        </ol>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>PROTECTING YOUR INFORMATION</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            We use encryption and authentication tools to protect the security of
            personal information that you share with us. However, no data transmission
            over the internet (or, with respect to our mobile services, over cellular
            phone networks) can be guaranteed to be 100% secure. As a result, while we
            strive to protect personal data, we cannot and do not guarantee or warrant
            the security of any information you transmit to or from the Service, and you
            do so at your own risk.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            Except as set forth herein, we restrict access to your personal information
            such that those PayMeRobot employees, contractors, and agents who need to
            know such personal information in order to operate, develop or improve our
            Services or otherwise fulfill their obligations to us are bound by
            confidentiality obligations and may be subject to discipline, including
            termination and criminal prosecution, if they fail to meet these
            obligations.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>HOW LONG IS YOUR PERSONAL DATA KEPT</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            We will retain your personal data for as long as your Account on the
            Services remains in good standing, and for as long as permitted or required
            for legal and regulatory purposes after your Account is terminated. Please
            note that Aggregate Information, which is not personal data, shall be
            retained indefinitely to improve the functionality of the Service.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>CALIFORNIA RESIDENTS</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>&nbsp;</strong>
        </p>
        <ol type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                Under California’s “Shine the Light” law, California residents have the
                right to opt out of disclosing information to third parties for the
                purpose of allowing such third parties to directly market their products
                and services. At this time, we do not engage in this type of disclosure.
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                Under the California Consumer Privacy Act (“CCPA”), California consumers
                have the following rights, which can be exercised directly or in certain
                cases, through an authorized agent:
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                You have the right to request information about the categories and sources
                of personal information we collect, our purposes for collecting the
                information, and the types of third parties that receive that information.
                In addition, you have the right to request a copy of your personal
                information.
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                You have the right to request that we delete some or all of the personal
                information that we have about you. Deleting all data will typically
                require the deletion of your account, along with all content, including
                your videos. Notwithstanding the foregoing, we may retain information to
                provide services you have ordered, complete transactions, honor opt-outs,
                prevent fraud, spam, and other abuse, comply with our legal obligations,
                cooperate with law enforcement, and to exercise and defend our rights.
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                You have the right to opt out of the sale of your personal information,
                and to request information about whether we have sold your personal
                information in the past 12 months. When you use our services, we may share
                your personal information with advertising partners in a way that may
                constitute a sale under the CCPA. You may opt out via the link provided on
                the relevant page or app. If we do not provide an opt-out mechanism, it is
                because we do not believe in good faith that your personal information is
                being sold.
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                We won’t discriminate against you because you exercise any rights herein.
            </li>
        </ol>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>NEVADA RESIDENTS</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            Nevada residents have the right to opt out of the sale of certain "covered
            information" collected by operators of websites or online services. We
            currently do not sell covered information, as "sale" is defined by such law,
            and we don't have plans to sell this information. However, if you would like
            to be notified if we decide in the future to sell personal information
            covered by the Act, you can provide us with your name and email address. You
            are responsible for updating any change in your email address by the same
            method and we are not obligated to cross-reference other emails you may have
            otherwise provided us for other purposes. We will maintain this information
            and contact you if our plans change. At that time, we will create a process
            for verifying your identity and providing an opportunity to verified
            consumers to complete their opt-out. Please become familiar with our data
            practices as set forth in this Privacy Policy. We may share your data as
            explained in this Privacy Policy, such as to enhance your experiences and
            our services, and those activities will be unaffected by a Nevada do not
            sell request.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>CHILDREN’S PRIVACY AND INTERNATIONAL CONSIDERATIONS</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            PayMeRobot does not allow any user under thirteen (13) years of age or
            knowingly collect or maintain personal information from persons under 13
            years. If we learn that personal information of persons under 13 years has
            been collected on or through the Services, we will take the appropriate
            steps to delete this information. If you are the parent or legal guardian of
            a child under 13 who has become a registered user or subscriber of
            PayMeRobot or any of the Services or otherwise set up an Account, then
            please contact PayMeRobot at info@paymerobot.com to have that child’s
            account terminated and personal information deleted.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            We are headquartered in the United States and any information that you
            submit, or we otherwise obtain will be transferred to, collected, and held
            in the United States. We may subsequently transfer such information to
            another county or jurisdiction in connection with the authorized uses of
            information set forth herein.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>CHANGES TO THIS PRIVACY POLICY</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            PayMeRobot may change this Privacy Policy at any time by posting revisions
            to our site. We expect most changes will be minor. In the event that any
            changes are made to use of your personal data, you will be informed via
            e-mail, and will afford you the opportunity to opt-out of any further use.
            Your use of the site constitutes acceptance of the provisions of this
            Privacy Policy and your continued usage after such changes are posted
            constitutes acceptance of each revised Privacy Policy. If you do not agree
            to the terms of this Privacy Policy or any revised policy, please exit the
            site immediately.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>Contact Us</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            If you have any questions concerns, please contact PayMeRobot Customer
            Support at info@paymerobot.com.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "20pt" }}>
            <strong>&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "20pt" }}>
            <strong>&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "20pt" }}>
            <strong>Copyright Policy</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>
                This Copyright Policy sets forth the process by which copyright holders
                and their agents may remove allegedly infringing materials available on
                PayMeRobot’s online service. By registering for, downloading, or using our
                services, you accept this Copyright Policy, which is part of our Terms of
                Service.
            </strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            <strong>&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>DMCA Policy</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            PayMeRobot respects the intellectual property of others and expects its
            users to do the same. Each user must ensure that the materials they upload
            do not infringe any third-party copyright. PayMeRobot will promptly remove
            materials in accordance with the Digital Millennium Copyright Act (“DMCA”)
            when properly notified that the materials infringe a third party's
            copyright. PayMeRobot will also, in appropriate circumstances, terminate the
            accounts of repeat copyright infringers.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>DMCA Takedown Notices</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            To request the removal of materials based upon copyright infringement, you
            must file a notice containing the following:
        </p>
        <ol type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                Your name, address, telephone number, and email address (if any);
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                A description of the copyrighted work that you claim has been infringed;
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                A description of where on PayMeRobot’s service the material that you claim
                is infringing may be found, sufficient for PayMeRobot to locate the
                material (e.g., the URL for the video);
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                A statement that you have a good faith belief that the use of the
                copyrighted work is not authorized by the copyright owner, its agent, or
                the law;
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                A statement, by you, that the information in the notice is accurate, and
                under penalty of perjury, that you are authorized to act on behalf of the
                copyright owner; and
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                Your electronic or physical signature.
            </li>
        </ol>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>SUBMITTING A DMCA INFRINGEMENT NOTICE</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            Download the form using the button and then you may submit this notice:
        </p>
        <p
            style={{
                marginTop: "0pt",
                marginLeft: "36pt",
                marginBottom: "0pt",
                textIndent: "-18pt",
                fontSize: "13pt"
            }}
        >
            •
            <span
                style={{
                    width: "13.45pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block"
                }}
            >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
            By email: info@paymerobot.com
        </p>
        <p
            style={{
                marginTop: "0pt",
                marginLeft: "36pt",
                marginBottom: "0pt",
                textIndent: "-18pt",
                fontSize: "13pt"
            }}
        >
            •
            <span
                style={{
                    width: "13.45pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block"
                }}
            >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
            By mail: PayMeRobot Copyright Agent
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            *PayMeRobot may disclose notices with affected users and third-party
            databases that collect information about copyright takedown notices.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>DMCA COUNTER-NOTIFICATIONS</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            If you are a PayMeRobot user who wishes to challenge the removal of
            materials caused by a DMCA takedown notice, you must file a
            counter-notification containing the following.
        </p>
        <ol type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                Your name, address, and telephone number.
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                A description of the material that was removed and the location on
                PayMeRobot’s service where it previously appeared (e.g., the URL of the
                video).
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                A statement, under penalty of perjury, that you have a good faith belief
                that the material was removed or disabled as a result of mistake or
                misidentification.
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                A statement that you consents to the jurisdiction of Federal District
                Court for the judicial district in which your address is located, or if
                your address is outside of the United States, for any judicial district in
                which PayMeRobot may be found (United States District Court for the
                Western District of North Carolina), and that you accept service of
                process from the person who filed the original DMCA notice or an agent of
                that person.
            </li>
            <li
                style={{ marginLeft: "33.84pt", paddingLeft: "2.16pt", fontSize: "13pt" }}
            >
                Your electronic or physical signature.
            </li>
        </ol>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>&nbsp;</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>SUBMITTING A NOTICE OF INFRINGEMENT</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            Download the form using the button and then you may submit this notice:
        </p>
        <p
            style={{
                marginTop: "0pt",
                marginLeft: "36pt",
                marginBottom: "0pt",
                textIndent: "-18pt",
                fontSize: "13pt"
            }}
        >
            •
            <span
                style={{
                    width: "13.45pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block"
                }}
            >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
            By email: info@paymerobot.com
        </p>
        <p
            style={{
                marginTop: "0pt",
                marginLeft: "36pt",
                marginBottom: "0pt",
                textIndent: "-18pt",
                fontSize: "13pt"
            }}
        >
            •
            <span
                style={{
                    width: "13.45pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block"
                }}
            >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
            By mail: PayMeRobot Copyright Agent
        </p>
        <p
            style={{
                marginTop: "0pt",
                marginLeft: "36pt",
                marginBottom: "0pt",
                textIndent: "-18pt",
                fontSize: "13pt"
            }}
        >
            •
            <span
                style={{
                    width: "13.45pt",
                    font: '7pt "Times New Roman"',
                    display: "inline-block"
                }}
            >
      &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
    </span>
            *PayMeRobot will forward any complete counter-notification to the person who
            filed the original DMCA notice. The copyright owner(s) may elect to file a
            lawsuit against you for copyright infringement. If we do not receive notice
            that such a lawsuit has been filed within ten (10) business days after we
            provide notice of your counter-notification, we may restore the challenged
            materials. Until that time, your materials will remain removed.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>REPEATED INFRINGEMENT</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            PayMeRobot will terminate user accounts that receive three (3) DMCA strikes.
            A “DMCA strike” accrues each time that material is removed from a user’s
            account due to a DMCA notice. We may group multiple DMCA notices received
            within a short period of time as a single DMCA strike.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            We may remove a DMCA strike in appropriate circumstances, such as where (1)
            the underlying material is ultimately restored due to a DMCA
            counter-notification; or (2) the claimant withdraws the underlying notice.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            We may terminate user accounts that receive fewer than three (3) DMCA
            strikes in appropriate circumstances, such as where the user has a history
            of violating or willfully disregarding our Terms &amp; Conditions.
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            &nbsp;
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "2pt", fontSize: "16pt" }}>
            <strong>COPYRIGHT AGENT</strong>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            You may address inquiries to PayMeRobot’s Copyright Agent:
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            <u>PayMeRobot. LLC</u>
            <u>
                <span style={{ fontFamily: '"MS Gothic"' }} />
            </u>
            <u>Attn: PayMeRobot Copyright Agent</u>
            <u>
                <span style={{ fontFamily: '"MS Gothic"' }} />
            </u>
            <u>422 NW 13th Avenue</u>
            <u>
                <span style={{ fontFamily: '"MS Gothic"' }} />
            </u>
            <u>Portland, OR 97209</u>
            <span style={{ fontFamily: '"MS Gothic"' }} />
            <a href="mailto:dmca@CompEngine.com" style={{ textDecoration: "none" }}>
                <span style={{ color: "#dca10d" }}>info</span>
            </a>
            <span style={{ color: "#dca10d" }}>@paymerobot.com</span>
        </p>
        <p style={{ marginTop: "0pt", marginBottom: "0pt", fontSize: "13pt" }}>
            NOTE: This should not be construed as legal advice. Before taking any action
            based on this website, you should consider your personal situation and seek
            professional advice from an attorney.
        </p>
        <p style={{ bottom: 10, right: 10, position: "absolute" }}>
            <a
                href="https://wordtohtml.net"
                target="_blank"
                style={{ fontSize: 11, color: "#d0d0d0" }}
            >
                Converted to HTML with WordToHTML.net
            </a>
        </p>
    </>
}
