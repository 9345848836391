import React from 'react';

import {
    GET_LANDING_CONTENT_VOTING,
    GET_LANDING_CONTENT_VOTING_SUCCESS,
    GET_LANDING_CONTENT_VOTING_FAILURE,
    GET_LANDING_TERMS,
    GET_LANDING_TERMS_SUCCESS,
    GET_LANDING_TERMS_FAILURE
} from 'Actions/types';

import { api, errorHandler } from 'Api';

export const getTermsForLanding = () => dispatch => {
    dispatch({ type: GET_LANDING_TERMS });

    api.get('admin/system/settings/terms')
        .then(response => {
            dispatch({
                type: GET_LANDING_TERMS_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => dispatch(errorHandler(error, GET_LANDING_TERMS_FAILURE)))
};

export const getVotingContestForLanding = () => dispatch => {
    dispatch({ type: GET_LANDING_CONTENT_VOTING });

    api.get('admin/contests/voting')
        .then(response => {
            dispatch({
                type: GET_LANDING_CONTENT_VOTING_SUCCESS,
                payload: response.data.data
            });
        })
        .catch(error => dispatch(errorHandler(error, GET_LANDING_CONTENT_VOTING_FAILURE)))
};
