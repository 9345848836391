import React from 'react';

import { getDateForCurrentContest } from 'Utils/DateModifiers';
import { useSelector } from 'react-redux';
import { getCurrentContestData} from "../../selectors";

const CurrentDate = () => {
    const currentContestData = useSelector(getCurrentContestData)

    return (
        <div className="header-contest-date">
            <h2 className="m-0">{getDateForCurrentContest(currentContestData?.date)}</h2>
        </div>
    );
};

export default CurrentDate;
