import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import { RemoveRedEye, RemoveRedEyeOutlined } from '@material-ui/icons';

import { getCurrentUserInfo, updateUserProfile } from 'Actions';

import FormErrors from 'Constants/FormErrors';

import PositionedFormFeedback from 'Components/PositionedFormFeedback';

import { getUserInfo, getUserRole } from 'Selectors';

import { arePasswordsMatching, isInvalidField } from 'Utils/FormUtils';

const ProfileForm = () => {
    const userInfo = useSelector(getUserInfo);
    const role = useSelector(getUserRole);

    const dispatch = useDispatch();

    const [name, setName] = useState('');

    const [password, setPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [passwordConfirm, setConfirmPassword] = useState('');

    const [showPassword, setPasswordVisibility] = useState(false);
    const [showNewPassword, setNewPasswordVisibility] = useState(false);
    const [showPasswordConfirm, setPasswordConfirmVisibility] = useState(false);

    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        if (userInfo) {
            setName(() => userInfo.name);
        }
    }, [userInfo]);

    const onTogglePasswordVisibility = () => {
        setPasswordVisibility(state => !state)
    };

    const onToggleNewPasswordVisibility = () => {
        setNewPasswordVisibility(state => !state)
    };

    const onTogglePasswordConfirmVisibility = () => {
        setPasswordConfirmVisibility(state => !state)
    };

    const onUpdateProfileSubmit = () => {
        const email = userInfo.email;

        if (
            name &&
            name !== '' &&
            password !== '' &&
            newPassword !== '' &&
            newPassword === passwordConfirm
        ) {
            const dataToUpdate = {
                email,
                name,
                current_password: password,
                password: newPassword,
                password_confirmation: passwordConfirm
            };

            const onSuccess = () => {
                getCurrentUserInfo();
            }

            dispatch(updateUserProfile(dataToUpdate, onSuccess));

            setSubmitted(() => false);
        } else {
            setSubmitted(() => true);
        }
    };

    const onChangeFormField = e => {
        const { name, value } = e.target;

        switch (name) {
            case 'name': setName(() => value); break;
            case 'password': setPassword(() => value); break;
            case 'newPassword': setNewPassword(() => value); break;
            case 'passwordConfirm': setConfirmPassword(() => value); break;
            default: break;
        }

        setSubmitted(() => false)
    };

    return (
        <div className="profile-container">
            <h1 className="text-center font-2x">My profile</h1>

            {userInfo && (
                <div className="profile-form-container">
                    <Row className="align-items-end mb-3">
                        <h3 className="text-muted mb-0 mr-3">Email</h3>
                        <h4 className="m-0 font-weight-light">{userInfo.email}</h4>
                    </Row>

                    <Row className="py-3 flex-nowrap align-items-center position-relative">
                        <h3 className="text-muted mb-0 mr-3">
                            {role === 'admin' ? 'Watcher name' : 'Admin name'}
                        </h3>

                        <Input
                            invalid={isInvalidField(submitted, name)}
                            type="text"
                            name="name"
                            className="form-control input-lg"
                            placeholder="Enter new name"
                            onChange={onChangeFormField}
                            value={name}
                        />

                        {isInvalidField(submitted, name) && (
                            <PositionedFormFeedback message={FormErrors.name.blank} rightOffset />
                        )}
                    </Row>

                    <Row className="py-3 flex-nowrap align-items-center position-relative">
                        <h3 className="text-muted mb-0 mr-3">Password</h3>

                        <InputGroup>
                            <Input
                                invalid={!isInvalidField(submitted, name) && isInvalidField(submitted, password)}
                                type={showPassword ? 'text' : 'password'}
                                name="password"
                                className="form-control input-lg"
                                placeholder="Password"
                                onChange={onChangeFormField}
                                value={password}
                            />

                            <InputGroupAddon addonType="append">
                                <InputGroupText>
                                    <IconButton
                                        disabled={password.length === 0}
                                        aria-label="eye"
                                        tabIndex="-1"
                                        onClick={onTogglePasswordVisibility}
                                    >
                                        {showPassword ? <RemoveRedEye/> : <RemoveRedEyeOutlined/>}
                                    </IconButton>
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>

                        {
                            !isInvalidField(submitted, name) &&
                            isInvalidField(submitted, password) && (
                                <PositionedFormFeedback message={FormErrors.password.blank} rightOffset />
                            )
                        }
                    </Row>

                    <h2 className="mt-40 mr-3 mb-20 profile-change-password-title">
                        Change password
                    </h2>

                    <Row className="py-3 flex-nowrap align-items-center position-relative">
                        <h3 className="text-muted mb-0 mr-3">New password</h3>

                        <InputGroup>
                            <Input
                                invalid={
                                    !isInvalidField(submitted, name) &&
                                    !isInvalidField(submitted, password) &&
                                    isInvalidField(submitted, newPassword)
                                }
                                type={showNewPassword ? 'text' : 'password'}
                                name="newPassword"
                                className="form-control input-lg"
                                placeholder="New password"
                                onChange={onChangeFormField}
                                value={newPassword}
                            />

                            <InputGroupAddon addonType="append">
                                <InputGroupText>
                                    <IconButton
                                        disabled={newPassword.length === 0}
                                        aria-label="eye"
                                        tabIndex="-1"
                                        onClick={onToggleNewPasswordVisibility}
                                    >
                                        {showNewPassword ? <RemoveRedEye/> : <RemoveRedEyeOutlined/>}
                                    </IconButton>
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>

                        {
                            !isInvalidField(submitted, name) &&
                            !isInvalidField(submitted, password) &&
                            isInvalidField(submitted, newPassword) && (
                                <PositionedFormFeedback message={FormErrors.newPassword.blank} rightOffset />
                            )
                        }
                    </Row>

                    <Row className="py-3 flex-nowrap align-items-center position-relative">
                        <h3 className="text-muted mb-0 mr-3">Confirm</h3>

                        <InputGroup>
                            <Input
                                invalid={
                                    isInvalidField(submitted, passwordConfirm) &&
                                    !isInvalidField(submitted, name) &&
                                    !isInvalidField(submitted, password) &&
                                    !isInvalidField(submitted, newPassword)
                                }
                                type={showPasswordConfirm ? 'text' : 'password'}
                                name="passwordConfirm"
                                className="form-control input-lg"
                                placeholder="Confirm the password"
                                onChange={onChangeFormField}
                                value={passwordConfirm}
                            />

                            <InputGroupAddon addonType="append">
                                <InputGroupText>
                                    <IconButton
                                        disabled={passwordConfirm.length === 0}
                                        aria-label="eye"
                                        tabIndex="-1"
                                        onClick={onTogglePasswordConfirmVisibility}
                                    >
                                        {showPasswordConfirm ? <RemoveRedEye/> : <RemoveRedEyeOutlined/>}
                                    </IconButton>
                                </InputGroupText>
                            </InputGroupAddon>
                        </InputGroup>

                        {
                            !isInvalidField(submitted, name) &&
                            !isInvalidField(submitted, password) &&
                            !isInvalidField(submitted, newPassword) &&
                            !arePasswordsMatching(submitted, newPassword, passwordConfirm) && (
                                <PositionedFormFeedback
                                    message={FormErrors.confirmation.newPassword.noMatch}
                                    rightOffset
                                />
                            )
                        }
                    </Row>

                    <Row className="mt-4 justify-content-end">
                        <Button
                            color="primary"
                            className="profile-save-button py-2 font-weight-bold"
                            onClick={onUpdateProfileSubmit}
                            variant="text"
                        >
                            Save
                        </Button>
                    </Row>
                </div>
            )}
        </div>
    );
};

export default ProfileForm;
