import React  from 'react';
import { useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';

import { sendVerifyEmail } from 'Actions';

import { getUserEmailFromStorage } from 'Utils/LocalStorageUtils';

const ConfirmUserEmailForm = () => {
    const dispatch = useDispatch();

    return (
        <div className="profile-wrapper">
            <div className="content">
                <div className="d-flex justify-content-start align-items-center mb-5">
                    <h4 className="mb-0 font-weight-light">
                        <span className="pr-1">
                            You were authorized but you need to verify your own account to get access to the Portal. We have sent the validation email to
                        </span>

                        <span className="font-weight-bold">
                             {getUserEmailFromStorage()}
                         </span>
                    </h4>
                </div>

                <div className="pt-10">
                    <Button
                        color="primary"
                        onClick={() => dispatch(sendVerifyEmail())}
                        variant="text"
                    >
                        I didn't receive the validation letter
                    </Button>
                </div>
            </div>
        </div>
    );
}

export default ConfirmUserEmailForm;
