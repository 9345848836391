import React from 'react';
import { Link } from 'react-router-dom';
import { Col, Container, Input, Row } from 'reactstrap';
import Button from '@material-ui/core/Button';
import LinearProgress from '@material-ui/core/LinearProgress';

import AppConfig from 'Constants/AppConfig';
import FormErrors from 'Constants/FormErrors';

import PositionedFormFeedback from 'Components/PositionedFormFeedback';

import { isInvalidField } from 'Utils/FormUtils';

const ForgotPasswordForm = ({
    email,
    loading,
    onChangeFormField,
    onResetPasswordFormSubmit,
    submitted
}) => (
    <Container className="page-container-center-wrapper flex-column">
        {loading && <LinearProgress/>}

        <Row>
            <Col md={12} className="d-flex justify-content-center">
                <img
                    className="img-fluid"
                    src={AppConfig.appLogo}
                    alt=""
                />
            </Col>
        </Row>

        <Row>
            <Col md={12} className="d-flex justify-content-center pt-40">
                <h1>Password recovery</h1>
            </Col>
        </Row>

        <Row className="py-3 w-100">
            <Col md={12} className="d-flex justify-content-center">
                <Input
                    invalid={isInvalidField(submitted, email)}
                    type="text"
                    name="email"
                    className="form-control input-lg"
                    placeholder="Email"
                    onChange={onChangeFormField}
                    value={email}
                />

                {isInvalidField(submitted, email) && (
                    <PositionedFormFeedback message={FormErrors.email.blank} leftOffset />
                )}
            </Col>
        </Row>

        <Row className="justify-content-between w-100 mb-3 px-2 my-3">
            <Button
                color="primary"
                className="mx-1 py-2 font-weight-bold"
                fullWidth
                onClick={onResetPasswordFormSubmit}
                variant="text"
            >
                Reset password
            </Button>
        </Row>

        <Row className="justify-content-between pb-4">
            <Link to="/admin/signin" className="mx-3 text-muted font-weight-bold">
                Sign in
            </Link>
        </Row>
    </Container>
);

export default ForgotPasswordForm;
