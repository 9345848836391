import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Row } from 'reactstrap';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { signIn } from 'Actions';

import SignInToAdminPanelForm from './SignInToAdminPanelForm';

const SignInToAdminPanelDialog = ({ show, toggle }) =>  {
    const dispatch = useDispatch();
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setPasswordVisibility] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const onTogglePasswordVisibility = () => {
        setPasswordVisibility(state => !state)
    };

    const onSubmitLoginForm = () => {
        if (email !== '' && password !== '') {
            dispatch(signIn({ email, password }, history));

            setSubmitted(() => false);
        } else {
            setSubmitted(() => true);
        }
    };

    const onChangeFormField = e => {
        const { name, value } = e.target;

        switch (name) {
            case 'email':
                setEmail(() => value);
                setSubmitted(() => false)
                break;

            case 'password':
                setPassword(() => value);
                setSubmitted(() => false)
                break;

            default:
                break;
        }
    };

    return (
        <Dialog
            fullWidth
            open={show}
            onClose={toggle}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">
                <p className="w-100 text-center mb-4 font-2x font-weight-bold">Please log in</p>
            </DialogTitle>

            <DialogContent className="pb-0">
                <SignInToAdminPanelForm
                    email={email}
                    password={password}
                    showPassword={showPassword}
                    submitted={submitted}
                    onChangeFormField={onChangeFormField}
                    onTogglePasswordVisibility={onTogglePasswordVisibility}
                />
            </DialogContent>

            <DialogActions className="overflow-hidden pt-0">
                <Row className="landing-admin-panel-dialog-actions">
                    <Button
                        color="secondary"
                        className="font-weight-bold"
                        onClick={onSubmitLoginForm}
                        variant="text"
                    >
                        Log in
                    </Button>
                </Row>
            </DialogActions>
        </Dialog>
    );
}

export default SignInToAdminPanelDialog;
