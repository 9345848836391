import React from 'react';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import MomentUtils from '@date-io/moment';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';

import App from './container/App';

import 'Assets/scss/_style.scss';
import 'react-datepicker/dist/react-datepicker.css';
import 'react-notifications/lib/notifications.css';
import 'react-quill/dist/quill.snow.css';

import 'Assets/fonts/rubik/rubik.css';

import { configureStore } from './store';

const MainApp = () => (
    <Provider store={configureStore()}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
            <Router>
                <Switch>
                    <Route path="/" component={App} />
                </Switch>
            </Router>
        </MuiPickersUtilsProvider>
    </Provider>
);

export default MainApp;
