import React from 'react';
import { Input, InputGroup, InputGroupAddon, InputGroupText, Row } from 'reactstrap';
import IconButton from '@material-ui/core/IconButton';
import { RemoveRedEye, RemoveRedEyeOutlined } from '@material-ui/icons';

import FormErrors from 'Constants/FormErrors';

import PositionedFormFeedback from 'Components/PositionedFormFeedback';

import { arePasswordsMatching, isInvalidField } from 'Utils/FormUtils';

const ChangePasswordForm = ({
    password,
    newPassword,
    passwordConfirm,
    onChangeFormField,
    onTogglePasswordVisibility,
    onToggleNewPasswordVisibility,
    onTogglePasswordConfirmVisibility,
    showPassword,
    showNewPassword,
    showPasswordConfirm,
    submitted
}) => (
    <div className="d-flex flex-column align-items-center">
        <div className="change-password-form">
            <Row className="py-3 flex-nowrap align-items-center position-relative">
                <h3 className="text-muted mb-0 mr-3">Password</h3>

                <InputGroup>
                    <Input
                        invalid={isInvalidField(submitted, password)}
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        className="form-control input-lg"
                        placeholder="Password"
                        onChange={onChangeFormField}
                        value={password}
                    />

                    <InputGroupAddon addonType="append">
                        <InputGroupText>
                            <IconButton
                                disabled={password.length === 0}
                                aria-label="eye"
                                tabIndex="-1"
                                onClick={onTogglePasswordVisibility}
                            >
                                {showPassword ? <RemoveRedEye/> : <RemoveRedEyeOutlined/>}
                            </IconButton>
                        </InputGroupText>
                    </InputGroupAddon>
                </InputGroup>

                {isInvalidField(submitted, password) && (
                    <PositionedFormFeedback message={FormErrors.password.blank} rightOffset />
                )}
            </Row>

            <Row className="py-3 flex-nowrap align-items-center position-relative">
                <h3 className="text-muted mb-0 mr-3">New password</h3>

                <InputGroup>
                    <Input
                        invalid={!isInvalidField(submitted, password) && isInvalidField(submitted, newPassword)}
                        type={showNewPassword ? 'text' : 'password'}
                        name="newPassword"
                        className="form-control input-lg"
                        placeholder="New password"
                        onChange={onChangeFormField}
                        value={newPassword}
                    />

                    <InputGroupAddon addonType="append">
                        <InputGroupText>
                            <IconButton
                                disabled={newPassword.length === 0}
                                aria-label="eye"
                                tabIndex="-1"
                                onClick={onToggleNewPasswordVisibility}
                            >
                                {showNewPassword ? <RemoveRedEye/> : <RemoveRedEyeOutlined/>}
                            </IconButton>
                        </InputGroupText>
                    </InputGroupAddon>
                </InputGroup>

                {!isInvalidField(submitted, password) && isInvalidField(submitted, newPassword) && (
                    <PositionedFormFeedback message={FormErrors.newPassword.blank} rightOffset />
                )}
            </Row>

            <Row className="py-3 flex-nowrap align-items-center position-relative">
                <h3 className="text-muted mb-0 mr-3">Confirm</h3>

                <InputGroup>
                    <Input
                        invalid={
                            isInvalidField(submitted, passwordConfirm) &&
                            !isInvalidField(submitted, password) &&
                            !isInvalidField(submitted, newPassword)
                        }
                        type={showPasswordConfirm ? 'text' : 'password'}
                        name="passwordConfirm"
                        className="form-control input-lg"
                        placeholder="Confirm the password"
                        onChange={onChangeFormField}
                        value={passwordConfirm}
                    />

                    <InputGroupAddon addonType="append">
                        <InputGroupText>
                            <IconButton
                                disabled={passwordConfirm.length === 0}
                                aria-label="eye"
                                tabIndex="-1"
                                onClick={onTogglePasswordConfirmVisibility}
                            >
                                {showPasswordConfirm ? <RemoveRedEye/> : <RemoveRedEyeOutlined/>}
                            </IconButton>
                        </InputGroupText>
                    </InputGroupAddon>
                </InputGroup>

                {
                    !isInvalidField(submitted, password) &&
                    !isInvalidField(submitted, newPassword) &&
                    !arePasswordsMatching(submitted, newPassword, passwordConfirm) && (
                        <PositionedFormFeedback message={FormErrors.confirmation.newPassword.noMatch} rightOffset />
                    )
                }
            </Row>
        </div>
    </div>
);

export default ChangePasswordForm;
