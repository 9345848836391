import {
    GET_SETTINGS,
    GET_SETTINGS_SUCCESS,
    GET_SETTINGS_FAILURE,
    UPDATE_SETTINGS,
    UPDATE_SETTINGS_SUCCESS,
    UPDATE_SETTINGS_FAILURE, GET_PUSH_NOTIFICATIONS, GET_PUSH_NOTIFICATIONS_SUCCESS, GET_PUSH_NOTIFICATIONS_FAILURE
} from 'Actions/types';

const INIT_STATE = {
    data: [],
    pushNotifications: [],
    loading: false,
    error: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_SETTINGS:
            return {
                ...state,
                loading: true
            };

        case GET_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload
            };

        case GET_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case UPDATE_SETTINGS:
            return {
                ...state,
                loading: true
            };

        case UPDATE_SETTINGS_SUCCESS:
            return {
                ...state,
                loading: false
            };

        case UPDATE_SETTINGS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case GET_PUSH_NOTIFICATIONS:
            return {
                ...state,
                loading: true
            };

        case GET_PUSH_NOTIFICATIONS_SUCCESS:
            return {
                ...state,
                loading: false,
                pushNotifications: action.payload
            };

        case GET_PUSH_NOTIFICATIONS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        default: return state;
    }
}
