import React, { useState } from 'react';
import { Col, Input, Pagination, PaginationItem, PaginationLink } from 'reactstrap';

import { getSizePerPageOptions } from 'Utils/PaginationUtils';

const CustomPagination = ({
    data,
    page,
    lastPage,
    perPage,
    onChangeHandler,
    topRef
}) => {
    const [active, setActive] = useState(page === 0 ? 0 : page - 1);
    const [sizePerPage, setSizePerPage] = useState(getSizePerPageOptions()[0]);

    const onScrollToListTop = () => {
        topRef.current.scrollIntoView({ block: 'center' });
    }

    const onChangePage = page => {
        if (page >= 1 && page <= lastPage) {
            onChangeHandler({ page: page - 1, perPage });

            setActive(() => page - 1);
        }

        onScrollToListTop();
    };

    const onChangeSizePerPage = e => {
        e.persist();

        onChangeHandler({ page: 0, perPage: +e.target.value });

        setActive(() => 0);
        setSizePerPage(() => getSizePerPageOptions().find(({ label }) => label === e.target.value));

        onScrollToListTop();
    };

    const renderNumberedPages = () => {
        const pages = [];

        const numberedItem = i => (
            <PaginationItem key={i} active={active + 1 === i}>
                <PaginationLink onClick={() => onChangePage(i)}>
                    {i}
                </PaginationLink>
            </PaginationItem>
        )

        for (let i = active + 1; i <= active + 3 && i <= lastPage; i++) {
            if (active >= 1 && active + 1 < lastPage) {
                pages.push(numberedItem(i - 1));

                if (active + 2 === i && lastPage === i) {
                    pages.push(numberedItem(i));
                }
            } else if (active >= 1 && active + 1 === lastPage) {
                if (i - 2 > 0) {
                    pages.push([
                        numberedItem(i - 2),
                    ]);
                }

                pages.push([
                    numberedItem(i - 1),
                    numberedItem(i)
                ]);
            } else {
                pages.push(numberedItem(i));
            }
        }

        return pages;
    };

    if (!data || data.length === 0 || (data.length <= 10 && lastPage === 1)) {
        return null;
    }

    return (
        <div className="pagination-container">
            <Col sm={6} className="d-flex px-0 mt-3">
                <Input
                    disabled={data.length === 0}
                    type="select"
                    name="paginatePerPage"
                    id="paginatePerPage"
                    className="input-lg text-black bg-white"
                    style={{ height: '3rem' }}
                    onChange={onChangeSizePerPage}
                    value={sizePerPage.value}
                >
                    {getSizePerPageOptions().map(({ label, value }) => (
                        <option key={value}>{label}</option>
                    ))}
                </Input>
            </Col>

            <Col sm={6} className="d-flex justify-content-sm-end justify-content-xs-start px-0 mt-3">
                <Pagination className="mb-0 px-10">
                    <PaginationItem>
                        <PaginationLink
                            first
                            onClick={() => onChangePage(1)}
                        />
                    </PaginationItem>

                    <PaginationItem>
                        <PaginationLink
                            previous
                            onClick={() => onChangePage(active)}
                        />
                    </PaginationItem>

                    {renderNumberedPages()}

                    <PaginationItem>
                        <PaginationLink
                            next
                            onClick={() => onChangePage(active + 2)}
                        />
                    </PaginationItem>

                    <PaginationItem>
                        <PaginationLink
                            last
                            onClick={() => onChangePage(lastPage)}
                        />
                    </PaginationItem>
                </Pagination>
            </Col>
        </div>
    );
}

export default CustomPagination;
