import {
    CHANGE_WATCHERS_LIST_PAGE,
    CHANGE_WATCHERS_LIST_SIZE_PER_PAGE,
    CREATE_WATCHER,
    CREATE_WATCHER_SUCCESS,
    CREATE_WATCHER_FAILURE,
    GET_WATCHERS,
    GET_WATCHERS_SUCCESS,
    GET_WATCHERS_FAILURE,
    REMOVE_WATCHER,
    REMOVE_WATCHER_SUCCESS,
    REMOVE_WATCHER_FAILURE
} from 'Actions/types';

const INIT_STATE = {
    data: [],
    loading: false,
    error: null,
    pagination: {
        page: 0,
        sizePerPage: 10,
        total: 0
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case CHANGE_WATCHERS_LIST_PAGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    page: action.payload
                }
            };

        case CHANGE_WATCHERS_LIST_SIZE_PER_PAGE:
            return {
                ...state,
                pagination: {
                    ...state.pagination,
                    sizePerPage: action.payload
                }
            };

        case CREATE_WATCHER:
            return {
                ...state,
                loading: true
            };

        case CREATE_WATCHER_SUCCESS:
            return {
                ...state,
                loading: false
            };

        case CREATE_WATCHER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case GET_WATCHERS:
            return {
                ...state,
                loading: true
            };

        case GET_WATCHERS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data,
                pagination: {
                    ...state.pagination,
                    total: action.payload.meta.total
                }
            };

        case GET_WATCHERS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case REMOVE_WATCHER:
            return {
                ...state,
                loading: true
            };

        case REMOVE_WATCHER_SUCCESS:
            return {
                ...state,
                loading: false
            };

        case REMOVE_WATCHER_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        default: return state;
    }
}
