import React from 'react';
import { MuiThemeProvider } from '@material-ui/core/styles';

import primaryTheme from './themes/primaryTheme';

const RctThemeProvider = ({ rtlLayout, children }) => {
    const theme = primaryTheme;

    if (rtlLayout) {
        theme.direction = 'rtl'
    } else {
        theme.direction = 'ltr'
    }

    return (
        <MuiThemeProvider theme={theme}>
            {children}
        </MuiThemeProvider>
    );
}

export default RctThemeProvider;
