export const CHANGE_USER_ACCESS = 'CHANGE_USER_ACCESS';
export const CHANGE_USER_ACCESS_SUCCESS = 'CHANGE_USER_ACCESS_SUCCESS';
export const CHANGE_USER_ACCESS_FAILURE = 'CHANGE_USER_ACCESS_FAILURE';

export const DELETE_USER = 'DELETE_USER';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const CHANGE_USERS_LIST_PAGE = 'CHANGE_USERS_LIST_PAGE';
export const CHANGE_USERS_LIST_SIZE_PER_PAGE = 'CHANGE_USERS_LIST_SIZE_PER_PAGE';

export const CHANGE_WATCHERS_LIST_PAGE = 'CHANGE_WATCHERS_LIST_PAGE';
export const CHANGE_WATCHERS_LIST_SIZE_PER_PAGE = 'CHANGE_WATCHERS_LIST_SIZE_PER_PAGE';

export const CREATE_WATCHER = 'CREATE_WATCHER';
export const CREATE_WATCHER_SUCCESS = 'CREATE_WATCHER_SUCCESS';
export const CREATE_WATCHER_FAILURE = 'CREATE_WATCHER_FAILURE';

export const GET_CONTESTS = 'GET_CONTESTS';
export const GET_CONTESTS_SUCCESS = 'GET_CONTESTS_SUCCESS';
export const GET_CONTESTS_FAILURE = 'GET_CONTESTS_FAILURE';

export const GET_CURRENT_CONTEST = 'GET_CURRENT_CONTEST';
export const GET_CURRENT_CONTEST_SUCCESS = 'GET_CURRENT_CONTEST_SUCCESS';
export const GET_CURRENT_CONTEST_FAILURE = 'GET_CURRENT_CONTEST_FAILURE';

export const GET_LANDING_TERMS = 'GET_LANDING_TERMS';
export const GET_LANDING_TERMS_SUCCESS = 'GET_LANDING_TERMS_SUCCESS';
export const GET_LANDING_TERMS_FAILURE = 'GET_LANDING_TERMS_FAILURE';

export const GET_LANDING_CONTENT_VOTING = 'GET_LANDING_CONTENT_VOTING';
export const GET_LANDING_CONTENT_VOTING_SUCCESS = 'GET_LANDING_CONTENT_VOTING_SUCCESS';
export const GET_LANDING_CONTENT_VOTING_FAILURE = 'GET_LANDING_CONTENT_VOTING_FAILURE';

export const GET_SETTINGS = 'GET_SETTINGS';
export const GET_SETTINGS_SUCCESS = 'GET_SETTINGS_SUCCESS';
export const GET_SETTINGS_FAILURE = 'GET_SETTINGS_FAILURE';

export const GET_USERS = 'GET_USERS';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const GET_VIDEOS = 'GET_VIDEOS';
export const GET_VIDEOS_SUCCESS = 'GET_VIDEOS_SUCCESS';
export const GET_VIDEOS_FAILURE = 'GET_VIDEOS_FAILURE';

export const GET_VIDEOS_WITH_COMPLAINTS = 'GET_VIDEOS_WITH_COMPLAINTS';
export const GET_VIDEOS_WITH_COMPLAINTS_SUCCESS = 'GET_VIDEOS_WITH_COMPLAINTS_SUCCESS';
export const GET_VIDEOS_WITH_COMPLAINTS_FAILURE = 'GET_VIDEOS_WITH_COMPLAINTS_FAILURE';

export const REMOVE_COMPLAINTS_ON_VIDEO = 'REMOVE_COMPLAINTS_ON_VIDEO';
export const REMOVE_COMPLAINTS_ON_VIDEO_SUCCESS = 'REMOVE_COMPLAINTS_ON_VIDEO_SUCCESS';
export const REMOVE_COMPLAINTS_ON_VIDEO_FAILURE = 'REMOVE_COMPLAINTS_ON_VIDEO_FAILURE';

export const DELETE_VIDEO_WITH_COMPLAINTS = 'DELETE_VIDEO_WITH_COMPLAINTS';
export const DELETE_VIDEO_WITH_COMPLAINTS_SUCCESS = 'DELETE_VIDEO_WITH_COMPLAINTS_SUCCESS';
export const DELETE_VIDEO_WITH_COMPLAINTS_FAILURE = 'DELETE_VIDEO_WITH_COMPLAINTS_FAILURE';

export const GET_WATCHERS = 'GET_WATCHERS';
export const GET_WATCHERS_SUCCESS = 'GET_WATCHERS_SUCCESS';
export const GET_WATCHERS_FAILURE = 'GET_WATCHERS_FAILURE';

export const LOGIN = 'LOGIN';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

export const LOGOUT = 'LOGOUT';

export const MODERATE_VIDEO = 'MODERATE_VIDEO';
export const MODERATE_VIDEO_SUCCESS = 'MODERATE_VIDEO_SUCCESS';
export const MODERATE_VIDEO_FAILURE = 'MODERATE_VIDEO_FAILURE';

export const DELETE_FROM_THE_POOL_OR_CONTEST = 'DELETE_FROM_THE_POOL_OR_CONTEST';
export const DELETE_FROM_THE_POOL_OR_CONTEST_SUCCESS = 'DELETE_FROM_THE_POOL_OR_CONTEST_SUCCESS';
export const DELETE_FROM_THE_POOL_OR_CONTEST_FAILURE = 'DELETE_FROM_THE_POOL_OR_CONTEST_FAILURE';

export const PASSWORD_CHANGE_NECESSITY = 'PASSWORD_CHANGE_NECESSITY';

export const RESET_PASSWORD = 'RESET_PASSWORD';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILURE = 'RESET_PASSWORD_FAILURE';

export const REMOVE_WATCHER = 'REMOVE_WATCHER';
export const REMOVE_WATCHER_SUCCESS = 'REMOVE_WATCHER_SUCCESS';
export const REMOVE_WATCHER_FAILURE = 'REMOVE_WATCHER_FAILURE';

export const SEND_RESET_PASSWORD = 'SEND_RESET_PASSWORD';
export const SEND_RESET_PASSWORD_SUCCESS = 'SEND_RESET_PASSWORD_SUCCESS';
export const SEND_RESET_PASSWORD_FAILURE = 'SEND_RESET_PASSWORD_FAILURE';

export const SEND_VERIFY_EMAIL = 'SEND_VERIFY_EMAIL';
export const SEND_VERIFY_EMAIL_SUCCESS = 'SEND_VERIFY_EMAIL_SUCCESS';
export const SEND_VERIFY_EMAIL_FAILURE = 'SEND_VERIFY_EMAIL_FAILURE';

export const UPDATE_SETTINGS = 'UPDATE_SETTINGS';
export const UPDATE_SETTINGS_SUCCESS = 'UPDATE_SETTINGS_SUCCESS';
export const UPDATE_SETTINGS_FAILURE = 'UPDATE_SETTINGS_FAILURE';

export const GET_PUSH_NOTIFICATIONS = 'GET_PUSH_NOTIFICATIONS';
export const GET_PUSH_NOTIFICATIONS_SUCCESS = 'GET_PUSH_NOTIFICATIONS_SUCCESS';
export const GET_PUSH_NOTIFICATIONS_FAILURE = 'GET_PUSH_NOTIFICATIONS_FAILURE';

export const UPDATE_PUSH_NOTIFICATIONS = 'UPDATE_PUSH_NOTIFICATIONS';
export const UPDATE_PUSH_NOTIFICATIONS_SUCCESS = 'UPDATE_PUSH_NOTIFICATIONS_SUCCESS';
export const UPDATE_PUSH_NOTIFICATIONS_FAILURE = 'UPDATE_PUSH_NOTIFICATIONS_FAILURE';

export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';
export const UPDATE_USER_PROFILE_SUCCESS = 'UPDATE_USER_PROFILE_SUCCESS';
export const UPDATE_USER_PROFILE_FAILURE = 'UPDATE_USER_PROFILE_FAILURE';

export const USER_INFO = 'USER_INFO';
export const USER_INFO_SUCCESS = 'USER_INFO_SUCCESS';
export const USER_INFO_FAILURE = 'USER_INFO_FAILURE';

export const VERIFY_EMAIL = 'VERIFY_EMAIL';
export const VERIFY_EMAIL_SUCCESS = 'VERIFY_EMAIL_SUCCESS';
export const VERIFY_EMAIL_FAILURE = 'VERIFY_EMAIL_FAILURE';

export const GET_REWARDS = 'GET_REWARDS';
export const GET_REWARDS_SUCCESS = 'GET_REWARDS_SUCCESS';
export const GET_REWARDS_FAILURE = 'GET_REWARDS_FAILURE';

export const ADD_REWARD = 'ADD_REWARD';
export const ADD_REWARD_SUCCESS = 'ADD_REWARD_SUCCESS';
export const ADD_REWARD_FAILURE = 'ADD_REWARD_FAILURE';

export const GET_REWARD_BY_ID = 'GET_REWARD_BY_ID';
export const GET_REWARD_BY_ID_SUCCESS = 'GET_REWARD_BY_ID_SUCCESS';
export const GET_REWARD_BY_ID_FAILURE = 'GET_REWARD_BY_ID_FAILURE';

export const EDIT_REWARD = 'EDIT_REWARD';
export const EDIT_REWARD_SUCCESS = 'EDIT_REWARD_SUCCESS';
export const EDIT_REWARD_FAILURE = 'EDIT_REWARD_FAILURE';

export const DELETE_REWARD = 'DELETE_REWARD';
export const DELETE_REWARD_SUCCESS = 'DELETE_REWARD_SUCCESS';
export const DELETE_REWARD_FAILURE = 'DELETE_REWARD_FAILURE';

export const SET_IS_INVALID__REWARD_FORM = 'SET_IS_INVALID__REWARD_FORM';

export const SEND_REWARD = 'SEND_REWARD';
export const SEND_REWARD_SUCCESS = 'SEND_REWARD_SUCCESS';
export const SEND_REWARD_FAILURE = 'SEND_REWARD_FAILURE';
