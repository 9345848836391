import React from 'react';
import { Redirect, Route } from 'react-router-dom';

import { isUserVerified } from 'Utils/LocalStorageUtils';

const AuthRoute = ({ component: Component, token, ...rest }) => {
    const verified = isUserVerified();

    return (
        <Route
            {...rest}
            render={props =>
                token && verified
                    ? <Redirect to="/admin/watchers" />
                    : <Component {...props} />
            }
        />
    );
};

export default AuthRoute;
