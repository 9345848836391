import MUIDataTable from "mui-datatables";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {addReward, deleteReward, editReward, getRewards} from "Actions/RewardsActions";
import {TableActionButton} from "Components/TableActionButton";
import {Button} from "reactstrap";
import {getIsRewardsLoading, getRewardsEntities, getRewardsTableData} from "Selectors";
import {RewardDialog} from "Routes/rewardPolicy/components/RewardDialog";
import {RewardForm} from "Routes/rewardPolicy/components/RewardForm";
import {TwoActionsDialog} from "Components/TwoActionsDialog";
import {TableLoader} from "Components/Loader";
import {isInvalidRewardDescriptionField, isInvalidRewardField, isInvalidRewardPhotoField} from "Utils/FormUtils";

const columnsOptions = {
    filter: false,
    sort: false,
}

export const RewardsList = () => {
    const dispatch = useDispatch();
    const [isEditMode, setIsEditMode] = useState(false);
    const [isVisibleRewardModal, setIsVisibleRewardModal] = useState(false);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [selectedRewardId, setSelectedRewardId] = useState(-1);
    const [submitted, setSubmitted] = useState(false);

    const [nameField, setNameField] = useState('');
    const [amountField, setAmountField] = useState('');
    const [descriptionField, setDescriptionField] = useState('');
    const [photoField, setPhotoField] = useState('');

    const [isInvalidPhoto, setIsInvalidPhoto] = useState(false);
    const [isInvalidName, setIsInvalidName] = useState(false);
    const [isInvalidAmount, setIsInvalidAmount] = useState(false);
    const [isInvalidDescription, setIsInvalidDescription] = useState(false);

    const [isDisableSaveButton, setIsDisableSaveButton] = useState(true);

    const [file, setFile] = useState(undefined);

    const tableData = useSelector(getRewardsTableData);
    const rewardsEntities = useSelector(getRewardsEntities);
    const isLoading = useSelector(getIsRewardsLoading);

    const showAddButton = tableData.length < 3;

    const resetIsInvalidStates = () => {
        setIsInvalidPhoto(false)
        setIsInvalidName(false)
        setIsInvalidAmount(false)
        setIsInvalidDescription(false)
    }

    const onInputAction = (file) => {
        resetIsInvalidStates()

        setFile(file)
    }

    const onCloseDialogAction = () => {
        setSubmitted(false)
        resetIsInvalidStates()
    }

    const onSaveAction = () => {
        setSubmitted(() => true)

        const isInvalidPhoto = isInvalidRewardPhotoField(true, photoField, file)
        const isInvalidName = isInvalidRewardField(true, nameField, 1, 50)
        const isInvalidAmount = isInvalidRewardField(true, amountField, 1, 4)
        const isInvalidDescription = isInvalidRewardDescriptionField(true, descriptionField, 1, 300)

        setIsInvalidPhoto(isInvalidPhoto)
        setIsInvalidName(isInvalidName)
        setIsInvalidAmount(isInvalidAmount)
        setIsInvalidDescription(isInvalidDescription)

        const isInvalidForm = isInvalidPhoto || isInvalidName || isInvalidAmount || isInvalidDescription;

        if(isInvalidForm){
            return;
        }

        if(isEditMode){
            dispatch(editReward({
                name: nameField,
                amount: amountField,
                description: descriptionField,
                image: photoField,
                rewardId: selectedRewardId
            }, () => {
                dispatch(getRewards())
                setIsVisibleRewardModal(false)
                setIsDisableSaveButton(true)
            }))
        } else if(tableData.length < 3) {
                dispatch(addReward({
                name: nameField,
                amount: amountField,
                description: descriptionField,
                image: photoField
            }, () => {
                dispatch(getRewards())
                setIsVisibleRewardModal(false)
                setIsDisableSaveButton(true)
            }))
        }
    }

    const onChangeFormField = (e) => {
        const { name, value } = e.target;

        switch (name) {
            case 'name':
                setNameField(value);
                setSubmitted(false)
                resetIsInvalidStates()
                break;

            case 'amount_of_users':
                setAmountField(value);
                setSubmitted( false)
                resetIsInvalidStates()
                break;

            case 'description':
                setDescriptionField(value);
                setSubmitted( false)
                resetIsInvalidStates()
                break;

            case 'photo':
                setDescriptionField(value);
                setSubmitted( false)
                resetIsInvalidStates()
                break;

            default:
                break;
        }
    };

    const setRewardFormData = (rewardId) => {
        const reward = rewardsEntities[rewardId];

        setDescriptionField(reward.description)
        setPhotoField(reward.photo)
        setNameField(reward.name)
        setAmountField(reward.amount_of_users)
    }

    const resetRewardFormData = () => {
        setDescriptionField('')
        setPhotoField('')
        setNameField('')
        setAmountField('')
    }

    const onAddAction = () => {
        resetRewardFormData()

        setIsEditMode(false)
        setIsVisibleRewardModal(true)
        setSubmitted(false)
        setIsDisableSaveButton(false)
    }

    const onUpdatePress = (rewardId) => {
        setRewardFormData(rewardId)

        setIsEditMode(true)
        setIsVisibleRewardModal(true)
        setSelectedRewardId(rewardId)
        setSubmitted(false)
    }

    const onDeletePress = (selectedRewardId) => {
        setIsDialogOpen(true);
        setSelectedRewardId(selectedRewardId)
    }

    const onNegativeDialogAction = () => {
        setIsDialogOpen(false)
    }

    const onPositiveDialogAction = () => {
        dispatch(deleteReward({
            rewardId: selectedRewardId
        }, () => {
            dispatch(getRewards())
            setIsDialogOpen(false)
            setSelectedRewardId(-1)
        }))
    }

    const onRenderTableActions = (selectedRewardId) => {

        return (
            <div className="btn-group" role="group">
                <TableActionButton
                    onButtonClick={() => onUpdatePress(selectedRewardId)}
                    secondary={true}
                    text={'Update'}
                />
                <TableActionButton
                    className={'ml-2'}
                    onButtonClick={() => onDeletePress(selectedRewardId)}
                    text={'Delete'}
                />
            </div>
        );
    };

    const onRenderTableTextColumns = (text) => {
        return (
            <span className={'rewards-cell-text'}>
                    {text}
            </span>
        );
    }

    const onRenderTableImageColumns = (photo) => {
        return (
            <div className={'preview-container'}>
                <img src={photo} alt={'reward photo'} className={'preview-image'} />
            </div>
        );
    }

    const options = {
        download: false,
        filter: false,
        print: false,
        search: false,
        selectableRowsHideCheckboxes: true,
        serverSide: true,
        viewColumns: false,
        pagination: false,
    }

    const columns = [
        {
            name: "name",
            label: "Reward name",
            options: {...columnsOptions, customBodyRender: onRenderTableTextColumns}
        },
        {
            name: "amount_of_users",
            label: "Amount of users",
            options: {...columnsOptions, customBodyRender: onRenderTableTextColumns}
        },
        {
            name: "description",
            label: "Reward description",
            options: {...columnsOptions, customBodyRender: onRenderTableTextColumns}
        },
        {
            name: "photo",
            label: "Reward photo",
            options: {...columnsOptions, customBodyRender: onRenderTableImageColumns}
        },
        {
            name: "id",
            label: "Actions",
            options: {...columnsOptions, customBodyRender: onRenderTableActions}
        },
    ];

    useEffect(() => {
        dispatch(getRewards())
    }, []);

    useEffect(() => {
        if(selectedRewardId !== -1){
            const rewardFromStore = rewardsEntities[selectedRewardId];

            const isChangedFields = !(
                rewardFromStore.name === nameField &&
                rewardFromStore.amount_of_users === amountField &&
                rewardFromStore.description === descriptionField &&
                rewardFromStore.photo === photoField
            )

            if(isChangedFields){
                setIsDisableSaveButton(false)
            } else {
                setIsDisableSaveButton(true)
            }
        }
    }, [nameField, descriptionField, photoField, amountField])

    return (
        <>
            {isLoading && <TableLoader />}
            <MUIDataTable data={tableData} columns={columns} options={options} />
            {
                showAddButton && (
                    <Button
                        className={"min-width-100 m-3"}
                        color={'success'}
                        onClick={onAddAction}
                        disabled={isLoading}
                    >
                        <span className={'font-weight-light'}>
                            {'Add'}
                        </span>
                    </Button>
                )
            }
        <RewardDialog
            isEditMode = {isEditMode}
            isVisible = {isVisibleRewardModal}
            setIsVisible = {setIsVisibleRewardModal}
            onSaveAction = {onSaveAction}
            disableSaveButton = {isDisableSaveButton}
            onCloseDialogAction = {onCloseDialogAction}
        >
            <RewardForm
                name={nameField}
                amount={amountField}
                description={descriptionField}
                photo={photoField}
                setPhoto={setPhotoField}
                submitted={submitted}
                onChangeFormField={onChangeFormField}
                isInvalidName={isInvalidName}
                isInvalidAmount={isInvalidAmount}
                isInvalidDescription={isInvalidDescription}
                isInvalidPhoto={isInvalidPhoto}
                onInputAction={onInputAction}
            />
        </RewardDialog>
            <TwoActionsDialog
                isOpen={isDialogOpen}
                onClose={onNegativeDialogAction}
                onNegativeAction={onNegativeDialogAction}
                onPositiveAction={onPositiveDialogAction}
                dialogTitle={"Are you sure you want to delete the reward?"}
                positiveText={"Delete"}
                negativeText={"Cancel"}
            />
        </>
    )
}
