import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { resetPassword } from 'Actions';

import PasswordRecoveryForm from './form';

import {
    getUserEmailFromStorage,
    getResetPasswordTokenFromStorage,
    removeUserEmailFromStorage
} from 'Utils/LocalStorageUtils';

const PasswordRecovery = ({ loading }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [password, setPassword] = useState('');
    const [passwordConfirm, setConfirmPassword] = useState('');

    const [showPassword, setPasswordVisibility] = useState(false);
    const [showPasswordConfirm, setPasswordConfirmVisibility] = useState(false);

    const [submitted, setSubmitted] = useState(false);

    useEffect(() => {
        return () => {
            const email = getUserEmailFromStorage();

            if (email) {
                removeUserEmailFromStorage();
            }
        };
    }, []);

    const onTogglePasswordVisibility = () => {
        setPasswordVisibility(state => !state)
    };

    const onTogglePasswordConfirmVisibility = () => {
        setPasswordConfirmVisibility(state => !state)
    };

    const onSubmitChangePasswordForm = () => {
        const email = getUserEmailFromStorage();
        const token = getResetPasswordTokenFromStorage();

        if (email && password !== '' && password === passwordConfirm) {
            const newPasswordData = {
                email,
                token,
                password,
                password_confirmation: passwordConfirm
            };

            dispatch(resetPassword(newPasswordData, history));

            setSubmitted(() => false);
        } else {
            setSubmitted(() => true);
        }
    };

    const onChangeFormField = e => {
        const { name, value } = e.target;

        switch (name) {
            case 'password':
                setPassword(() => value);
                setSubmitted(() => false)
                break;

            case 'passwordConfirm':
                setConfirmPassword(() => value);
                setSubmitted(() => false)
                break;

            default:
                break;
        }
    };

    return (
        <PasswordRecoveryForm
            loading={loading}
            password={password}
            passwordConfirm={passwordConfirm}
            showPassword={showPassword}
            showPasswordConfirm={showPasswordConfirm}
            submitted={submitted}
            onChangeFormField={onChangeFormField}
            onTogglePasswordVisibility={onTogglePasswordVisibility}
            onTogglePasswordConfirmVisibility={onTogglePasswordConfirmVisibility}
            onSubmitChangePasswordForm={onSubmitChangePasswordForm}
        />
    );
}

export default PasswordRecovery;
