import { Button } from "reactstrap";
import React from "react";

export const TableActionButton = (props) => {
    const { onButtonClick, text, secondary, className } = props;

    return  (
        <Button
            className={"min-width-100" + ` ${className}`}
            color={secondary ? 'secondary' : 'danger'}
            onClick={onButtonClick}
        >
            <span className="font-weight-light">
                {text}
            </span>
        </Button>
    )
}
