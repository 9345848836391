import _ from 'lodash';

import {
    GET_CONTESTS,
    GET_CONTESTS_SUCCESS,
    GET_CONTESTS_FAILURE,
    GET_CURRENT_CONTEST,
    GET_CURRENT_CONTEST_SUCCESS,
    GET_CURRENT_CONTEST_FAILURE
} from 'Actions/types';

const INIT_STATE = {
    current: {
        data: {
            time_left: 0
        },
        loading: false,
        error: null
    },
    data: [],
    loading: false,
    error: null
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_CONTESTS:
            return {
                ...state,
                loading: true
            };

        case GET_CONTESTS_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload.data,
                meta: action.payload.meta
            };

        case GET_CONTESTS_FAILURE:
            return {
                ...state,
                loading: false,
                error: action.payload
            };

        case GET_CURRENT_CONTEST:
            return {
                ...state,
                current: {
                    ...state.current,
                    loading: true
                }
            };

        case GET_CURRENT_CONTEST_SUCCESS:
            return {
                ...state,
                current: {
                    ...state.current,
                    loading: false,
                    data: _.first(_.get(action.payload, 'data', []))
                }
            };

        case GET_CURRENT_CONTEST_FAILURE:
            return {
                ...state,
                current: {
                    ...state.current,
                    loading: false,
                    error: action.payload
                }
            };

        default: return state;
    }
}
