import { FormGroup } from "reactstrap";
import {
    getErrorMessageForRewardDescriptionField,
    getErrorMessageForRewardField,
} from "Utils/FormUtils";
import React from "react";
import {FormInputWithLabel} from "Components/FormInputWithLabel";
import {FormImageInputWithLabel} from "Components/FormImageInputWithLabel";

export const RewardForm = (props) => {
    const {
        name,
        amount,
        description,
        photo,
        setPhoto,
        onChangeFormField,
        submitted,
        isInvalidName,
        isInvalidAmount,
        isInvalidDescription,
        isInvalidPhoto,
        onInputAction,

    } = props;

    return (
        <FormGroup className="has-wrapper text-left">
            <FormInputWithLabel
                errorMessage={getErrorMessageForRewardField(name, "name", submitted)}
                id={'reward-form-name'}
                invalid={isInvalidName}
                label={'Reward Name'}
                maxCounterLength={50}
                name={"name"}
                onChange={onChangeFormField}
                placeholder={'Reward Name'}
                showCounter={true}
                type={'text'}
                value={name}
            />
            <FormInputWithLabel
                errorMessage={getErrorMessageForRewardField(amount, "amount_of_users", submitted)}
                id={'reward-form-amount'}
                invalid={isInvalidAmount}
                label={'Amount of users'}
                maxCounterLength={4}
                name={"amount_of_users"}
                onChange={onChangeFormField}
                placeholder={"Amount of users"}
                showCounter={true}
                type={"number"}
                value={amount}
            />
            <FormInputWithLabel
                className={"min-h-100"}
                errorMessage={getErrorMessageForRewardDescriptionField()}
                id={'reward-form-description'}
                invalid={isInvalidDescription}
                label={'Reward description'}
                maxCounterLength={300}
                name={"description"}
                onChange={onChangeFormField}
                placeholder={"Reward description"}
                showCounter={true}
                type={'textarea'}
                value={description}
            />
            <FormImageInputWithLabel
                label={'Photo'}
                photo={photo}
                setPhoto={setPhoto}
                submitted={submitted}
                onInputAction={onInputAction}
                invalid={isInvalidPhoto}
            />
        </FormGroup>
    );
};
