import React from 'react';
import { Row } from 'reactstrap';
import DatePicker from 'react-datepicker';

const PastContestsCalendar = ({ date, onChangeDate }) => {
    const monthsShown = window.screen.width > 1200 ? 3 : 1;

    return (
        <Row className="justify-content-end my-4 past-contests-calendar">
            <DatePicker
                allowSameDay
                selected={date}
                onChange={onChangeDate}
                monthsShown={monthsShown}
                inline
            />
        </Row>
    );
};

export default PastContestsCalendar;
