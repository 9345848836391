import React from 'react';
import Typography from '@material-ui/core/Typography';

import ProfileForm from './component/ProfileForm';
import {PageLayout} from "Components/PageLayout";

const Profile = ({ match, role }) => (
    <PageLayout
        match={match}
        role={role}
        pageTitle={'Profile'}
        metaDataName={'profile'}
        metaDataContent={'Profile'}
    >
        <div className="rct-tabs h-100">
            <Typography component="div" style={{ padding: 8 * 3 }}>
                <ProfileForm />
            </Typography>
        </div>
    </PageLayout>
);

export default Profile;
