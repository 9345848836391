import {Col, Label} from "reactstrap";
import PositionedFormFeedback from "Components/PositionedFormFeedback";
import React, {useState} from "react";
import {ImageInputWithPreview} from "Components/ImageInputWithPreview";
import {
    getErrorMessageForRewardField,
    isInvalidRewardPhotoField
} from "Utils/FormUtils";

export const FormImageInputWithLabel = (props) => {
    const {
        photo,
        setPhoto,
        label,
        submitted,
        onInputAction,
        invalid
    } = props

    return (
        <Col md={12} className={'mb-lg-4'}>
            <Label>
                {label}
            </Label>

            <ImageInputWithPreview photo={photo} setPhoto={setPhoto} isInvalid={invalid} onInputAction={onInputAction}/>

            {invalid && (
                <PositionedFormFeedback message={getErrorMessageForRewardField(photo,'photo', submitted)} leftOffset />
            )}
        </Col>
    )
}
