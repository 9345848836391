import Typography from "@material-ui/core/Typography";
import React from "react";
import {PageLayout} from "Components/PageLayout";
import {RewardsList} from "Routes/rewardPolicy/components/RewardsList";

const RewardPolicy = ({ match, role }) => (
    <PageLayout
        match={match}
        role={role}
        pageTitle={'Reward policy'}
        metaDataName={'rewards'}
        metaDataContent={'Reward policy'}
    >
        <Typography component="div" style={{ padding: 8 * 3 }}>
            <h1 className="text-center font-2x">{"Reward policy"}</h1>
        </Typography>

        <RewardsList />
    </PageLayout>
);

export default RewardPolicy;
