import React from 'react';
import { useSelector } from 'react-redux';
import LinearProgress from '@material-ui/core/LinearProgress';

import Footer from 'Components/Footer';
import Header from 'Components/Header';
import Sidebar from 'Components/Sidebar';

import { getLoading } from 'Selectors';

import { isUserVerified } from 'Utils/LocalStorageUtils';

const MainApp = ({ children }) => {
    const loading = useSelector(getLoading);

    const verified = isUserVerified();

    const baseContentClasses = 'rct-page-content d-flex flex-column justify-content-between';
    const verifiedUserContentClasses = verified ? '' : ' p-0';

    return (
        <div className="app">
            <div className="app-main-container">
                <div className="app-container">
                    <div className="rct-app-content">
                        {loading && (
                            <div className="loader-rct-layout">
                                <LinearProgress color="secondary" />
                            </div>
                        )}

                        <Header />

                        <div className="rct-page">
                            {verified && <Sidebar />}

                            <div className={`${baseContentClasses}${verifiedUserContentClasses}`}>
                                {children}
                            </div>
                        </div>
                    </div>

                    <Footer/>
                </div>
            </div>
        </div>
    )
};

export default MainApp;
