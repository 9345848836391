import React from 'react';
import { Button } from 'reactstrap';

import { getFileFromStorage, getImageFromStorage, getUserId } from 'Selectors';
import {VideoStatuses} from "Constants/VideoStatuses";

export const ComplaintsVideoItem = ({
                        onManageComplaint,
                       video,
                   }) => {

    return (
        <div
            className={`video-item-container`}
        >
            <div className="video-block">
                {video.url && video.thumbnail && (
                    <video
                        controls
                        controlsList="nodownload noplaybackrate"
                        disablePictureInPicture
                        height="100%"
                        poster={getImageFromStorage(video.thumbnail)}
                    >
                        <source src={getFileFromStorage(video.url)} />
                    </video>
                )}
            </div>

            <div className="video-info">
                <div className="video-information-data">
                    {video.player && video.player.name && <h4>{video.player.name}</h4>}
                    {video.title && <h4 className={'one-line-ellipsis'}>{video.title}</h4>}
                    {video.id && <h4>ID: {video.id}</h4>}
                    {video.description && <p className={'one-line-ellipsis'}>{video.description}</p>}
                    {video.status && <p>{VideoStatuses[video.status] ?? ''}</p>}
                    {(
                        <div className="video-actions">
                            <Button
                                size="sm"
                                color="success"
                                onClick={() => onManageComplaint(video.id)}
                            >
                                Manage Complaint
                            </Button>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
}
