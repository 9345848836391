import { NotificationManager } from 'react-notifications';

import {
    CHANGE_USERS_LIST_PAGE,
    CHANGE_USERS_LIST_SIZE_PER_PAGE,
    CHANGE_USER_ACCESS,
    CHANGE_USER_ACCESS_FAILURE,
    CHANGE_USER_ACCESS_SUCCESS,
    DELETE_USER,
    DELETE_USER_FAILURE,
    DELETE_USER_SUCCESS,
    GET_USERS,
    GET_USERS_FAILURE,
    GET_USERS_SUCCESS,
    UPDATE_USER_PROFILE,
    UPDATE_USER_PROFILE_FAILURE,
    UPDATE_USER_PROFILE_SUCCESS, SEND_REWARD, SEND_REWARD_SUCCESS, SEND_REWARD_FAILURE,
} from 'Actions/types';

import { api, errorHandler, successMessageHandler } from 'Api';

export const getUsersList = ({
    roles,
    page = 0,
    search = '',
    sizePerPage = 10
}) => dispatch => {
    dispatch({ type: GET_USERS });

    const searchPath = search.length === 0 ? '' : `&search=${search}`;

    api.get(`admin/users?roles[]=${roles}&page=${page + 1}&per_page=${sizePerPage}${searchPath}`)
        .then(response => {
            dispatch({
                type: GET_USERS_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => dispatch(errorHandler(error, GET_USERS_FAILURE)))
};

export const changeUserAccess = (id, status, onSuccess) => dispatch => {
    dispatch({ type: CHANGE_USER_ACCESS });

    api.put(`admin/users/${id}/access`, { status })
        .then(response => {
            dispatch({
                type: CHANGE_USER_ACCESS_SUCCESS,
                payload: response.data
            });

            if (!successMessageHandler(response)) {
                NotificationManager.success('Success!');
            } else {
                successMessageHandler(response);
            }

            onSuccess();
        })
        .catch(error => dispatch(errorHandler(error, CHANGE_USER_ACCESS_FAILURE)))
};

export const deleteUser = (id, onSuccess, onFailure) => dispatch => {
    dispatch({ type: DELETE_USER });

    api.delete(`admin/users/${id}/delete`)
        .then(response => {
            dispatch({
                type: DELETE_USER_SUCCESS,
            });

            if (!successMessageHandler(response)) {
                NotificationManager.success('Success!');
            } else {
                successMessageHandler(response);
            }

            onSuccess?.();
        })
        .catch(error => {
            dispatch(errorHandler(error, DELETE_USER_FAILURE))

            onFailure?.();
        })
};

export const updateUserProfile = (data, onSuccess) => dispatch => {
    dispatch({ type: UPDATE_USER_PROFILE });

    api.post(`admin/users`, data)
        .then(response => {
            dispatch({
                type: UPDATE_USER_PROFILE_SUCCESS,
                payload: response.data
            });

            if (!successMessageHandler(response)) {
                NotificationManager.success('Success!');
            } else {
                successMessageHandler(response);
            }

            onSuccess();
        })
        .catch(error => dispatch(errorHandler(error, UPDATE_USER_PROFILE_FAILURE)))
};

export const changeUsersListPage = page => dispatch => {
    dispatch({
        type: CHANGE_USERS_LIST_PAGE,
        payload: page
    });
};

export const changeUsersListSizePerPage = sizePerPage => dispatch => {
    dispatch({
        type: CHANGE_USERS_LIST_SIZE_PER_PAGE,
        payload: sizePerPage
    });
};

export const sendReward = (rewardId, onSuccess) => dispatch => {
    dispatch({ type: SEND_REWARD });

    api.post(`admin/rewards/${rewardId}/send`)
        .then(response => {
            dispatch({
                type: SEND_REWARD_SUCCESS,
                payload: {reward: response.data, rewardId}
            });

            onSuccess?.();
        })
        .catch(error => dispatch(errorHandler(error, SEND_REWARD_FAILURE)))
};
