import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Card, CardBody, Col, Row } from 'reactstrap';
import moment from 'moment';

import CurrentContest from './component/CurrentContest';
import { PastContestsCalendar } from 'Components/Calendar';
import PastContests from './component/PastContests';

import { getUserRole } from 'Selectors';
import {PageLayout} from "Components/PageLayout";

const Contest = ({ match, role }) => {
    const userRole = useSelector(getUserRole);

    const [date, setDate] = useState('');

    const onChangeDate = date => {
        setDate(prevDate => moment(date).isSame(moment(prevDate)) ? '' : date);
    }

    return (
        <PageLayout
            match={match}
            role={role}
            pageTitle={'Contest'}
            metaDataName={'contest'}
            metaDataContent={'Contest'}
        >
            <Card className="border-0">
                <CardBody className="current-contest-container">
                    <Row className="w-100">
                        <Col
                            // className="d-flex flex-column align-items-center"
                            xs={{size: 12, order: 2}}
                            xl={{size: 8, order: 1}}
                        >
                            {userRole === 'super-admin' && <CurrentContest />}

                            <PastContests date={date} />
                        </Col>

                        <Col
                            className="d-flex justify-content-center justify-content-xl-end"
                            xs={{size: 12, order: 1}}
                            xl={{size: 4, order: 2}}
                        >
                            <PastContestsCalendar date={date} onChangeDate={onChangeDate} />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
        </PageLayout>
    );
}

export default Contest;
