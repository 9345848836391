import {
    USER_INFO,
    USER_INFO_SUCCESS,
    USER_INFO_FAILURE
} from 'Actions/types';

import { api, errorHandler } from 'Api';
import { setVerified } from 'Utils/LocalStorageUtils';

export const getCurrentUserInfo = (showError = true) => dispatch => {
    dispatch({ type: USER_INFO });

    api.get('admin/user')
        .then(response => {
            setVerified();

            dispatch({
                type: USER_INFO_SUCCESS,
                payload: response.data
            });
        })
        .catch(error => dispatch(errorHandler(error, USER_INFO_FAILURE, showError)))
};
