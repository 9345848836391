import React from 'react';
import { Row } from 'reactstrap';
import DatePicker from 'react-datepicker';
import moment from 'moment';

import { getContestDurationDays } from 'Utils/DateModifiers';

const VideosCalendarInTheContest = ({ date, onChangeDate }) => {
    const startDate = moment();
    const endDate = moment().add(getContestDurationDays(), 'days'); // contest duration

    const diffInMonths = Math.ceil(endDate.diff(startDate, 'months', true));

    const monthsShown = window.screen.width >= 1063 ? diffInMonths + 1 : 1;

    return (
        <Row className="justify-content-center my-4 videos-contest-calendar">
            <DatePicker
                allowSameDay
                selected={date}
                onChange={onChangeDate}
                monthsShown={monthsShown}
                inline
                showDisabledMonthNavigation
                minDate={startDate.toDate()}
                maxDate={endDate.toDate()}
                popperPlacement="auto"
                popperModifiers={{
                    preventOverflow: {
                        enabled: true
                    }
                }}
            />
        </Row>
    );
};

export default VideosCalendarInTheContest;
