import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import Button from '@material-ui/core/Button';

import logo from 'Assets/img/logo.svg';

const PageNotFound = () => {
    const history = useHistory();

    const onRedirectToLandingPage = () => {
        history.push('/');
    };

    return (
        <div className="not-found-wrapper">
            <div className="d-flex justify-content-center bg-light w-100">
                <div className="pt-60 px-4">
                    <Link to="/">
                        <img
                            src={logo}
                            alt="session-logo"
                            className="img-fluid"
                        />
                    </Link>
                </div>
            </div>

            <div className="session-inner-wrapper">
                <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-9 mx-auto">
                        <div className="error-body text-center">
                            <h2 className="bold mb-0">404</h2>

                            <h2 className="error-msg mb-60">Sorry, page not found</h2>

                            <Button
                                color="secondary"
                                variant="contained"
                                className="text-white"
                                onClick={onRedirectToLandingPage}
                            >
                                Go To Landing Page
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageNotFound;
