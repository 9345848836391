export const normalizeData = (dataArray) => {
    let ids = [];

    const entities = dataArray.reduce((acc, item) => {
        const {id, ...rest} = item

        ids.push(id);

        return {...acc, [id]: rest}
    }, {})

    return { ids, entities }
}
