import React from 'react';
import { Col, Row } from 'reactstrap';

const VideoGroup = ({ classNames = '', border = true, children, date }) => (
    <Row className={`video-group${border ? ' border': ''}${classNames === '' ? '' : ` ${classNames}`}`}>
        {date && (
            <Col md={12}>
                <h3 className="mt-3 text-center">{date}</h3>
            </Col>
        )}

        <Row className={`w-100 justify-content-around${date ? '' : ' current-contest-container'}`}>
            {children}
        </Row>
    </Row>
)

export default VideoGroup;
