import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Input, Row } from 'reactstrap';
import MUIDataTable from 'mui-datatables';

import {
    changeUserAccess,
    changeUsersListPage,
    changeUsersListSizePerPage, deleteUser,
    getUsersList,
    sendReward
} from 'Actions';

import { TableLoader } from 'Components/Loader';
import { TransparentTable } from 'Components/Table';

import { getUsers, getUsersDataLoading, getUserListPagination } from 'Selectors';
import { TableActionButton } from "Components/TableActionButton";
import {TwoActionsDialog} from "Components/TwoActionsDialog";
import {RewardSendButton} from "Components/RewardSendButton";

const UsersList = () => {
    const dispatch = useDispatch();

    const loading = useSelector(getUsersDataLoading);
    const users = useSelector(getUsers);

    const [search, setSearch] = useState('');

    const pagination = useSelector(getUserListPagination);

    const [page, setPage] = useState(pagination.page);
    const [userIdToDelete, setUserIdToDelete] = useState(null);
    const [isDialogOpen, setIsDialogOpen] = useState(false);
    const [sizePerPage, setSizePerPage] = useState(pagination.sizePerPage);

    const roles = ['user'];

    const onChangeUserStatus = (selectedUserId, status) => {
        dispatch(
            changeUserAccess(
                selectedUserId,
                status,
                () => dispatch(getUsersList({ roles, page, sizePerPage, search }))
            )
        );
    };

    const onOpenDialog = (userId) => {
        setUserIdToDelete(userId)

        setIsDialogOpen(true)
    }

    const onNegativeDialogAction = () => { setIsDialogOpen(false) }

    const onPositiveDialogAction = () => {
        dispatch(
            deleteUser(
                userIdToDelete,
                () => {
                    dispatch(getUsersList({ roles, page, sizePerPage, search }))

                    setIsDialogOpen(false)
                },
                () => {
                    setIsDialogOpen(false)

                    console.log('### deleteUser FAILURE: ', userIdToDelete)
                }
            )
        );
    }


        const onTableChange = (action, tableState) => {
        if (action === 'changePage') {
            const { page, rowsPerPage } = tableState;

            setPage(page);

            dispatch(changeUsersListPage(page));

            dispatch(getUsersList({
                roles,
                page,
                sizePerPage: rowsPerPage,
                search
            }));
        }

        if (action === 'changeRowsPerPage') {
            const { rowsPerPage } = tableState;

            setSizePerPage(rowsPerPage);

            dispatch(changeUsersListPage(0));
            dispatch(changeUsersListSizePerPage(rowsPerPage));

            dispatch(getUsersList({
                roles,
                page: 0,
                sizePerPage: rowsPerPage,
                search
            }));
        }
    };

    const onRenderColumnTextBasedOnStatus = (columnText, row) => {
        const [id, email, userName, status] = row.rowData;

        return (
            <span className={`text-${status === 'blocked' ? 'danger' : 'black'}`}>
               {columnText}
            </span>
        );
    };

    const onRenderRewardColumn = (rewardsData, _) => {
        return (
            <div className={'max-h-70px overflow-scroll-custom overflow-x-hidden-custom'}>
                {
                    rewardsData.length === 0 ? (
                        <span className={'rewards-cell-text'}>
                            {'No rewards'}
                        </span>
                    ) : (
                        rewardsData.map((item) => (
                            <div className={'d-flex flex-row my-2 align-items-center justify-content-between max-w-300px'} key={item.id}>
                                <div className={'overflow-hidden text-overflow-ellipsis'} style={{width: '100%'}}>
                                    <span className={'rewards-cell-text mr-3'}>
                                        {item.name}
                                    </span>
                                </div>
                                <div className={'overflow-hidden text-overflow-ellipsis text-center'} style={{width: '100%'}}>
                                    {
                                        item.status === "SENT" ? (
                                            <span className={'rewards-cell-text'}>
                                    {           item.sent_at}
                                            </span>
                                        ) : (
                                            <RewardSendButton onClick={() => {
                                                dispatch(sendReward(item.id, () => {
                                                    dispatch(getUsersList({ roles, page, sizePerPage, search }))
                                                }))
                                            }} text={'Send'} className={'p-0 px-4'}/>
                                        )
                                    }
                                </div>
                            </div>
                        ))
                    )
                }
            </div>)
    };

    const onRenderTableActions = (selectedUserId, row) => {
        const [id, email, userName, status] = row.rowData;

        const newStatus = status === 'blocked' ? 'active' : 'blocked';

        return (
            <div className="btn-group" role="group">
                <TableActionButton
                    onButtonClick={() => onChangeUserStatus(selectedUserId, newStatus)}
                    secondary={status === 'blocked'}
                    text={status === 'blocked' ? 'Unblock' : 'Block'}
                />
                <TableActionButton
                    className={`ml-2 ${status === 'blocked' ? 'visible' : 'invisible'}`}
                    onButtonClick={() => onOpenDialog(selectedUserId)}
                    text={'Delete'}
                />
            </div>
        );
    };

    const onSearchUsers = e => {
        setSearch(() => e.target.value);
    };

    const columns = [
        {
            name: 'id',
            label: 'User ID',
            options: {
                sort: false,
                customBodyRender: onRenderColumnTextBasedOnStatus
            }
        },
        {
            name: 'email',
            label: 'Email',
            options: {
                sort: false,
                customBodyRender: onRenderColumnTextBasedOnStatus
            }
        },
        {
            name: 'name',
            label: 'User Name',
            options: {
                sort: false,
                customBodyRender: onRenderColumnTextBasedOnStatus
            }
        },
        {
            name: 'status',
            label: '',
            options: {
                display: 'false',
                sort: false,
                customBodyRender: onRenderColumnTextBasedOnStatus
            }
        },
        {
            name: 'rewards',
            label: 'Reward',
            options: {
                sort: false,
                customBodyRender: onRenderRewardColumn,
                setCellProps: () => ({
                    style: {
                        maxWidth: '220px', // Установите максимальную ширину
                        whiteSpace: 'nowrap',
                    },
                })
            }
        },
        {
            name: 'id',
            label: 'Managing',
            options: {
                customBodyRender: onRenderTableActions,
                sort: false,
                setCellProps: () => ({
                    style: {
                        maxWidth: '190px', // Установите максимальную ширину
                    },
                })
            }
        }
    ];

    const options = {
        download: false,
        count: pagination.total,
        filter: false,
        onTableChange: onTableChange,
        page: pagination.page,
        print: false,
        rowsPerPage: pagination.sizePerPage,
        search: false,
        selectableRowsHideCheckboxes: true,
        serverSide: true,
        viewColumns: false
    }

    useEffect(() => {
        dispatch(getUsersList({
            roles,
            page: page,
            sizePerPage: sizePerPage,
            search
        }));
    }, [search]);

    return (
        <div className="pt-10 px-30 pb-30">
            <Row className="py-3">
                <Col md={4}>
                    <Input
                        className="mb-3"
                        placeholder="Filter by email"
                        type="search"
                        onChange={onSearchUsers}
                        value={search}
                    />
                </Col>
            </Row>

            {loading && <TableLoader />}

            <TransparentTable transparent={loading}>
                <MUIDataTable
                    data={users}
                    columns={columns}
                    options={options}
                />
            </TransparentTable>

            <TwoActionsDialog
                isOpen={isDialogOpen}
                onClose={onNegativeDialogAction}
                onNegativeAction={onNegativeDialogAction}
                onPositiveAction={onPositiveDialogAction}
                dialogTitle={"Are you sure you want to delete the User?"}
                positiveText={"Delete"}
                negativeText={"Cancel"}
            />
        </div>
    )
}

export default UsersList;
