import React from 'react';

import { numberWithCommas } from 'Utils/NumberModifiers';

const CurrentJackpot = ({ jackpot }) => (
    <div className="header-contest-jackpot">
        <h2 className="m-0">Current jackpot</h2>

        <h3 className="m-0">
            <span className="mr-1">{jackpot ? numberWithCommas(jackpot) : 0}</span>
            <span>$</span>
        </h3>
    </div>
);

export default CurrentJackpot;
