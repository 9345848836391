import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { getContestList } from 'Actions';

import PastContent from './PastContent';

import { getContests } from 'Selectors';

const PastContests = ({ date }) => {
    const dispatch = useDispatch();

    const completedContests = useSelector(getContests);

    const [completed, setCompleted] = useState([]);

    useEffect(() => {
        dispatch(getContestList({
            date,
            page: 0,
            perPage: 3,
            statuses: ['completed']
        }));
    }, [date]);

    useEffect(() => {
        if (date instanceof Date) {
            setCompleted(completedContests);
        } else {
            setCompleted(completedContests.filter((_, idx) => idx <= 2));
        }
    }, [completedContests]);

    return (
        <div className="past-content-info-container p-3">
            <h1>Past contests results</h1>

            {completed.length === 0 && <h4 className="pt-2">No completed contests on selected date</h4>}

            {completed.map(contest => <PastContent key={contest.id} contest={contest} />)}
        </div>
    );
};


export default PastContests;
