import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col,  Row } from 'reactstrap';

import { getVideoList, moderateVideo } from 'Actions';

import CustomPagination from 'Components/Pagination';
import DisapproveDialog from 'Components/DisapproveDialog';
import { VideoItem } from 'Components/Video';

import { getVideoCurrentPage, getVideoLastPage, getVideos, getVideoPerPage } from 'Selectors';

import { filterVideosByDate, formatDate, getUniqVideoDates } from 'Utils/DateModifiers'
import {TwoActionsDialog} from "Components/TwoActionsDialog";

const UploadedList = () => {
    const dispatch = useDispatch();

    const videos = useSelector(getVideos);

    const [showDisapproveDialog, setShowDisapproveDialog] = useState(false);
    const [videoToDisapprove, setVideoToDisapprove] = useState(null);

    const currentPage = useSelector(getVideoCurrentPage);
    const lastPage = useSelector(getVideoLastPage);
    const perPage = useSelector(getVideoPerPage);

    const videoListTopRef = useRef(null);

    const getVideoListAction = ({ page = 0, perPage = 10 }) => {
        dispatch(getVideoList({
            page,
            perPage,
            status: 'uploaded'
        }));
    };

    const onToggleDisapproveDialog = id => {
        setShowDisapproveDialog(prev => !prev);
        setVideoToDisapprove(() => showDisapproveDialog ? null : id);
    };

    const onNegativeDialogAction = () => {
        setShowDisapproveDialog(false)
    }

    const onApproveVideoHandler = id => {
        dispatch(moderateVideo({
            id,
            status: 'in_the_pool',
            onSuccess: () => getVideoListAction({ page: 0, perPage })
        }))
    };

    const onDisapproveVideoHandler = () => {
        dispatch(moderateVideo({
            id: videoToDisapprove,
            status: 'disapproved',
            onSuccess: () => getVideoListAction({ page: 0, perPage })
        }))
    };

    const onModerateHandler = (id, currentStatus) => {
        dispatch(moderateVideo({
            id,
            status: currentStatus === 'moderating' ? 'uploaded' : 'moderating',
            onSuccess: () => getVideoListAction({ page: 0, perPage })
        }));
    };

    useEffect(() => {
        getVideoListAction({ page: 0, perPage: 10 });
    }, []);

    const uniqDates = getUniqVideoDates(videos);

    return (
        <div className="pt-10 pb-30">
            <div ref={videoListTopRef} />

            <Row className="flex-wrap px-4">
                {uniqDates.map(date => (
                    <Col md={12} key={date}>
                        <Row className="px-3 pt-3">
                            <Col md={12}>
                                <h2 className="mb-0">
                                    {formatDate(new Date(date))}
                                </h2>
                            </Col>
                        </Row>

                        <Row>
                            {filterVideosByDate(date, videos).map(video => (
                                <div key={video.id}>
                                    <TwoActionsDialog
                                        isOpen={showDisapproveDialog && videoToDisapprove === video.id}
                                        onClose={onNegativeDialogAction}
                                        onNegativeAction={onNegativeDialogAction}
                                        onPositiveAction={onDisapproveVideoHandler}
                                        dialogTitle={"Are you sure you want to disapprove the video?"}
                                        positiveText={"Disapprove"}
                                        negativeText={"Cancel"}
                                    />

                                    <VideoItem
                                        hasActions
                                        video={video}
                                        onApprove={onApproveVideoHandler}
                                        onModerate={onModerateHandler}
                                        onToggleDisapproveDialog={onToggleDisapproveDialog}
                                    />
                                </div>
                            ))}
                        </Row>
                    </Col>
                ))}
            </Row>

            <Row className="py-3 px-40">
                <CustomPagination
                    page={currentPage}
                    lastPage={lastPage}
                    perPage={perPage}
                    onChangeHandler={getVideoListAction}
                    data={videos}
                    topRef={videoListTopRef}
                />
            </Row>
        </div>
    )
}

export default UploadedList;
