import { createStore, applyMiddleware } from 'redux';
import Thunk from 'redux-thunk';
import { composeWithDevTools } from 'redux-devtools-extension';

// reducers
import reducers from '../reducers';

export function configureStore(initialState) {
    const store = createStore(
        reducers,
        initialState,
        composeWithDevTools(applyMiddleware(Thunk))
    );

    if (module.hot) {
        // Enable Webpack hot module replacement for reducers

        module.hot.accept('../reducers/index', () => {
            const nextRootReducer = require('../reducers/index');

            store.replaceReducer(nextRootReducer);
        });
    }

    return store;
}
