import React, {useEffect, useState} from "react";
import {PageLayout} from "Components/PageLayout";
import Typography from "@material-ui/core/Typography";
import {PushItem} from "Routes/pushNotifications/pushItem";
import {useDispatch, useSelector} from "react-redux";
import {getPushNotifications, updatePushNotifications} from "Actions";
import {getPushNotificationsSelector} from "Selectors";

export const PushNotifications = ({ match, role }) => {
    const dispatch = useDispatch();

    const data = useSelector(getPushNotificationsSelector)

    const [pushEntities, setPushEntities] = useState({})

    useEffect(() => {
        dispatch(getPushNotifications())
    }, [])

    useEffect(() => {
        const entities = data.reduce((previousValue, currentValue) => {
            return {...previousValue, [Object.keys(currentValue)[0]]: currentValue}
        }, [{}])

        setPushEntities(entities)
    }, [data]);

    console.log('entities: ', pushEntities)

    const getOnSubmit = (pushId) => () => {
        dispatch(updatePushNotifications({notificationsData: {
                ...pushEntities[pushId]
            }}))
    }


    return     (
        <PageLayout
        match={match}
        role={role}
        pageTitle={'Push notifications managing'}
        metaDataName={'pushNotifications'}
        metaDataContent={'Push notifications'}
    >
            <Typography component="div" style={{ padding: 8 * 3 }}>
            <h1 className="text-center font-2x">{"Push notifications managing"}</h1>
        </Typography>

            <div className={'pl-3 pr-3'}>
                {
                    data.map((value) => {
                        const pushId = Object.keys(value)[0];

                        const currentPush = pushEntities?.[pushId];
                        const currentPushTitle = currentPush?.[`${pushId}_title`];
                        const currentPushDescription = currentPush?.[pushId];

                        return (<div className={'mb-2'}>
                            <PushItem key={pushId} onSubmit={getOnSubmit(pushId)} title={currentPushTitle} setTitle={(newTitle) => {
                                setPushEntities({
                                    ...pushEntities,
                                    [pushId]: {...currentPush, [`${pushId}_title`]: newTitle}
                                })
                            }} description={currentPushDescription} setDescription={
                                (newText) => {
                                    setPushEntities({
                                        ...pushEntities,
                                        [pushId]: {...currentPush, [pushId]: newText}
                                    })
                                }
                            }/>
                        </div>)
                        }
                    )
                }
            </div>
        </PageLayout>)
}
