import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import { signIn } from 'Actions';

import SignInForm from './form';

const SignIn = ({ loading }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [showPassword, setPasswordVisibility] = useState(false);
    const [submitted, setSubmitted] = useState(false);

    const onTogglePasswordVisibility = () => {
        setPasswordVisibility(state => !state)
    };

    const onSubmitLoginForm = () => {
        if (email !== '' && password !== '') {
            dispatch(signIn({ email, password }, history));

            setSubmitted(() => false);
        } else {
            setSubmitted(() => true);
        }
    };

    const onChangeFormField = e => {
        const { name, value } = e.target;

        switch (name) {
            case 'email':
                setEmail(() => value);
                setSubmitted(() => false)
                break;

            case 'password':
                setPassword(() => value);
                setSubmitted(() => false)
                break;

            default:
                break;
        }
    };

    return (
        <SignInForm
            email={email}
            loading={loading}
            password={password}
            showPassword={showPassword}
            submitted={submitted}
            onChangeFormField={onChangeFormField}
            onTogglePasswordVisibility={onTogglePasswordVisibility}
            onSubmitLoginForm={onSubmitLoginForm}
        />
    );
}

export default SignIn;
