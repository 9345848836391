import {Button} from "reactstrap";
import React from "react";

export const RewardSendButton = (props) => {
    const {text, onClick, className} = props;

    return (
        <Button onClick={onClick} className={className} color="primary">
            <span className="font-weight-light text-white">
                {text}
            </span>
        </Button>
    )
}
