import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';

import { getSettings, updateSettings } from 'Actions';

import PositionedFormFeedback from 'Components/PositionedFormFeedback';

import FormErrors from 'Constants/FormErrors';

import { getJackpotStep } from 'Selectors';

import { isInvalidField } from 'Utils/FormUtils';

const SettingsDialog = ({ show, toggle }) => {
    const dispatch = useDispatch();

    const [jackpotStep, setJackpotStep] = useState('');
    const [submitted, setSubmitted] = useState(false);

    const storedJackpotStep = useSelector(getJackpotStep);

    useEffect(() => {
        if (show) {
            dispatch(getSettings());
        }
    }, [show]);

    useEffect(() => {
        onChangeJackpotStep(+storedJackpotStep.value || '');
    }, [storedJackpotStep]);

    const onChangeJackpotStep = value => {
        setJackpotStep(() => value);
    };

    const onCloseDialog = () => {
        toggle();

        setSubmitted(() => false);
    };

    const onSubmitCallback = () =>  {
        onCloseDialog();

        dispatch(getSettings());
    };

    const onSubmit = () => {
        setSubmitted(() => true);

        if ((jackpotStep !== '') && !isNaN(+jackpotStep)) {
            dispatch(
                updateSettings(
                    { jackpot_step: jackpotStep },
                    () => onSubmitCallback()
                )
            );
        }
    };

    return (
        <Dialog
            size="md"
            open={show}
            onClose={onCloseDialog}
            aria-labelledby="form-dialog-title"
        >
            <DialogTitle id="form-dialog-title">Settings</DialogTitle>

            <DialogContent>
                <FormGroup row className="has-wrapper text-left pl-3 pt-3">
                    <Row className="w-100">
                        <Col md={12} className="d-flex align-items-center px-2">
                            <Label>Jackpot step</Label>
                        </Col>
                    </Row>

                    <Row className="w-100">
                        <Col md={12} className="px-2">
                            <Input
                                invalid={isInvalidField(submitted, jackpotStep) || isNaN(+jackpotStep)}
                                type="text"
                                name="jackpotStep"
                                className="form-control input-lg"
                                placeholder="Please enter jackpot step"
                                onChange={e => onChangeJackpotStep(e.target.value)}
                                value={jackpotStep}
                            />

                            {isInvalidField(submitted, jackpotStep) && (
                                <PositionedFormFeedback
                                    message={FormErrors.jackpot_step.blank}
                                    leftOffset
                                />
                            )}

                            {!isInvalidField(submitted, jackpotStep) && isNaN(+jackpotStep) && (
                                <PositionedFormFeedback
                                    message={FormErrors.jackpot_step.notANumber}
                                    leftOffset
                                />
                            )}
                        </Col>
                    </Row>
                </FormGroup>
            </DialogContent>

            <DialogActions>
                <Row className="m-3">
                    <Button
                        color="primary"
                        variant="text"
                        className="mr-3"
                        onClick={onSubmit}
                    >
                        Change
                    </Button>

                    <Button
                        color="default"
                        variant="text"
                        onClick={onCloseDialog}
                    >
                        Cancel
                    </Button>
                </Row>
            </DialogActions>
        </Dialog>
    );
}

export default SettingsDialog;
