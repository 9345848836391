import React from 'react';
import { Route } from 'react-router-dom';

import RctAppLayout from 'Components/RctAppLayout';

import routerService from 'Services/RouterService';

const DefaultLayout = ({ match }) => (
    <RctAppLayout>
        {routerService && routerService.map((route, key) => (
            <Route
                key={key}
                path={`${match.url}/${route.path}`}
                component={route.component}
            />
        ))}
    </RctAppLayout>
);

export default DefaultLayout;
