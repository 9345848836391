import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { Card, CardBody } from 'reactstrap';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import NewWatcherDialog from './component/NewWatcherDialog';
import WatchersList from './component/WatchersList';

import { getUserInfo, isSuperAdminCheck } from 'Selectors';
import {PageLayout} from "Components/PageLayout";

const Watchers = () => {
    const [showNewWatcherDialog, onSetShowNewWatcherDialog] = useState(false);

    const isSuperAdmin = useSelector(isSuperAdminCheck);
    const userInfo = useSelector(getUserInfo);

    const onToggleNewWatcherDialogShow = () => {
        onSetShowNewWatcherDialog(state => !state);
    };

    const CreateWatcherButton = (
        <Button
            color="secondary"
            variant="text"
            onClick={onToggleNewWatcherDialogShow}
        >
            Add new watcher
        </Button>
    );

    if (userInfo && !isSuperAdmin) {
        return <Redirect to="/admin/users" />;
    }

    return (
        <PageLayout
            pageTitle={'Watchers list'}
            metaDataName={'watchers'}
            metaDataContent={'Watchers list'}
        >
            <Typography component="div" style={{ padding: 8 * 3 }}>
                <h1 className="text-center font-2x">{'Watchers list'}</h1>
            </Typography>

            <Card className="border-0">
                <CardBody>
                    <WatchersList createWatcherButton={CreateWatcherButton} />
                </CardBody>
            </Card>

            <NewWatcherDialog
                show={showNewWatcherDialog}
                toggle={onToggleNewWatcherDialogShow}
            />
        </PageLayout>
    );
}

export default Watchers;
