import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { Col, Container, InputGroup, InputGroupAddon, InputGroupText, Input, Row } from 'reactstrap';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import LinearProgress from '@material-ui/core/LinearProgress';
import { RemoveRedEyeOutlined, RemoveRedEye } from '@material-ui/icons';

import AppConfig from 'Constants/AppConfig';
import FormErrors from 'Constants/FormErrors';

import PositionedFormFeedback from 'Components/PositionedFormFeedback';

import { getLoading } from 'Selectors';

import { arePasswordsMatching, isInvalidField } from 'Utils/FormUtils';
import { getResetPasswordTokenFromStorage } from 'Utils/LocalStorageUtils';

const PasswordRecoveryForm = ({
    onChangeFormField,
    onTogglePasswordVisibility,
    onTogglePasswordConfirmVisibility,
    onSubmitChangePasswordForm,
    password,
    passwordConfirm,
    showPassword,
    showPasswordConfirm,
    submitted
}) => {
    const loading = useSelector(getLoading);

    const token = getResetPasswordTokenFromStorage();

    return (
        <Container className="page-container-center-wrapper flex-column">
            <div className="loader-rct-layout">
                {loading && <LinearProgress color="secondary" />}
            </div>

            <Row>
                <Col md={12} className="d-flex justify-content-center">
                    <img
                        className="img-fluid"
                        src={AppConfig.appLogo}
                        alt=""
                    />
                </Col>
            </Row>

            <Row>
                <Col md={12} className="d-flex justify-content-center pt-40">
                    <h1>Recovery password</h1>
                </Col>
            </Row>

            <Row className="py-3 w-100">
                <Col md={12} className="d-flex justify-content-center">
                    <InputGroup>
                        <Input
                            disabled={!token}
                            invalid={isInvalidField(submitted, password)}
                            type={showPassword ? 'text' : 'password'}
                            name="password"
                            className="form-control input-lg"
                            placeholder="Password"
                            onChange={onChangeFormField}
                            value={password}
                        />

                        <InputGroupAddon addonType="append">
                            <InputGroupText>
                                <IconButton
                                    disabled={password.length === 0}
                                    aria-label="eye"
                                    tabIndex="-1"
                                    onClick={onTogglePasswordVisibility}
                                >
                                    {showPassword ? <RemoveRedEye/> : <RemoveRedEyeOutlined/>}
                                </IconButton>
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>

                    {isInvalidField(submitted, password) && (
                        <PositionedFormFeedback message={FormErrors.password.blank} leftOffset />
                    )}
                </Col>
            </Row>

            <Row className="py-3 w-100">
                <Col md={12} className="d-flex justify-content-center">
                    <InputGroup>
                        <Input
                            disabled={!token}
                            invalid={isInvalidField(submitted, passwordConfirm)}
                            type={showPasswordConfirm ? 'text' : 'password'}
                            name="passwordConfirm"
                            className="form-control input-lg"
                            placeholder="Confirm the password"
                            onChange={onChangeFormField}
                            value={passwordConfirm}
                        />

                        <InputGroupAddon addonType="append">
                            <InputGroupText>
                                <IconButton
                                    disabled={passwordConfirm.length === 0}
                                    aria-label="eye"
                                    tabIndex="-1"
                                    onClick={onTogglePasswordConfirmVisibility}
                                >
                                    {showPasswordConfirm ? <RemoveRedEye/> : <RemoveRedEyeOutlined/>}
                                </IconButton>
                            </InputGroupText>
                        </InputGroupAddon>
                    </InputGroup>

                    {!arePasswordsMatching(submitted, password, passwordConfirm) && (
                        <PositionedFormFeedback message={FormErrors.confirmation.password.noMatch} leftOffset />
                    )}
                </Col>
            </Row>

            <Row className="justify-content-between w-100 mb-3 px-2 py-3">
                <Button
                    disabled={!token}
                    color="primary"
                    className="mx-1 py-2 font-weight-bold"
                    fullWidth
                    onClick={onSubmitChangePasswordForm}
                    variant="text"
                >
                    Confirm
                </Button>
            </Row>

            <Row className="justify-content-between pb-4">
                <Link to="/admin/signin" className="mx-3 text-muted font-weight-bold">
                    Sign in
                </Link>
            </Row>
        </Container>
    );
}

export default PasswordRecoveryForm;
