import React from 'react';
import { Card, CardBody } from 'reactstrap';
import Typography from '@material-ui/core/Typography';

import TermsPage from './component/TermsPage';
import {PageLayout} from "Components/PageLayout";

const Terms = ({ match, role }) => (
    <PageLayout
        match={match}
        role={role}
        pageTitle={'Rules & Regulations'}
        metaDataName={'terms'}
        metaDataContent={'Rules and Regulations'}
    >
        <Typography component="div" style={{ padding: 8 * 3 }}>
            <h1 className="text-center font-2x">{'Rules & Regulations'}</h1>
        </Typography>

        <Card className="border-0">
            <CardBody>
                <TermsPage />
            </CardBody>
        </Card>
    </PageLayout>
);

export default Terms;
