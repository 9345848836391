import React from 'react';
import { NotificationManager } from 'react-notifications';

import {
    LOGIN,
    LOGIN_SUCCESS,
    LOGIN_FAILURE,
    LOGOUT,
    PASSWORD_CHANGE_NECESSITY,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAILURE,
    SEND_RESET_PASSWORD,
    SEND_RESET_PASSWORD_SUCCESS,
    SEND_RESET_PASSWORD_FAILURE,
    SEND_VERIFY_EMAIL,
    SEND_VERIFY_EMAIL_SUCCESS,
    SEND_VERIFY_EMAIL_FAILURE,
    VERIFY_EMAIL,
    VERIFY_EMAIL_SUCCESS,
    VERIFY_EMAIL_FAILURE
} from 'Actions/types';

import { getCurrentUserInfo } from 'Actions';

import { api, errorHandler, successMessageHandler } from 'Api';

import {
    clearLocalStorage,
    removeUserEmailFromStorage,
    removeResetPasswordTokenFromStorage,
    storeUserEmail,
    storeUserToken
} from 'Utils/LocalStorageUtils';

import { clearAllMessages } from 'Utils/NotificationUtils';

export const signIn = (user, history) => dispatch => {
    dispatch({ type: LOGIN });

    api.post('admin/login', user)
        .then(response => {
            storeUserToken(response.data.token);
            storeUserEmail(response.data.user.email);

            dispatch({
                type: LOGIN_SUCCESS,
                payload: response.data
            });

            history.push('/admin/verify');

            clearAllMessages();

            successMessageHandler(response);

            dispatch(getCurrentUserInfo(false));
        })
        .catch(error => dispatch(errorHandler(error, LOGIN_FAILURE)))
}

export const sendResetPasswordLink = (user, history) => dispatch => {
    dispatch({ type: SEND_RESET_PASSWORD });

    api.post('forgot-password', user)
        .then(response => {
            dispatch({ type: SEND_RESET_PASSWORD_SUCCESS });

            successMessageHandler(response);

            history.push('/admin/signin');
        })
        .catch(error => dispatch(errorHandler(error, SEND_RESET_PASSWORD_FAILURE)))
}

export const resetPassword = (newPasswordData, history) => dispatch => {
    dispatch({ type: RESET_PASSWORD });

    api.post('/reset-password', newPasswordData)
        .then(response => {
            dispatch({ type: RESET_PASSWORD_SUCCESS });

            removeUserEmailFromStorage();
            removeResetPasswordTokenFromStorage();

            history.push('/admin/signin');

            successMessageHandler(response);
        })
        .catch(error => {
            dispatch(errorHandler(error, RESET_PASSWORD_FAILURE));
        })
};

export const logoutAdmin = (showMessage = true) => dispatch => {
   dispatch({ type: LOGOUT });

   clearLocalStorage();

   if (showMessage) {
       clearAllMessages('success');

       NotificationManager.success('Logout');
   }
}

export const verifyEmail = ({ history }) => dispatch => {
    dispatch({ type: VERIFY_EMAIL });

    const path = `${history.location.pathname}`;

    api.get(path)
        .then(response => {
            dispatch({ type: VERIFY_EMAIL_SUCCESS });

            history.replace('/admin');

            successMessageHandler(response);
        })
        .catch(error => {
            history.replace('/admin');

            dispatch(errorHandler(error, VERIFY_EMAIL_FAILURE));
        })
};

export const sendVerifyEmail = () => dispatch => {
    dispatch({ type: SEND_VERIFY_EMAIL });

    api.get('admin/email/send/verify')
        .then(response => {
            dispatch({ type: SEND_VERIFY_EMAIL_SUCCESS });

            successMessageHandler(response);
        })
        .catch(error => dispatch(errorHandler(error, SEND_VERIFY_EMAIL_FAILURE)))
};

export const passwordChangeNecessitySet = flag => dispatch => {
    dispatch({
        type: PASSWORD_CHANGE_NECESSITY,
        payload: flag
    });
};
