import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Tooltip from '@material-ui/core/Tooltip';
import { ExitToApp, Person, Settings } from '@material-ui/icons';

import { getCurrentContest, logoutAdmin } from 'Actions';

import CurrentDate from './CurrentDate';
import ContestEnd from './ContestEnd';
import CurrentJackpot from './CurrentJackpot';
import HeaderLogo from './HeaderLogo';
import NoContests from './NoContests';
import SettingsDialog from './SettingsDialog';

import { getCurrentContestData, getCurrentContestLoading, getUserRole } from 'Selectors';

const Header = () => {
    const dispatch = useDispatch();
    const history = useHistory();

    const [isOpenSettingsDialog, setIsOpenSettingsDialog] = useState(false);

    const currentContest = useSelector(getCurrentContestData);
    const loading = useSelector(getCurrentContestLoading);
    const userRole = useSelector(getUserRole);

    const showSettings = userRole === 'super-admin';

    useEffect(() => {
        dispatch(getCurrentContest());
    }, []);

    const onToggleShowSettingsDialog = useCallback(() => {
        setIsOpenSettingsDialog(prev => !prev);
    }, [isOpenSettingsDialog]);

    const onRedirectToProfile = () => {
        history.push('/admin/profile');
    };

    const onHandleLogout = () => {
        dispatch(logoutAdmin());

        history.push('/admin/signin');
    };

    return (
        <AppBar position="static" className="rct-header">
            <Toolbar className="d-flex align-items-center justify-content-between w-100">
                <Row className="justify-content-xl-between w-xl-100 w-lg-100 w-md-100">
                    <Col
                        xs={{ order: 2, size: 12 }}
                        xl={{ order: 1, size: 9 }}
                        className="d-flex flex-column pull-right justify-content-between w-100 pr-xs-0 pr-3"
                    >
                        <Row
                            className="w-100 justify-content-between justify-content-xl-start justify-content-lg-between justify-content-md-center justify-content-sm-center justify-content-xs-center"
                        >
                            <HeaderLogo />
                            <CurrentDate />

                            {loading || (currentContest && !currentContest.id)
                                ? null
                                : currentContest && currentContest.id
                                    ? (
                                        <>
                                            <ContestEnd time={currentContest.time_left} />
                                            <CurrentJackpot jackpot={currentContest.jackpot} />
                                        </>
                                    ) : <NoContests />
                            }
                        </Row>
                    </Col>

                    <Col
                        xs={{ order: 1, size: 12 }}
                        xl={{ order: 2, size: 3 }}
                        className="d-flex justify-content-end my-4 mr-0 pr-md-30 pull-left"
                    >
                        <IconButton
                            className="mr-2"
                            onClick={onRedirectToProfile}
                            disableRipple
                        >
                            <Tooltip title="Go to Profile" placement="bottom">
                                <Person />
                            </Tooltip>
                        </IconButton>

                        {showSettings && (
                            <>
                                <SettingsDialog
                                    show={isOpenSettingsDialog}
                                    toggle={onToggleShowSettingsDialog}
                                />

                                <IconButton
                                    className="mr-2"
                                    disableRipple
                                    onClick={onToggleShowSettingsDialog}
                                >
                                    <Tooltip title="Change jackpot step">
                                        <Settings />
                                    </Tooltip>
                                </IconButton>
                            </>
                        )}

                        <IconButton
                            disableRipple
                            onClick={onHandleLogout}
                        >
                            <Tooltip title="Log Out" placement="bottom">
                                <ExitToApp />
                            </Tooltip>
                        </IconButton>
                    </Col>
                </Row>
            </Toolbar>
        </AppBar>
    )
};

export default Header;
