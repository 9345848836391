import {Col, Input, Label} from "reactstrap";
import PositionedFormFeedback from "Components/PositionedFormFeedback";
import React from "react";

export const FormInputWithLabel = (props) => {
    const {
        type,
        invalid,
        name,
        placeholder,
        onChange,
        id,
        value,
        label,
        className = '',
        maxCounterLength = '-',
        showCounter = false,
        errorMessage,
    } = props

    const stringifyValue = typeof value === 'number' ? value.toString() : value

    return (
        <Col md={12} className={'mb-lg-4'}>
            <Label for={id}>
                {label}
            </Label>

            <Input
                invalid={invalid}
                type={type}
                name={name}
                className={`${className} form-control input-lg`}
                placeholder={placeholder}
                onChange={onChange}
                id={id}
                value={value}
            />

            {showCounter && <span className={'text-gray font-xs'}>
                {`${stringifyValue.length}/${maxCounterLength} ${type === 'number' ? 'numbers' : 'signs'}`}
            </span>}

            {invalid && (
                <PositionedFormFeedback message={errorMessage} leftOffset />
            )}
        </Col>
    )
}
