export const checkIsContestExternalLink = () => window.location.pathname === '/contest';

export const deepLinkPrefix = process.env.MIX_APP_DEEPLINK;

export const getOS = () => {
    const userAgent = navigator.userAgent;

    switch (true) {
        case /iPhone/.test(userAgent) || /iPad/.test(userAgent):
            return 'ios';
        case /Android/.test(userAgent):
            return 'android';
        default:
            return 'web';
    }
}


export const getDeepLink = url => `${deepLinkPrefix}://${url}`;

export const prepareLinkForMobile = () => 'contest';
