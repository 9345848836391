import _ from 'lodash';
import moment from 'moment';

export const getDateForCurrentContest = (contestDate) => moment(contestDate).format('MM/DD/YY');

export const formatDate = date => {
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return `${year}-${month}-${day}`;
}

export const getUniqVideoDates = videos => {
    if (videos.length === 0) {
        return [];
    }

    return _.map(
        _.uniqWith(videos, (a, b) =>
            formatDate(new Date(a.created_at)) === formatDate(new Date(b.created_at))
        ), ({ created_at }) => created_at
    );
}

export const filterVideosByDate = (date, videos) => {
    if (videos.length === 0) {
        return [];
    }

    return videos.filter(video =>
        formatDate(new Date(video.created_at)) === formatDate(new Date(date))
    );
};

export const getContestTimeLeft = time => moment.utc(time * 1000).format('HH:mm:ss');

export const getCurrentDate = () => moment.utc().format('MM/DD/YY');

export const getContestEnd = date => moment(date, 'MM/DD/YY').format('MM/DD/YY');

export const getContestDurationDays = () => 89; // the first day is a current date
